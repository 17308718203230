<template>
  <nav class="nav_sticky">
    <NavigationView></NavigationView>
  </nav>
  <div class="details_position">

    <!-- 职位简述 -->
    <PositionSketch :positionSketch="positionSketch"></PositionSketch>

    <div class="position_main container">

      <div class="position_detail_main">
        <!-- 职位详情 -->
        <section>
          <PositionDetail :positionDetails="positionDetails"></PositionDetail>
        </section>


        <!-- 工作详情 -->
        <section>
          <WorkDetail :companyDetails="companyDetails"></WorkDetail>
        </section>

      </div>
      <!-- 侧边栏 -->
      <aside>
        <SidebarDetails :companySketch="companySketch"></SidebarDetails>
      </aside>
    </div>

  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import PositionSketch from "@/views/position/components/PositionSketch.vue";
import PositionDetail from "@/views/position/components/PositionDetail.vue";
import WorkDetail from "@/views/position/components/WorkDetail.vue";
import SidebarDetails from "@/views/position/components/SidebarDetails.vue";
import { getPositionById } from "@/request/api";
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
import { useStore } from "vuex";

const route = useRoute()
const store = useStore()

//职位简介
const positionSketch = reactive(
  {
    id: '',
    //职位名称
    position_name: '',
    //薪资
    position_min_salary: 0,
    position_max_salary: 0,
    // 地址
    position_address: '',
    // 经验要求
    position_experience: '',
    // 福利
    position_welfare: [],
    position_minAge: 0,
    position_maxAge: 0,
    position_linkman: "",
  }
)

//职位详情
const positionDetails = reactive({
  positionDetails_tags: [],
  position_name: '',
  position_min_salary: 0,
  position_max_salary: 0,
  position_conditions: '',
  positionImaUrl: '',
})

// 公司简介
const companySketch = reactive({
  companyId: '',
  logoUrl: '',
  type: '',
  professionStr: '',
  crewSize: '',
  name: ''
})

// 公司详情
const companyDetails = reactive({
  info: '',
  name: '',
  legalPerson: '',
  registerDate: '',
  registerFund: '',
  province: '',
  city: '',
  region: '',
  professionStr: '',
  addr: '',
  addrShow: ''
})


// let workDetail = reactive({})


onMounted(() => {
  store.state.pathActive = '/position'
  window.scrollTo(0, 0);
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
  getPositionById(route.query.id).then(res => {
    const r = res.data.data
    if (r.servicesYear === 0) {
      r.servicesYear = '不限'
    } else {
      r.servicesYear = r.servicesYear + '年'
    }
    if (r.companyResult.type === 0) {
      r.companyResult.type = '国企'
    } else if (r.companyResult.type === 1) {
      r.companyResult.type = '事业单位'
    } else if (r.companyResult.type === 2) {
      r.companyResult.type = '私企'
    } else if (r.companyResult.type === 3) {
      r.companyResult.type = '民企'
    }
    if (!r.companyResult.logoUrl || r.companyResult.logoUrl === "") {
      r.companyResult.logoUrl = "https://youmaigroup-ymy-customer-mp.oss-cn-shenzhen.aliyuncs.com/companyDefault.png"
    }

    // 处理职位简介数据
    positionSketch.id = r.needPositionId
    positionSketch.position_name = r.name
    positionSketch.position_min_salary = r.minSalary
    positionSketch.position_max_salary = r.maxSalary
    positionSketch.position_address = r.companyResult.city
    positionSketch.position_experience = r.servicesYear
    positionSketch.position_welfare = r.welfareList.split(";")
    positionSketch.position_minAge = r.minAge
    positionSketch.position_maxAge = r.maxAge
    positionSketch.position_linkman = r.linkman

    // 处理公司信息
    companySketch.logoUrl = r.companyResult.logoUrl
    companySketch.type = r.companyResult.type
    companySketch.professionStr = r.companyResult.professionStr
    companySketch.crewSize = r.companyResult.crewSize
    companySketch.companyId = r.companyResult.companyId
    companySketch.name = r.companyResult.name

    // 处理岗位详情
    if (r.welfareList) {
      positionDetails.positionDetails_tags = r.welfareList.split(";")
    } else {
      positionDetails.positionDetails_tags = ['福利多多']
    }
    positionDetails.position_name = r.name
    positionDetails.position_min_salary = r.minSalary
    positionDetails.position_max_salary = r.maxSalary
    positionDetails.position_conditions = r.responsibility;
    positionDetails.positionImaUrl = r.positionImgUrl
    // 处理公司详情
    companyDetails.info = r.companyResult.info
    companyDetails.name = r.companyResult.name
    companyDetails.legalPerson = r.companyResult.legalPerson
    companyDetails.registerDate = r.companyResult.registerDate.split("T")[0]
    companyDetails.registerFund = r.companyResult.registerFund
    companyDetails.province = r.companyResult.province
    companyDetails.city = r.companyResult.city
    companyDetails.region = r.companyResult.region
    companyDetails.professionStr = r.companyResult.professionStr
    companyDetails.addr = r.workAddrProvince + r.workAddrCity + r.workAddrRegion + r.workAddr
    companyDetails.addrShow = r.workAddr

    const descriptionEl = document.querySelector('meta[name="description"]')
    if (descriptionEl) {
      descriptionEl.setAttribute('content', r.conditionsTagList)
    }
    const keywordsEl = document.querySelector('meta[name="keywords"]')
    if (keywordsEl) {
      keywordsEl.setAttribute('content', r.typeStr)
    }
    if (document.title) {
      document.title = r.name + '-' + r.companyResult.name
    }
    store.state.showLoading = false
    store.state.showOverlay = false
    document.body.style.overflow = ""

  })

})


</script>

<style lang="less" scoped>
.details_position {


  // 主要部分
  .position_main {
    margin-top: .32rem;
    display: flex;
    justify-content: space-between;

    .position_detail_main {
      margin-right: .12rem
    }

    .danmu-canvas {
      width: 12.4rem;
      height: 3rem;
      display: block;
      background-color: rgba(255, 255, 255, 0);
      position: fixed;
      top: 3rem;
      left: 3.4rem;
      z-index: 999999;
      //top: 240px;
    }
  }
}
</style>