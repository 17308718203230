<template>
  <div class="nav" :class="isFixed ? 'shadow' : ''">
    <div class="container ">
      <div class="left">
        <img src="@/assets/image/index/index2.png" alt="友脉人才网logo" title="友脉人才网" />
      </div>
      <div class="center">
        <span class="link" v-for="item in navg" :key="item.id">
          <a class="link_name" :class="item.path === $store.state.pathActive ? 'active' : ''"
            @click="toPath(item.path)">{{ item.name }}</a>
          <span class="partition" v-if="item.id !== 5">|</span>
        </span>
      </div>

      <div class="right">
        <a class="findJob">
          <i class="iconfont icon-zhaogongzuo"></i>找工作小程序
          <div class="findJopCode">
            <img src="@/assets/image/layout/nav_code.jpg" alt="找工作小程序" title="找工作小程序" />
            <!--            <span>扫码进入小程序</span>-->
          </div>
        </a>
        <a class="findPerson">
          <i class="iconfont icon-wodeqiye"></i>找人才小程序
          <div class="findJopCode">
            <img src="@/assets/image/layout/youmaijobqiye.jpg" alt="找人才小程序" title="找人才小程序" />
            <!--            <span>扫码进入小程序</span>-->
          </div>
        </a>
      </div>

      <div class="address_change">
        <a>
          <i class="iconfont icon-dizhi"></i>
          <span class="address_name">{{ nowCity }}</span>
        </a>
        <a>
          <span class="change_bth" @click="changeCity">[切换城市]</span>
        </a>
        <n-modal v-model:show="showModal">
          <n-card style="width: 600px" title="城市选择" :bordered="false" size="huge" role="dialog" aria-modal="true">
            <template #header-extra>
              <a @click="close">关闭</a>
            </template>
            <div class="cityClose">
              <div class="nowClose">
                <div style="margin-bottom: .12rem;font-size: .16rem">
                  <span>当前城市</span>
                </div>
                <span class="cityTag">
                  <i class="iconfont icon-dizhi" style="margin-right: .05rem;"></i>
                  <a style="color: #4C80CC">{{ nowCity }}</a>
                </span>
              </div>
              <div class="otherCity" style="margin-top: .12rem;">
                <div style="font-size: 16px;margin-bottom: .12rem;">
                  <span>其他城市</span>
                </div>
                <n-grid :x-gap="12" :y-gap="24" :cols="6">
                  <n-gi v-for="item in otherCity" :key="item" class="other">
                    <a class="otherTag" @click="changeNowCity(item)">{{ item }}</a>
                  </n-gi>
                </n-grid>
              </div>
            </div>
          </n-card>
        </n-modal>
      </div>


    </div>
  </div>
</template>

<script setup>

import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex"
const store = useStore()

const router = useRouter();

let isFixed = ref(false)

const navg = ref([
  {
    id: 1,
    name: '首页',
    path: '/home'
  },
  {
    id: 2,
    name: '职位',
    path: '/position'
  },
  {
    id: 3,
    name: '公司',
    path: '/company'
  },
  {
    id: 4,
    name: '校园招聘',
    path: '/campusrecruitment'
  },
  {
    id: 5,
    name: '招录考',
    path: '/admissiontest'
  },
])

// const pathActive = ref('')
// pathActive.value = store.state.pathActive
// let findJob = ref(false)
// let findJobC = ref(false)
// let findPerson = ref(false)
// let findPersonC = ref(false)
const showModal = ref(false)
const changeCity = () => {
  // showModal.value = true
  window.$message.success("暂未开发，敬请期待！！！");
}

const nowCity = ref('昆明')

const changeNowCity = (city) => {
  nowCity.value = city
  showModal.value = false
  store.state.choosePosition[0] = city
  store.state.chooseCompany[0] = city
  store.state.positionSelectData.addr = city
  store.state.companySelectData.addr = city
}

const close = () => {
  showModal.value = false
}

const otherCity = ref([
  '昆明', '曲靖', '玉溪', '大理', '昭通', '丽江', '红河', '楚雄', '保山', '文山', '普洱', '临沧', '德宏', '怒江'
])


const handleScroll = () => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  isFixed.value = scrollTop > 70 ? true : false;
}
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
})


const toPath = (path) => {
  router.push({
    path
  })
  store.state.pathActive = path
}


</script>

<style lang="less" scoped>
.nav {
  background-color: #ffffff;
  font-size: .14rem;

  .container {

    display: flex;
    justify-content: space-between;

    .left {
      width: 3.4rem;

      img {
        width: 3.4rem;
      }

    }

    .center {
      margin-left: -1.3rem;
      font-size: .16rem;
      color: @small_Color;
      display: flex;
      align-items: center;

      .link {
        color: #C0C4C7;

        .link_name {
          padding-left: .1rem;
          color: #C0C4C7;
        }

        .partition {
          padding-left: .1rem;
        }

        .active {
          color: @youmai_Color;
        }
      }

    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      color: @youmai_Color;
      font-size: @bannerSize;

      .findJob {
        display: flex;
        align-items: center;
        position: relative;
        color: @youmai_Color;

        &:hover {
          >.findJopCode {
            height: 2rem;
            opacity: 1;
          }
        }

        .iconfont {
          margin-right: .05rem;
          font-size: .2rem;
        }

        .findJopCode {
          position: absolute;
          top: .47rem;
          left: -0.38rem;
          width: 2rem;
          height: 0;
          background-color: #fff;
          z-index: 99;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          opacity: 0;
          border-radius: .08rem;
          box-shadow: 0 0 .1rem .1rem rgba(0, 0, 0, .1);

          //transition: all .2s .1s;
          img {
            width: 1.6rem;
            //height: 8.333vw;
          }

          span {
            margin-top: 0;
            font-size: .14rem;
            color: @small_Color;
          }

          &:before {
            content: "";
            position: absolute;
            top: -0.1rem;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: .1rem solid #fff8f8;
            border-left: .1rem solid transparent;
            border-right: .1rem solid transparent;
          }
        }
      }

      .findPerson {
        display: flex;
        align-items: center;
        margin-left: .16rem;
        position: relative;
        color: @youmai_Color;

        &:hover {
          >.findJopCode {
            opacity: 1;
            height: 2rem;

            >:deep(.n-result) {
              display: block;
            }
          }
        }

        .iconfont {
          margin-right: .05rem;
          font-size: .24rem;
        }

        .findJopCode {
          position: absolute;
          top: .5rem;
          left: -0.38rem;
          width: 2rem;
          height: 0;
          background-color: #fff;
          z-index: 99;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          opacity: 0;
          border-radius: .08rem;
          box-shadow: 0 0 .1rem .1rem rgba(0, 0, 0, .1);

          :deep(.n-result) {
            display: none;
          }

          //transition: all .2s .1s;
          img {
            width: 1.6rem;
            //height: 8.333vw;
          }

          span {
            margin-top: 0;
            font-size: .14rem;
            color: @small_Color;
          }

          &:before {
            content: "";
            position: absolute;
            top: -0.1rem;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: .1rem solid #fff8f8;
            border-left: .1rem solid transparent;
            border-right: .1rem solid transparent;
          }
        }
      }
    }

    .address_change {
      display: flex;
      align-items: center;
      font-size: .12rem;

      .iconfont {
        color: @youmai_Color;
      }

      .address_name {
        margin-left: .04rem;
        color: @youmai_Color;
      }

      .change_bth {
        margin-left: .12rem;

        &:hover {
          color: @youmai_Color;
        }
      }

    }
  }

}

.cityTag {
  padding: .04rem .2rem;
  background-color: #a7c2e8;
  border-radius: .04rem;
  color: @youmai_Color;
}

.otherTag {
  color: @big_Color;
  padding: .04rem .2rem;
  border: .01rem solid rgba(204, 204, 204, .4);
  font-size: .14rem;
  border-radius: .04rem;

  &:hover {
    color: @youmai_Color;
  }
}
</style>