<template>
  <nav class="page_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">
      <div class="culture_nav">
        <h1>企业文化</h1>
        <div class="right_nav">
          <span>ENTERPRISE CULTURE</span>
          <div class="right_line"></div>
        </div>
      </div>
      <section>
        <div class="culture_main">
          <div class="culture_card">
            <div class="card">
              <h2>企业定位</h2>
              <h3>Orientation</h3>
              <p>互联网+人力资源全产业链服务提供商</p>
            </div>
            <div class="card">
              <h2>企业愿景</h2>
              <h3>Vision</h3>
              <p>企业持续发展，并被社会尊重、信赖和受喜爱、具有
                核心竞争力的一流集团化企业</p>
            </div>
            <div class="card">
              <h2>企业核心价值观</h2>
              <h3>Slogan</h3>
              <p>聚友同创 合脉共宇</p>
            </div>
          </div>
          <div class="culture_mission">
            <div class="culture_title">
              <h3 style="margin: .08rem 0">企业使命</h3>
              <h4 style="margin: .05rem .05rem .05rem .08rem">Mission</h4>
            </div>
            <p style="color: #5D666D">聚焦客户需求，提供专业、优质、有竞争力的人力资源解决方案和服务，持续为客户创造最大价值</p>
            <div class="mission">
              <ul>
                <li><span style="font-weight: 600;color: #5D666D">企业核心宗旨: </span>发展 共进 和谐。个体和、家庭和、企业和、产业和、社会和</li>
                <li><span style="font-weight: 600;color: #5D666D">企业管理理念: </span>精细管理 高效执行</li>
                <li><span style="font-weight: 600;color: #5D666D">企业经营理念: </span>打造特色、争创第一，以品质开拓市场</li>
                <li><span style="font-weight: 600;color: #5D666D">企业服务理念: </span>专心、真心、用心、贴心、安心，客户服务无小事</li>
                <li><span style="font-weight: 600;color: #5D666D">企业安全理念: </span>高高兴兴上班去，平平安安回家来</li>
                <li><span style="font-weight: 600;color: #5D666D">企业领导理念: </span>忠于职守 爱我友脉</li>
                <li><span style="font-weight: 600;color: #5D666D">企业精神理念: </span>同舟共济、精诚协作</li>
                <li><span style="font-weight: 600;color: #5D666D">企业行动理念: </span>坚决 持续 协调 高效</li>
                <li><span style="font-weight: 600;color: #5D666D">企业组织理念: </span>流程通畅、精简明责、协同高效</li>
                <li><span style="font-weight: 600;color: #5D666D">企业用人理念: </span>企业廉洁理念</li>
                <li><span style="font-weight: 600;color: #5D666D">企业形象: </span>与员工共同分享 一个员工热爱的公司
                  与客户共同体验 一个客户赞誉的公司
                  与社会共同进步 一个社会信赖的公司</li>
                <li><span style="font-weight: 600;color: #5D666D">员工形象: </span>有责任、有激情、有品格、有奉献</li>
                <li><span style="font-weight: 600;color: #5D666D">我们的作风: </span>以身作则、雷厉风行</li>

              </ul>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { onMounted } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";

onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style lang="less" scoped>
.page_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  display: flex;
  margin-top: .3rem;
  border-radius: .08rem;
  background-color: #fff;

  .content {
    width: 100%;

    .culture_nav {
      padding: .1rem .5rem 0.1rem .1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right_nav {
        display: flex;
        align-items: center;
        color: #3496FB;
        font-weight: 600;


        .right_line {
          margin-left: .1rem;
          height: .05rem;
          width: .3rem;
          background-color: #3496FB;
        }

      }
    }

    .culture_main {
      .culture_card {
        padding: .1rem .5rem 0.1rem .1rem;
        display: flex;
        justify-content: space-between;

        .card {
          padding: .2rem;
          background-color: #F3F6FD;
          display: flex;
          align-items: center;
          flex-direction: column;
          //width: 15.625vw;
          height: 2.1rem;

          p {
            margin-top: .2rem;
            text-align: center;
          }
        }
      }

      .culture_mission {
        margin: .1rem .5rem 0.1rem .1rem;
        padding: .1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //align-items: center;
        border: .01rem solid @small_Color;

        .culture_title {
          display: flex;
          align-items: center;
        }

        p {
          margin: .08rem 0;
          font-size: .15rem;
        }

        .mission {
          ul {
            list-style: none;
            margin-left: 0;
            padding-left: 0;
            display: flex;
            flex-direction: column;

            li {
              color: @small_Color;
            }
          }
        }
      }

    }
  }
}</style>