<template>
  <!-- 热门资讯 -->
  <div class="news">
    <!-- 标题部分 -->
    <div class="news_title">
      <div class="left">
        <div class="title_icon"></div>
        <span class="left_title">热门资讯</span>
      </div>
      <div class="right">
        <span>查看全部<i class="iconfont icon-xiangyoujiantou"></i></span>
      </div>
    </div>
    <div class="news_card">
      <div class="card_item" v-for="item in news" :key="item.id">
        <div class="item_title">
          <div class="left">
            <i class="iconfont icon-news"></i>
            <span>{{ item.news_category }}</span>
          </div>
          <div class="right">
            <span>更多<i class="iconfont icon-xiangyoujiantou"></i></span>
          </div>
        </div>
        <div class="item_info">
          <ul>
            <li class="info_list" v-for="i in item.news_item" :key="i.nid">
              <div class="words">{{ i.news_name }}</div>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const news = ref([
  {
    id: 1,
    news_category: '招聘会预告',
    news_item: [
      {
        nid: 1,
        news_name: '"才涌浙江 梦想起航" 2023年浙江-长三角地区人才招聘'
      },
      {
        nid: 2,
        news_name: '2023年浙江-西南地区高校网络人才招聘大人才招聘'
      },
      {
        nid: 3,
        news_name: '2023年浙江-东北地区高校人才招聘会'
      },
      {
        nid: 4,
        news_name: '"留·在浙里" 2023年海外高层次 人才招聘人才招聘人才招聘'
      },
      {
        nid: 5,
        news_name: '"绿水青山 乐业安吉" 青海师范大学校园人才招聘'
      },
      {
        nid: 6,
        news_name: '2023"智慧东阳 才聚三乡"秋季巡回引才网招聘'
      },
    ]
  },
  {
    id: 2,
    news_category: '最新资讯',
    news_item: [
      {
        nid: 1,
        news_name: '皖赣铁路安徽有限责任公司、沪杭铁路客运人才招聘'
      },
      {
        nid: 2,
        news_name: '浙江交通职业技术学院公开招聘人员公告'
      },
      {
        nid: 3,
        news_name: '嘉兴市恒光电力建设有限责任公司工程服务人才招聘'
      },
      {
        nid: 4,
        news_name: '浙江省农业农村现代化研究院拟聘用人员公示'
      },
      {
        nid: 5,
        news_name: '利欧集团23届校招——泵发新生力量'
      },
      {
        nid: 6,
        news_name: '《浙江省省属事业单位2022年特殊专业技术人才招聘》人才招聘'
      },
    ]
  },
  {
    id: 3,
    news_category: '招考公告',
    news_item: [
      {
        nid: 1,
        news_name: '海宁市交通投资集团有限公司下属公司招聘'
      },
      {
        nid: 2,
        news_name: '2023浙江宁波市生态环境局海曙分局招聘编人才招聘'
      },
      {
        nid: 3,
        news_name: '杭州市教育局所属事业单位教职工招聘公告'
      },
      {
        nid: 4,
        news_name: '2023浙江杭州市临平区卫生健康系统择优签人才招聘'
      },
      {
        nid: 5,
        news_name: '2023浙江宁波工程学院中东欧研究所（中国区）人才招聘'
      },
      {
        nid: 6,
        news_name: '2023浙江绍兴滨海科技创新服务中心选调事业单位人才招聘'
      },
    ]
  },
])
</script>

<style lang="less" scoped>
// 热门资讯部分
.news {

  // 标题
  .news_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: .3rem;

    .left {
      display: flex;
      align-items: center;
      width: 1rem;
      height: .2rem;

      .title_icon {
        flex: 1;
        height: .2rem;
        display: flex;
        align-items: center;
        border-left: #FD6300 solid 0.417vw;
      }

      .left_title {
        flex: 9;
        font-size: .2rem;
        font-weight: 700;
        color: @big_Color;
      }
    }

    .right {
      font-size: .16rem;
      color: @small_Color;

      .iconfont {
        font-size: .12rem;
      }
    }

  }

  .news_card {
    display: flex;
    justify-content: space-around;
    background-color: #fff;

    .card_item {
      width: 33.3333%;
      height: 16.667vw;
      background-color: #fff;

      .item_title {
        margin: 1.71875vw 0.521vw 0.521vw 0.521vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.052vw solid #E5E5E5;
        height: 1.823vw;
        color: @big_Color;

        .left {
          height: 1.5625vw;


          i {
            margin-right: 0.521vw;
          }

          span {
            font-size: 1.125rem;
            font-weight: 700;
          }
        }

        .right {
          font-size: .75rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #949497;

          .iconfont {
            margin-left: 0.104vw;
            font-size: .625rem;
          }
        }
      }

      .item_info {
        width: 100%;
        display: flex;
        flex-direction: column;


        .info_list {
          height: 1.458vw;
          color: #626C75;
          margin: 0.365vw 0.833vw;

          .words {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

        }


      }
    }
  }

}
</style>