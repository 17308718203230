<template>
  <nav class="nav_sticky">
    <NavigationView></NavigationView>
  </nav>
  <div class="componentsDetails">
    <section>
      <div class="componentsDetails_banner">
        <div class="banner_content container">
          <div class="banner_left">
            <div class="company_avatar">
              <img :src="companyPosition.logoUrl" :alt="companyPosition.name" />
            </div>
          </div>
          <div class="banner_center">
            <div class="company_name">
              <a class="companyPosition_name">{{ companyPosition.name }}</a>
            </div>
            <div class="company_type">
              <a style="margin-left: 0;">{{ companyPosition.city }}</a> | <a class="type">{{ companyPosition.type }}</a> |
              <a class="scale">{{ companyPosition.crewSize }}</a><span v-show="companyPosition.website"> | </span>
              <div class="officialWebsite" v-show="companyPosition.website !== ''">
                <a target="_blank" :href="companyPosition.website">官网</a>
              </div>
            </div>
            <div class="industry">
              <a>
                <span>{{ companyPosition.professionStr }}</span>
              </a>
            </div>
          </div>
          <div class="banner_right">
            <div class="jobOpenings">
              <a class="position_num">{{ position_num }}</a>
              <a class="word">在招职位</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="componentsDetails_main container">
        <section>
          <div class="main_left">
            <div class="recruit_project" v-if="recruit">
              <p class="stopTime" v-if="isTimeOut === 1">已截止</p>
              <p class="stopTime" v-if="isTimeOut === 2">{{ days }} 天 {{ hours }} 小时 {{ minutes }} 分钟
                {{ seconds }}
                秒</p>
              <p class="stopTime" v-if="isTimeOut === 3">长期有效</p>
              <h3>项目名称</h3>
              <div class="project_name">
                <span>{{ projectData.name }}</span>
              </div>
              <h3>项目说明</h3>
              <article>
                <div class="text" v-html="projectData.projectExplain">
                </div>
              </article>
              <!--                            <a @click="showAllRecruit = true" v-if="!showAllRecruit">查看全部</a>-->
              <!--                            <a @click="showAllRecruit = false" v-else>收起</a>-->
            </div>
            <div class="company_sketch box-shadow">
              <h3>公司介绍</h3>
              <article>
                <div :style="showAll ? 'display: inline-block' : ''" class="text" v-html="companyPosition.info"></div>
              </article>
              <a @click="showAll = true" v-if="!showAll">查看全部</a>
              <a @click="showAll = false" v-else>收起</a>
            </div>
            <div class="company_position">
              <template v-if="companyPositionList.length">
                <div class="position_list box-shadow" @click="toPositionDetails(item.needPositionId)"
                  v-for="item in companyPositionList" :key="item.needPositionId">
                  <div class="desc_top">
                    <div class="top_left">
                      <div class="left_top">
                        <a>{{ item.name }} [{{ companyPosition.city }}·{{
                          companyPosition.region
                        }}]</a>
                      </div>
                      <div class="left_bottom">
                        <div class="salary">
                          <a v-if="item.dailyWage === 0">{{
                            item.minSalary
                          }}-{{ item.maxSalary }}K</a>
                          <a v-else>{{ item.dailyWage }}元/天</a>
                        </div>
                        <div class="servicesYear">
                          <a>{{ item.servicesYear }}</a>
                        </div>
                        <div class="educationValue">
                          <a>{{ item.educationValue }}</a>
                        </div>
                      </div>

                    </div>
                    <div class="top_right">
                      <div class="logo">
                        <img :src="companyPosition.logoUrl" :alt="companyPosition.name" :title="companyPosition.name" />
                      </div>
                      <div class="company_info">
                        <div class="company_name">
                          <a>{{ companyPosition.name }}</a>
                        </div>
                        <div class="company_desc">
                          <div class="type">
                            <a>{{ companyPosition.type }}</a>
                          </div>
                          <div class="crewSize">
                            <a>{{ companyPosition.crewSize }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="desc_bottom">
                    <div class="bottom_left">
                      <a v-for="(i, index) in item.welfareList" :key="index">{{ i }}</a>
                    </div>
                    <div class="bottom_right">
                      <a v-for="(i, index) in companyPosition.welfareList" :key="index">{{
                        i
                      }}</a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div style="width: 100%; height: 300px">
                  <n-result status="info" title="信息" description="当前公司暂无职位，看看其他公司吧！">
                    <template #footer>
                      <n-button @click="toCompany">看看其他公司</n-button>
                    </template>
                  </n-result>
                </div>
              </template>
            </div>
            <div class="pagination">
              <n-pagination v-model:page="current" :page-count="pageTotal" size="large"
                :on-update:page="showSizeChange" />
            </div>
          </div>
        </section>
        <aside>
          <div class="main_right">
            <div class="recruit_code" v-if="recruit">
              <!--              <div class="recruit_code_title">-->
              <!--                <h3>手机查看</h3>-->
              <!--              </div>-->
              <div class="image">
                <img v-if="!recruitCode" src="@/assets/image/layout/mini_code.jpg" alt="默认图片">
                <img v-else :src="recruitCode" alt="招录考小程序二维码">
              </div>
              <p class="recruit_code_tips">扫码查看详情</p>
            </div>
            <div class="company_address">
              <div class="address_title">
                <h3>公司地址</h3>
              </div>
              <div class="addr_details">
                <i class="iconfont icon-dizhi1"></i>
                <p>{{ companyPosition.addr }}</p>
              </div>
              <div class="btn_map" @click="showMap">
                <a>查看地图</a>
              </div>
              <div class="modal" v-if="showOverlay">
                <div class="overlay" @click="disappear"></div>
                <div class="mapDetails" v-show="showMapDetails">
                  <div class="address_name">
                    <h3>公司地址</h3>
                    <a @click="disappear">
                      <i class="iconfont icon-quxiao"></i>
                    </a>
                  </div>
                  <div id="mapMain"></div>
                </div>
              </div>
            </div>
            <div class="businessInformation">
              <div class="bgc_business"></div>
              <div class="businessInformation_title">
                <h3>工商信息</h3>
              </div>
              <div style="height: 0.833vw"></div>
              <div class="business_info">
                <ul>
                  <li>
                    <span>公司全称：</span><a class="companyPosition_name">{{
                      companyPosition.name
                    }}</a>
                  </li>
                  <li>
                    <span>成立时间：</span><a>{{ companyPosition.registerDate }}</a>
                  </li>
                  <li>
                    <span>注册资本：</span><a>{{ companyPosition.registerFund || '未知' }}</a>
                  </li>
                  <li>
                    <span>法人代表：</span><a>{{ companyPosition.legalPerson || '未知' }}</a>
                  </li>
                  <li>
                    <span>所属地区：</span><a>{{ companyPosition.province }}{{
                      companyPosition.city
                    }}{{
  companyPosition.region
}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </section>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { getCompanyInfo, positionListByCompany, getRecruitDetails } from "@/request/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { jsonp } from "vue-jsonp";
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
import { setInterval, clearInterval } from "timers";
import { combineAddress } from "@/utils/dataFormat"

const store = useStore()
const router = useRouter()
const route = useRoute()
const recruit = ref(false)
let projectData = ref(null)
let days = ref(0)
let hours = ref(0)
let minutes = ref(0)
let seconds = ref(0)
let companyId = ref(0)

const position_num = ref(0)

//分页
const current = ref(1)
const pageTotal = ref(1)
const showSizeChange = (page) => {
  current.value = page
  // window.scrollTo(0, 0)
  getCompanyPositionList(companyId.value)
}

let showAll = ref(false)

// const info = ref('<p>友脉控股集团有限公司成立于2015年12月16日，注册地位于云南省昆明市五华区小康大道东侧德润春城花园18号楼2611号，法定代表人为和志勇。经营范围包括许可项目：第二类增值电信业务；建设工程设计；建设工程勘察（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）一般项目：企业管理咨询；以自有资金从事投资活动；国内贸易代理；信息系统集成服务；软件开发；装卸搬运；规划设计管理；商业综合体管理服务；商务代理代办服务；互联网销售（除销售需要许可的商品）；个人互联网直播服务；非居住房地产租赁；住房租赁；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；计算机系统服务；广告设计、代理；广告制作；广告发布；物业管理；工程管理服务；合同能源管理；国内货物运输代理；普通货物仓储服务（不含危险化学品等需许可审批的项目）；通信设备销售；计算机软硬件及辅助设备零售；计算机软硬件及辅助设备批发；工程技术服务（规划管理、勘察、设计、监理除外）；家政服务（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。友脉控股集团有限公司对外投资9家公司。</p>\n')

const companyPosition = ref({})
const companyPositionList = ref([])

const toCompany = () => {
  router.push("/company")
}


const getCompany = (id) => {
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
  getCompanyInfo(id).then(results => {
    if (results.data.data.web) {
      results.data.data.website = results.data.data.web
    } else {
      results.data.data.website = ""
    }
    if (results.data.data.type === 0) {
      results.data.data.type = '国企'
    } else if (results.data.data.type === 1) {
      results.data.data.type = '事业单位'
    } else if (results.data.data.type === 2) {
      results.data.data.type = '私企'
    } else if (results.data.data.type === 3) {
      results.data.data.type = '民企'
    }
    for (var i = 0; i < results.data.data.professionStr.length; i++) {
      if (results.data.data.professionStr[i] === ';') {
        results.data.data.professionStr = results.data.data.professionStr.replace(';', ' ');
      }
    }
    if (results.data.data.welfareList) {
      results.data.data.welfareList = results.data.data.welfareList.split(";")
      if (results.data.data.welfareList.length > 4) {
        results.data.data.welfareList.length = 5
        results.data.data.welfareList[4] = '···'
      }
      if (results.data.data.welfareList[0] === '') {
        results.data.data.welfareList = ["福利多多"]
      }
    } else {
      results.data.data.welfareList = ["福利多多"]
    }

    if (results.data.data.registerDate) {
      results.data.data.registerDate = results.data.data.registerDate.split('T')[0]
    }
    if (!results.data.data.info) {
      results.data.data.info = '<p>暂无公司简介</p>';
    }
    if (!results.data.data.registerFund && results.data.data.registerFund !== null) {
      results.data.data.registerFund = results.data.data.registerFund + '万元人民币'
    }

    companyPosition.value = results.data.data
    let data = {
      info: results.data.data.info,
      name: results.data.data.name,
      professionStr: results.data.data.professionStr
    }
    setMeta(data)
  })
}

const setMeta = (data) => {
  const descriptionEl = document.querySelector('meta[name="description"]')
  if (descriptionEl) {
    descriptionEl.setAttribute('content', data.info)
  }
  const keywordsEl = document.querySelector('meta[name="keywords"]')
  if (keywordsEl) {
    keywordsEl.setAttribute('content', data.name + ',' + data.professionStr)
  }
  if (document.title) {
    document.title = data.name
  }
}
const getCompanyPositionList = (id) => {
  companyPositionList.value = []
  positionListByCompany(id).then(res => {
    if (res.data.data.length === 0) {
      store.state.showLoading = false
      store.state.showOverlay = false
      document.body.style.overflow = ""
      companyPositionList.value = res.data.data
      return;
    }
    if (res.data.code === 200) {
      position_num.value = res.data.data.length
      pageTotal.value = Math.ceil(res.data.data.length / 4)
      for (let i = (current.value - 1) * 4; i < current.value * 4; i++) {
        if (!res.data.data[i]) {
          return
        }
        res.data.data[i].welfareList = res.data.data[i].welfareList.split(';')
        if (res.data.data[i].welfareList === ['']) {
          res.data.data[i].welfareList = ["福利多多"]
        }
        if (res.data.data[i].welfareList.length > 4) {
          res.data.data[i].welfareList.length = 5
          res.data.data[i].welfareList[4] = '···'
        }
        if (res.data.data[i].servicesYear === 0) {
          res.data.data[i].servicesYear = '不限'
        } else {
          res.data.data[i].servicesYear = res.data.data[i].servicesYear + '年'
        }
        companyPositionList.value.push(res.data.data[i])
        store.state.showLoading = false
        store.state.showOverlay = false
        document.body.style.overflow = ""
      }
    }

  })
}

let showMapDetails = ref(false)
let showOverlay = ref(false)
const showMap = () => {
  showOverlay.value = true
  showMapDetails.value = true
  const params = {
    headers: { "content-type": "application/xml" },
    callbackQuery: "callbackParam",
    callbackName: "jsonpCallback"
  }
  let addr = combineAddress(companyPosition.value)
  let url = `https://apis.map.qq.com/ws/geocoder/v1/?address=${addr}&key=63GBZ-VDY6F-UGNJW-JALQW-OSJL5-CTBGL&sig=In2AS4k1gQ5IJLEGBvynqq6Ks1cn0DH&output=jsonp&callback="jsonpCallback`;
  jsonp(url, params).then(res => {
    // eslint-disable-next-line no-undef
    let map = new TMap.Map(document.getElementById('mapMain'), {
      // eslint-disable-next-line no-undef
      center: new TMap.LatLng(res.result.location.lat, res.result.location.lng),//设置地图中心点坐标
      zoom: 17.2,   //设置地图缩放级别
    });
    //创建并初始化MultiMarker
    // eslint-disable-next-line no-undef,no-unused-vars
    let marker = new TMap.MultiMarker({
      map: map,  //指定地图容器
      //样式定义
      styles: {
        // 点标记样式
        // eslint-disable-next-line no-undef
        marker: new TMap.MarkerStyle({
          width: 20, // 样式宽
          height: 30, // 样式高
          anchor: { x: 10, y: 30 }, // 描点位置
        }),
      },
      //点标记数据数组
      geometries: [{
        // 标记位置(纬度，经度，高度)
        // eslint-disable-next-line no-undef
        position: new TMap.LatLng(res.result.location.lat, res.result.location.lng),
        id: 'marker',
      }]
    });
    //初始化label
    // eslint-disable-next-line no-undef
    new TMap.MultiLabel({
      id: 'label-layer',
      map: map, //设置折线图层显示到哪个地图实例中
      //文字标记样式
      styles: {
        // eslint-disable-next-line no-undef
        'label': new TMap.LabelStyle({
          'color': '#000', //颜色属性
          'size': 12, //文字大小属性
          'offset': { x: 0, y: -60 }, //文字偏移属性单位为像素
          'angle': 0, //文字旋转属性
          'alignment': 'center', //文字水平对齐属性
          'verticalAlignment': 'middle' //文字垂直对齐属性
        })
      },
      //文字标记数据
      geometries: [{
        'id': 'label_1', //点图形数据的标志信息
        'styleId': 'label', //样式id
        // eslint-disable-next-line no-undef
        'position': new TMap.LatLng(res.result.location.lat, res.result.location.lng), //标注点位置
        'content': addr, //标注文本
      }]
    });

    map.on("tilesloaded", function () {
    })

  }).catch(err => {
    console.log('err', err);
  })
}

const disappear = () => {
  showOverlay.value = false
  showMapDetails.value = false
}

const toPositionDetails = (id) => {
  router.push({
    path: '/detailspositon',
    query: {
      id
    }
  })
  store.state.pathActive = '/position'
}
let isTimeOut = ref(1)

const recruitCode = ref("")

const getRecruitDetailsHandler = (id) => {
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
  getRecruitDetails(id).then(res => {
    if (res.data.code === 200) {
      companyId.value = res.data.data.companyId
      projectData.value = res.data.data
      if (!res.data.data.projectExplain) {
        res.data.data.projectExplain = '<p>暂无说明</p>'
      }
      // getCompany(companyId.value)
      if (res.data.data.businessCompany.type === 0) {
        res.data.data.type = '国企'
      } else if (res.data.data.businessCompany.type === 1) {
        res.data.data.businessCompany.type = '事业单位'
      } else if (res.data.data.businessCompany.type === 2) {
        res.data.data.businessCompany.type = '私企'
      } else if (res.data.data.businessCompany.type === 3) {
        res.data.data.businessCompany.type = '民企'
      }
      for (var i = 0; i < res.data.data.businessCompany.professionStr.length; i++) {
        if (res.data.data.businessCompany.professionStr[i] === ';') {
          res.data.data.businessCompany.professionStr = res.data.data.businessCompany.professionStr.replace(';', ' ');
        }
      }
      if (res.data.data.businessCompany.welfareList) {
        res.data.data.businessCompany.welfareList = res.data.data.businessCompany.welfareList.split(";")
        if (res.data.data.businessCompany.welfareList.length > 4) {
          res.data.data.businessCompany.welfareList.length = 5
          res.data.data.businessCompany.welfareList[4] = '···'
        }
        if (res.data.data.businessCompany.welfareList[0] === '') {
          res.data.data.businessCompany.welfareList = ["福利多多"]
        }
      } else {
        res.data.data.businessCompany.welfareList = ["福利多多"]
      }

      if (res.data.data.businessCompany.registerDate) {
        res.data.data.businessCompany.registerDate = res.data.data.businessCompany.registerDate.split('T')[0]
      }
      if (!res.data.data.businessCompany.info) {
        res.data.data.businessCompany.info = '<p>暂无公司简介</p>';
      }
      // 计算剩余时间
      if (res.data.data.endTime) {
        isTimeOut.value = 2
        const deadline = new Date(res.data.data.endTime).getTime()
        const currentTime = ref(new Date().getTime())

        const timer = ref(0)
        timer.value = setInterval(() => {
          currentTime.value = new Date().getTime()
          const diff = deadline - currentTime.value
          if (diff < 0) {
            clearInterval(timer.value)
            isTimeOut.value = 1
          }
          days.value = Math.floor(diff / (1000 * 60 * 60 * 24))
          hours.value = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          minutes.value = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
          seconds.value = Math.floor((diff % (1000 * 60)) / 1000)
        }, 1000)
      } else {
        isTimeOut.value = 3
      }
      companyPosition.value = res.data.data.businessCompany
      recruitCode.value = res.data.data.miniCode
      getCompanyPositionList(companyId.value)

      let data = {
        info: res.data.data.name,
        name: res.data.data.name,
        professionStr: res.data.data.name
      }
      setMeta(data)

    }


  })
}


onMounted(() => {
  store.state.pathActive = '/company'
  window.scrollTo(0, 0)
  if (route.query.type) {
    recruit.value = true
    // projectData.value = JSON.parse(localStorage.getItem("projectData"))
    getRecruitDetailsHandler(Number(route.query.id))
  }
  if (!route.query.type) {
    companyId.value = route.query.id
    recruit.value = false
    getCompany(companyId.value)
    getCompanyPositionList(companyId.value)
  }

})


</script>

<style lang="less" scoped>
.componentsDetails {
  .loading_animate {
    z-index: 9999;
    position: fixed;
    top: 35%;
    left: 45%;
  }

  .componentsDetails_banner {
    //background: url("@/assets/image/company/c_details2.jpg");
    height: 2.2rem;
    position: relative;

    &::after {
      // div是你需要添加背景图片的盒子
      content: "";
      background-image: url("@/assets/image/company/c_details.jpg"); // 背景图片的路径
      //opacity: 0.5;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .banner_content {
      z-index: 9;

      height: 100%;
      display: flex;

      .banner_left {
        //background-color: red;
        width: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .company_avatar {
          width: 1.2rem;
          height: 1.2rem;
          border: .01rem solid @small_Color;
          border-radius: .05rem;
          background-color: rgba(255, 255, 255, .7);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            //height: 100%;
            border-radius: .05rem;
          }
        }
      }

      .banner_center {
        width: 7rem;

        .company_name {
          color: #fff;
          font-size: .28rem;
          margin-top: .49rem;

          .companyPosition_name {
            color: #ffffff;
            font-size: .42rem;
          }

        }

        .company_type {
          color: #b0aeae;
          font-size: .18rem;
          margin-top: .0564rem;
          display: flex;
          align-items: center;
          box-sizing: border-box;

          a {
            color: #b0aeae;
            font-size: .18rem;
            margin: 0 .05rem;
          }

          .officialWebsite {

            padding: .02rem .05rem;
            border-radius: .05rem;
            font-size: .14rem;
            background-color: rgba(0, 0, 0, .5);

            a {
              color: #b0aeae;

              &:hover {
                color: #1787FB;
              }
            }
          }
        }

        .industry {
          color: #fff;
          font-size: .18rem;
          margin-top: .0564rem;
          display: flex;
          align-items: center;


          a {
            color: #b0aeae;
            font-size: .18rem;
            width: 3.4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;

            span {
              border-radius: .05rem;
              background-color: rgba(0, 0, 0, .5);
            }
          }

        }
      }

      .banner_right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 1.01rem;


        .jobOpenings {
          width: .96rem;
          height: .96rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, .4);
          border-radius: .08rem;

          .position_num {
            font-size: .32rem;
            color: #F1B572;
          }

          .word {
            font-size: .16rem;
            color: #ffffff;
          }
        }
      }
    }
  }

  .componentsDetails_main {
    margin-top: .25rem;
    display: flex;
    justify-content: space-between;

    .main_left {
      width: 9.216rem;

      .recruit_project {
        width: 100%;
        border-radius: .08rem;
        background-color: #fff;
        padding: .3rem;
        margin-bottom: .25rem;
        position: relative;

        .stopTime {
          position: absolute;
          top: .3rem;
          right: .3rem;
          padding: 0.02rem 0.08rem;
          background-color: rgba(0, 0, 0, .2);
          border-radius: 0.06rem;
        }

        h3 {
          margin: .1rem 0;
        }

        .project_name {
          font-size: .18rem;
        }

        .text {
          font-size: .14rem;
          color: #4C4C4C;
          line-height: .32rem;
        }

        a {
          font-size: .12rem;
          color: #265888;
        }
      }

      .company_sketch {
        width: 100%;
        border-radius: .08rem;
        background-color: #fff;
        padding: .3rem;

        h3 {
          margin: .1rem 0;
        }

        .text {
          font-size: .14rem;
          color: #4C4C4C;
          line-height: .32rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          //display: inline-block;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
        }

        a {
          font-size: .12rem;
          color: #265888;
        }
      }

      .company_position {
        margin-top: .25rem;

        .position_list {
          width: 100%;
          //height: 6.771vw;
          background-color: #fff;
          margin-bottom: .16rem;
          border: none;
          border-radius: .06rem;
          -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
          transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
          word-break: break-all;

          &:hover {
            box-shadow: 0 .16rem .32rem 0 rgba(48, 55, 66, 0.15);
            transform: translate(0, -0.26vw);
            transition-delay: 0s !important;
          }

          .desc_top {
            padding: .1536rem .24rem;
            display: flex;
            border-bottom: .01rem dashed #ccc;

            .top_left {
              flex: 1;

              .left_top {
                a {
                  font-size: .18rem;
                  color: #505050;

                  &:hover {
                    color: @youmai_Color;
                  }
                }
              }

              .left_bottom {

                display: flex;
                margin-top: .1536rem;

                .salary {
                  margin-right: .1rem;

                  a {
                    font-size: .18rem;
                    color: #FE6B60;
                  }
                }

                .servicesYear {
                  margin-right: .1rem;

                  a {
                    color: #828282;
                    font-size: .14rem;
                    padding: .01rem .1rem;
                    background-color: #F8F8F8;
                    border-radius: .05rem;
                  }
                }

                .educationValue {
                  a {
                    color: #828282;
                    font-size: .14rem;
                    padding: .01rem .1rem;
                    background-color: #F8F8F8;
                    border-radius: .05rem;
                  }
                }
              }
            }

            .top_right {
              margin-left: .3rem;
              flex: 1;
              display: flex;

              .logo {
                width: .6rem;
                height: .6rem;
                border: .01rem solid #F8F8F8;
                border-radius: .05rem;
                margin-right: .1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                  //height: 100%;
                  border-radius: .05rem;
                }
              }

              .company_info {
                .company_name {
                  margin-left: .1rem;

                  a {
                    &:hover {
                      color: @youmai_Color;
                    }
                  }
                }

                .company_desc {
                  margin-top: .15rem;
                  display: flex;

                  .type {
                    margin-right: .1rem;

                    a {
                      color: #828282;
                      font-size: .14rem;
                      padding: .01rem .1rem;
                      background-color: #F8F8F8;
                      border-radius: .05rem;
                    }
                  }

                  .crewSize {
                    a {
                      color: #828282;
                      font-size: .14rem;
                      padding: .01rem .1rem;
                      background-color: #F8F8F8;
                      border-radius: .05rem;
                    }
                  }
                }
              }
            }
          }

          .desc_bottom {
            padding: .15rem .15rem;
            display: flex;
            border-bottom-left-radius: .08rem;
            border-bottom-right-radius: .08rem;

            .bottom_left {
              flex: 1;
              display: flex;

              a {
                margin-right: .1rem;
                //overflow: hidden;
                //text-overflow: ellipsis;
                //display: -webkit-box;
                //-webkit-box-orient: vertical;
                //-webkit-line-clamp: 1;
                color: #828282;
                font-size: .14rem;
                padding: .01rem .1rem;
                background-color: #F8F8F8;
                border-radius: .05rem;
              }
            }

            .bottom_right {
              flex: 1;
              margin-left: .3rem;
              display: flex;

              a {
                margin-right: .1rem;
                //overflow: hidden;
                //text-overflow: ellipsis;
                //display: -webkit-box;
                //-webkit-box-orient: vertical;
                //-webkit-line-clamp: 1;
                color: #828282;
                font-size: .14rem;
                padding: .01rem .1rem;
                background-color: #F8F8F8;
                border-radius: .05rem;
              }
            }
          }

        }


      }

      .pagination {
        margin-top: .2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        :deep(.n-pagination-item--button) {
          background: #fff;
        }

        :deep(.n-pagination-item--clickable) {
          background-color: #fff;
        }

        :deep(.n-pagination-item--active) {
          color: @youmai_Color;
          border: .01rem solid @youmai_Color;
        }
      }
    }

    .main_right {
      width: 2.95rem;

      .recruit_code {
        overflow: hidden;
        padding: .15rem;
        background-color: #fff;
        //background-image: linear-gradient(#e0ecff, #ffffff);
        border-radius: .08rem;
        border: .01rem solid rgba(204, 204, 204, .5);
        margin-bottom: .16rem;

        .recruit_code_tips {
          display: flex;
          justify-content: center;
        }
      }

      .company_address {
        padding: .2rem;
        background: url("@/assets/image/company/details_address_bgc1.png");
        border-radius: .08rem;
        border: .01rem solid rgba(204, 204, 204, .5);

        .mapDetails {
          position: absolute;
          top: 3.03rem;
          left: 4.65rem;
          width: 8.08rem;
          height: 4.04rem;
          background-color: #fff;
          border-radius: .08rem;

          .address_name {
            padding: .1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          #mapMain {
            width: 100%;
            height: 100%;
            border-bottom-right-radius: .08rem;
            border-bottom-left-radius: .08rem;
          }
        }

        .address_title {
          margin-bottom: .08rem;
        }

        .addr_details {
          display: flex;

          .iconfont {
            padding-top: .05rem;
            margin-right: .05rem;
          }

          p {
            font-size: .14rem;
            line-height: .28rem;
            color: @small_Color;
          }
        }

        .btn_map {
          margin-top: .08rem;
          width: 100%;
          height: .4rem;
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            padding: .08rem .22rem;
            border: .01rem solid @small_Color;
            border-radius: .08rem;
            color: @small_Color;
          }
        }
      }

      .businessInformation {
        margin-top: .16rem;
        padding: .2rem;
        background-color: #fff;
        border-radius: .08rem;
        position: relative;

        .bgc_business {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: .4rem;
          background: linear-gradient(#e0ecff, #ffffff);
        }

        .businessInformation_title {
          position: absolute;
          top: .16rem;
          left: .16rem;
        }

        .business_info {
          margin-top: .24rem;
          font-size: .14rem;

          ul {
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            li {
              margin-bottom: .16rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              &:nth-child(5) {
                margin-bottom: 0;
              }
            }
          }
        }
      }

    }
  }
}

.box-shadow {
  -webkit-box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
  box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
}
</style>