<template>
  <!-- 职位详情 -->
  <div class="position_detail shadow">
    <div class="position_code">
      <!--      <img src="@/assets/image/layout/nav_code.jpg" alt=""/>-->
      <div>
        <img style="width: 2rem" v-if="details.positionImaUrl === ''" src="@/assets/image/layout/mini_code.jpg" alt="扫码进入小程序投递职位"
          title="扫码直接报名" />
        <img v-else :src="details.positionImaUrl" alt="扫码进入小程序投递职位" title="扫码直接报名" />
      </div>

      <a class="code_name">扫码直接报名</a>
    </div>
    <div class="title">
      <span class="position_desc">职位描述</span>

    </div>
    <div class="position_tags">
      <span class="tags" v-for="tags in details.positionDetails_tags" :key="tags">{{ tags }}</span>
    </div>

    <div class="position_content">
      <div v-html="details.position_conditions" class="work_details">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs} from "vue";



const props = defineProps({
  positionDetails: Object
})
const details = ref({})
details.value = toRefs(props).positionDetails.value

</script>

<style lang="less" scoped>
// 职位详情
.position_detail {
  border-radius: .08rem;
  width: 8.64rem;
  background-color: #fff;
  margin-bottom: .2rem;
  padding: .4rem .25rem;
  position: relative;
  min-height: 2.76rem;

  .loading_animate {
    z-index: 9999;
    position: fixed;
    top: 35%;
    left: 40%;
  }

  .position_code {
    position: absolute;
    top: .25rem;
    right: .25rem;
    width: 1.92rem;
    padding: .16rem;
    border: .02rem solid rgba(204, 204, 204, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .08rem;

    .code_name {
      margin-top: .08rem;
      font-size: .1rem;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .position_desc {
      font-size: .2rem;
      color: @big_Color;
      font-weight: 600;
    }
  }

  .position_tags {
    margin-top: .28rem;
    height: .28rem;

    .tags {
      height: 100%;
      padding: .09rem;
      font-size: .14rem;
      background-color: #F8F8F8;
      margin-right: .06rem;
      color: #A2A2A2;
    }
  }

  .position_content {
    margin-top: 27pxz;
    color: #595959;
    line-height: .38rem;


    .work_details {

      //&:nth-child(1) {
      //  width: 30vw;
      //}
      :deep(p) {
        &:nth-child(1) {
          width: 5.76rem;
        }

        &:nth-child(2) {
          width: 5.76rem;
        }

        &:nth-child(3) {
          width: 5.76rem
        }

      }

    }

  }
}
</style>