<template>
  <nav class="nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="help">
    <n-result status="info" title="使用帮助页面" description="程序员正在加急开发中···">
      <template #footer>
        <n-button @click="back">点击返回</n-button>
      </template>
    </n-result>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";

onMounted(() => {
  window.scrollTo(0, 0)
})
const router = useRouter()
const back = () => {
  router.go(-1)
}
</script>

<style lang="less" scoped>
.help {
  margin-top: .36rem;
  background-color: #fff;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>