import request from "@/request/request";

//根据公司id获取公司信息
export const getCompanyInfo = function (id) {
	return request.get("/web/company/view", { params: { companyId: id } });
};

//根据条件获取公司列表，data中参数为name,addr,type
export const getCompanyList = function (pageNum,data) {
	return request.post("/web/company/list?pageSize=16&pageNum=" + pageNum, data);
};

//根据条件获取职位列表 data中传递，name,addr,salaryRange,serviceYear四个参数
export const getPositionList = function (pageNum,data) {
	return request.post("/web/needPosition/list?pageSize=10&pageNum="+pageNum, data);
};

// 获取职位详细信息
export const getPositionById = function (id) {
	return request.get("/web/needPosition/view", {
		params: { needPositionId: id },
	});
};

//获取职位信息列表,传一个数组
export const positionList = (data) => {
	return request.post("/web/position/list", {
		keyWordList: data,
	});
};

//根据公司查询职位
export const positionListByCompany = (id) => {
	return request.get("/web/company/companyAboutNeedPosition", {
		params: { companyId: id },
	});
};

// 获取职位二维码
// export const getPositionCode = (id) => {
// 	return request.get("/recruit/need/position/getMiniAppQRCode", {
// 		params: { positionId: id },
// 	});
// };

// 获取招录考列表
export const getRecruitList = (num,size) => {
	return request.get("/recruit/api/project/list?pageNum="+num+"&pageSize="+size);
};

// 获取招录考详情
export const getRecruitDetails = (projectId) => {
	return request.get("/recruit/api/project/view?projectId=" + projectId);
};

// 获取招录考二维码
export const getRecruitCode = (projectId) => {
	return request.get("/recruit/api/project/getMiniAppRecruitmentQRCode?projectId=" + projectId)
}

// 获取隐私政策和协议
export const getPrivacyProtocal = (uuid) => {
	return request.get("/recruit/api/customer/document/detail?uuid=" + uuid)
}

// 获取推荐公司
export const getRecommendCompany = () => {
	return request.get("/web/needPosition/company/recommend")
}
