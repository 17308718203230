<template>
  <div class="swiper_honor container">
    <div class="left_btn" @click="prevPage">
      <img src="@/assets/image/index/footer_honor/left.jpg" alt="左按钮" />
    </div>
    <div class="honor">
      <div class="slides" :style="{ transform: 'translateX(' + translateX + ')' }">
        <div class="page1">
          <a href="http://www.youmaigroup.cn/nd.jsp?id=832&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-1.jpg" alt="云南省专精特新“成长”企业称号" title="云南省专精特新“成长”企业" />
          </a>
          <a href="http://www.youmaigroup.cn/nd.jsp?id=1088&fromMid=325&fromColId=2" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-2.jpg" alt="云南省人力资源服务行业协会副会长单位" title="云南省人力资源服务行业协会副会长单位" />
          </a>
          <a href="http://www.youmaigroup.cn/nd.jsp?id=1053&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-3.jpg" alt="云南服务业企业50强" title="云南服务业企业50强" />
          </a>
          <a href="http://www.youmaigroup.cn/nd.jsp?id=652&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-4.jpg" alt="昆明市企业家联合会协会副会长" title="昆明市企业家联合会协会副会长" />
          </a>
        </div>
        <div class="page2">
          <a href="http://www.youmaigroup.cn/nd.jsp?id=1005&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-5.jpg" alt="云南省劳动关系和谐企业" title="云南省劳动关系和谐企业" />
          </a>
          <a href="http://www.youmaigroup.cn/nd.jsp?id=645&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-6.jpg" alt="云南省省级众创空间" title="云南省省级众创空间" />
          </a>
          <a href="http://www.youmaigroup.cn/nd.jsp?id=669&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-7.jpg" alt="全国工商联人力资源服务业委员会委员单位" title="全国工商联人力资源服务业委员会委员单位" />
          </a>
          <a href="http://www.youmaigroup.cn/nd.jsp?id=700&fromColId=30" target="_blank">
            <img src="@/assets/image/index/footer_honor/ry-8.jpg" alt="中国人才交流协会人才测评专委会" title="中国人才交流协会人才测评专委会" />
          </a>
        </div>
      </div>
    </div>
    <div class="right_btn" @click="nextPage">
      <img src="@/assets/image/index/footer_honor/right.jpg" alt="左按钮" />
    </div>
  </div>
</template>

<script setup>

import { ref } from "vue";


const translateX = ref('0')
const prevPage = () => {
  translateX.value = 0
}
const nextPage = () => {
  translateX.value = '-' + (100) + '%'
}
</script>

<style lang="less" scoped>
.swiper_honor {
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_btn {
    //margin-right: 2.34375vw;
    width: .35rem;
  }

  .honor {
    overflow: hidden;


    .slides {
      width: 11.3rem;
      display: flex;
      transition: all 0.5s ease-out;

      .page1 {
        //width: 1024px;
        display: flex;
        flex: 0 0 100%;

        a {
          width: 2.57rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: .24rem;

          img {
            width: 2.3rem;

            transition: all 0.3s;

            &:hover {
              width: 2.57rem;
            }
          }
        }


      }

      .page2 {
        //width: 1024px;
        display: flex;
        flex: 0 0 100%;

        a {
          width: 2.57rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1.25vw;

          img {
            width: 2.3rem;

            transition: all 0.3s;

            &:hover {
              width: 2.57rem;
            }
          }
        }
      }
    }

  }

  .right_btn {
    width: .35rem;
    //margin-left: 2.34375vw;
    margin-left: .24rem;
  }
}
</style>