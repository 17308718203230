<template>
  <div class="news_left">
    <div class="left_top">
      <span class="big_title">{{ bigTitle }}</span>
      <span class="small_title">{{ smallTitle }}</span>
      <div class="line_news">
        <div class="line_left"></div>
        <div class="line_point"></div>
      </div>
      <span class="news_message">{{ chTitle }}</span>
    </div>
    <div class="left_bottom">
      <div class="circle">
        <div class="cross"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, toRefs} from 'vue';

const bigTitle = ref('LATEST')
const smallTitle = ref('NEWS')
const chTitle = ref('最新资讯')
const props = defineProps({
  bigTitle: String,
  smallTitle: String,
  chTitle: String
})
bigTitle.value = toRefs(props).bigTitle.value
smallTitle.value = toRefs(props).smallTitle.value
chTitle.value = toRefs(props).chTitle.value


</script>

<style lang="less" scoped>
.news_left {
  flex: 1;
  height: 100%;

  .left_top {
    height: 50%;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    color: #CAD8EE;

    .big_title {
      margin-bottom: 1.667vw;
    }

    .small_title {
      margin-bottom: 0.833vw;
    }

    .line_news {
      width: 3.125vw;
      height: 0.15625vw;

      margin-bottom: 0.833vw;
      display: flex;
      justify-content: space-between;

      .line_left {
        width: 2.708vw;
        height: 100%;
        background-color: #6F98D6;
      }

      .line_point {
        width: 0.104vw;
        height: 100%;
        background-color: #6F98D6;
      }

    }

    .news_message {
      color: #333333;
      font-weight: 700;
    }
  }

  .left_bottom {
    height: 50%;
    display: flex;
    align-items: center;

    .circle {
      width: 3.125vw;
      height: 3.125vw;
      background-color: @youmai_Color;
      border-radius: 3.125vw;
      display: flex;
      justify-content: center;
      align-items: center;

      //十字架
      .cross {
        background: #ffffff;
        height: 1.5625vw;
        position: relative;
        width: 0.26vw;

        &:after {
          background-color: #fff;
          content: "";
          height: 0.26vw;
          left: -0.651vw;
          position: absolute;
          top: 0.651vw;
          width: 1.5625vw;
        }
      }
    }
  }
}
</style>