<template>
  <nav class="development_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">
      <div class="development">
        <nav class="development_nav">
          <h1>发展历程</h1>
          <div class="right_nav">
            <span>DEVELOPING COURSE</span>
            <div class="right_line"></div>
          </div>
        </nav>
        <div class="development_main">
          <img src="@/assets/image/statement/devement.png" alt="友脉发展" title="友脉发展">
          <!--      <div class="development_left">-->
          <!--        <div class="left_card1">-->
          <!--          <h3 style="color: #D81B43">2022-2023</h3>-->
          <!--          <ul>-->
          <!--            <li>集团与区人社局、区国投公司联合筹建区、市、省级人力资源服务产业园</li>-->
          <!--            <li>集团下属企业荣获云南省专精特新“成长”企业称号</li>-->
          <!--            <li>董事长和志勇出席昆明市五华区第十七届人民代表大会</li>-->
          <!--            <li>国企混改企业昆明高新人才服务有限公司正式成立并开展相关业务</li>-->
          <!--            <li>集团广东总部成立珠海、深圳、汕尾分公司</li>-->
          <!--            <li>友脉重庆公司（科技公司、人力资源公司）成立</li>-->
          <!--            <li>友脉贵州公司（贵州友脉人力资源服务有限公司）成立</li>-->
          <!--            <li>集团党支部被评为昆明市“四星级”示范党支部</li>-->
          <!--            <li>董事长和志勇被评为云南省百强优秀民营企业家</li>-->
          <!--            <li>董事长和志勇被评为云南省人力资源服务行业领军人物（全省仅10名）</li>-->
          <!--            <li>董事长和志勇当选为云南省民族团结进步促进会副会长</li>-->
          <!--            <li>董事长和志勇当选为昆明市五华区新阶联副会长</li>-->
          <!--            <li>友脉集团被命名为云南省劳动关系和谐企业（全省仅三家）</li>-->
          <!--          </ul>-->
          <!--        </div>-->
          <!--        <div class="left_card2" style="margin-top: 4.167vw;">-->
          <!--          <h3 style="color: #D81B43">2018-2019</h3>-->
          <!--          <ul>-->
          <!--            <li>启动西南昆明+华南广州双总部协同发展战略</li>-->
          <!--            <li>成立广州友脉人力资源有限公司、广东友脉科技有限公司</li>-->
          <!--            <li>成立友脉控股集团妇女联合会</li>-->
          <!--            <li>成立云南友脉投资有限公司党支部</li>-->
          <!--            <li>友脉集团玉溪呼叫中心产业园盛大起航</li>-->
          <!--            <li>联合共青团昆明市委、青基会举办慈善音乐会，资助300余名贫困优秀大学生</li>-->
          <!--            <li>集团入驻五华区德润中心A座写字楼26楼、27楼新办公室</li>-->
          <!--            <li>与中电福富、爱尔信集团、中智集团签订战略合作</li>-->
          <!--            <li>友脉互联网团队圆满完成柬埔寨澜湄航空项目实施</li>-->
          <!--            <li>友脉集团涉足招聘猎头、培训咨询业务</li>-->
          <!--            <li>“海子会众创空间”被昆明市科技局认定为市级众创空间</li>-->
          <!--            <li>董事长和志勇当选为昆明市五华区新阶联副会长</li>-->
          <!--            <li>经国家工商总局核准，友脉控股集团升级为全国无地域限制、无城市名称的多元化集团企业</li>-->
          <!--          </ul>-->
          <!--        </div>-->
          <!--        <div class="left_card3" style="margin-top: 4.167vw;">-->
          <!--          <h3 style="color: #D81B43">2014-2015</h3>-->
          <!--          <ul>-->
          <!--            <li>成立云南省人力资源管理师协会</li>-->
          <!--            <li>成立云南省人力资源管理师协会党支部</li>-->
          <!--            <li>成立云南友脉科技有限公司</li>-->
          <!--            <li>成立友脉科技红河分公司</li>-->
          <!--            <li>启动人力资源和呼叫中心业务</li>-->
          <!--          </ul>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--      <div class="center">-->
          <!--        <div class="company_logo">-->
          <!--          <img src="@/assets/image/development/company_logo.jpg" alt="" />-->
          <!--        </div>-->
          <!--        <div class="center_line"></div>-->
          <!--      </div>-->
          <!--      <div class="development_right">-->
          <!--        <div class="right_card1">-->
          <!--          <h3 style="color: #D81B43">2020-2021</h3>-->
          <!--          <ul>-->
          <!--            <li>嵩明县政府与友脉控股集团签署服务外包产业园合作协议，联合成立服务外包产业园</li>-->
          <!--            <li>集团启动重庆友脉、贵州友脉、海南友脉、广西友脉、四川友脉等公司并开展业务</li>-->
          <!--            <li>官渡区政府与友脉控股集团签订战略合作协议</li>-->
          <!--            <li>云南省退役军人事务厅与友脉控股集团签订就业合作协议</li>-->
          <!--            <li>董事长和志勇成为云南省第一批劳动关系专家智库成员</li>-->
          <!--            <li>董事长和志勇被评为第七届昆明市道德模范“昆明好人”</li>-->
          <!--            <li>董事长和志勇被聘为全国工商联人资服务委员会委员</li>-->
          <!--            <li>董事长和志勇当选中国人才交流协会人才测评专委会副秘书长</li>-->
          <!--            <li>成立中国共产主义青年团友脉控股集团委员会</li>-->
          <!--            <li>集团被评为“云南省省级文明单位”荣誉称号</li>-->
          <!--            <li>董事长和志勇当选为五华区人大代表并参会做重点企业代表发言</li>-->
          <!--            <li>成立云南友脉保安服务有限公司</li>-->
          <!--            <li>集团云科海子会众创空间被认定为省级众创空间</li>-->
          <!--            <li>集团布局安保服务、数字档案和人才测评等全产业链业务</li>-->
          <!--            <li>友脉人力集团珠海、深圳公司成立，旗下全资及控股企业分子公司达40余家</li>-->
          <!--          </ul>-->
          <!--        </div>-->
          <!--        <div class="right_card2">-->
          <!--          <h3 style="color: #D81B43">2016-2017</h3>-->
          <!--          <ul>-->
          <!--            <li>成立云南友脉劳务服务有限公司</li>-->
          <!--            <li>成立云南友脉教育咨询有限公司</li>-->
          <!--            <li>成立云南海子房车露营发展有限公司</li>-->
          <!--            <li>成立临沧、大理、曲靖、楚雄、玉溪、版纳等分公司</li>-->
          <!--            <li>成立友脉集团工会组织</li>-->
          <!--            <li>启动互联网外包和文旅业务</li>-->
          <!--            <li>海子房车澄江营地、禄劝茂山房车营地开业</li>-->
          <!--          </ul>-->
          <!--        </div>-->
          <!--      </div>-->
        </div>

      </div>
    </div>
  </div>

  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { onMounted } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";

onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style lang="less" scoped>
.development_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  display: flex;
  margin-top: .3rem;
  border-radius: .08rem;
  background-color: #fff;

  .content {

    .development {
      padding: .1rem .5rem 0.1rem .1rem;
      box-sizing: border-box;

      .development_nav {
        //padding: 0.521vw 2.604vw 0.521vw 0.521vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right_nav {
          display: flex;
          align-items: center;
          color: #3496FB;
          font-weight: 600;


          .right_line {
            margin-left: .1rem;
            height: .05rem;
            width: .3rem;
            background-color: #3496FB;
          }

        }
      }

      .development_main {
        display: flex;
        justify-content: space-between;
        margin-top: .2rem;
        border: .01rem solid @small_Color;

        .development_left {
          text-align: right;
          padding-right: .3rem;

          .left_card1 {
            ul {
              list-style: none;
              margin-left: 0;

              li {
                font-size: .13rem;
                margin-top: .08rem;
                color: @small_Color;
              }
            }
          }

          .left_card2 {
            ul {
              list-style: none;
              margin-left: 0;

              li {
                font-size: .13rem;
                margin-top: .08rem;
                color: @small_Color;
              }
            }
          }

          .left_card3 {
            ul {
              list-style: none;
              margin-left: 0;

              li {
                font-size: .13rem;
                margin-top: .08rem;
                color: @small_Color;
              }
            }
          }
        }

        .center {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .company_logo {
            width: .3rem;
            height: .3rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .center_line {
            width: .01rem;
            background-color: @small_Color;
            height: 10.5rem;
          }
        }

        .development_right {
          margin-top: 1.8rem;
          padding-left: .3rem;

          .right_card1 {
            ul {
              list-style: none;
              margin-left: 0;
              padding-left: 0;

              li {
                font-size: .13rem;
                margin-top: .08rem;
                color: @small_Color;
              }
            }
          }

          .right_card2 {
            margin-top: .8rem;

            ul {
              list-style: none;
              margin-left: 0;
              padding-left: 0;

              li {
                font-size: .13rem;
                margin-top: .08rem;
                color: @small_Color;
              }
            }
          }
        }
      }
    }
  }
}</style>