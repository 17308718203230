<template>
  <nav class="nav_sticky">
    <NavigationView></NavigationView>
  </nav>
  <!-- 职位页面 -->
  <div class="position">
    <section>
      <div class="position_search">
        <div class="search_left"></div>
        <input class="search_input" type="text" :placeholder="placeholder" @keydown="searchHandler"
          v-model="positionInputValue">
        <a class="search_btn" style="font-size: .18rem;" @click="searchPosition">搜索</a>
      </div>
    </section>

    <div style="height: .5rem"></div>

    <!-- 条件筛选栏 -->
    <section>
      <div class="position_condition container shadow">
        <div class="condition_item" :class="'item' + item.id" v-for="item in condition" :key="item.id">
          <div class="item_title">{{ item.title }}</div>
          <div class="item_content">
            <a @click="chooseCategory(item.id, i)" :class="i === choosePosition[item.id - 1] ? 'choose' : ''"
              v-for="(i, index) in item.content" :key="index">{{ i }}</a>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="position_main container">
        <div class="position_list">
          <div class="list_top">
            <section>
              <div class="position_card">
                <!-- 职位列表 -->
                <template v-if="positionDesc.length">
                  <div class="position_list box-shadow" @click="toDetail(item.needPositionId)"
                    v-for="item in positionDesc" :key="item.needPositionId">
                    <div class="desc_top">
                      <div class="top_left">
                        <div class="left_top">
                          <a>{{ item.name }} <span>[{{ item.workAddrProvince }}·{{ item.workAddrCity }}]</span></a>
                        </div>
                        <div class="left_bottom">
                          <div class="salary">
                            <a>{{ item.minSalary }}-{{ item.maxSalary }}K</a>
                          </div>
                          <div class="servicesYear">
                            <a>{{ item.servicesYear }}</a>
                          </div>
                          <div class="educationValue">
                            <a>{{ item.educationValue }}</a>
                          </div>
                        </div>

                      </div>
                      <div class="top_right">
                        <div class="logo">
                          <img :src="item.companyInfo.logoUrl" :alt="item.companyInfo.name"
                            title="item.companyResult.name" />
                        </div>
                        <div class="company_info">
                          <div class="company_name">
                            <a>{{ item.companyInfo.name }}</a>
                          </div>
                          <div class="company_desc">
                            <div class="type">
                              <a>{{ item.companyInfo.type }}</a>
                            </div>
                            <div v-if="item.companyInfo.crewSize" class="crewSize">
                              <a>{{ item.companyInfo.crewSize }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="desc_bottom">
                      <div class="bottom_left">
                        <a v-for="(i, index) in item.welfareList" :key="index">{{ i }}</a>
                      </div>
                      <div class="bottom_right">
                        <a v-for="(i, index) in item.companyInfo.welfareList" :key="index">{{ i }}</a>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="position_no_data">
                    <n-result status="warning" title="" description="暂无符合条件的数据">
                    </n-result>
                  </div>
                </template>


              </div>
            </section>
            <!-- 侧边描述 -->
            <aside>
              <div class="position_desc">
                <div class="other_recommend shadow">
                  <div class="bgc-lin"></div>
                  <div class="title">
                    <h3>其他企业推荐</h3>
                  </div>
                  <div style="height: .3rem"></div>
                  <div class="recommend" :class="'recommend' + index" v-for="(item, index) in commandCompany"
                    :key="item.companyId">
                    <div class="img">
                      <img :src="item.logoUrl" :alt="item.name" :title="item.name" />
                    </div>
                    <div class="word">

                      <a @click="toCompanyDetails(item.companyId)">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <!-- 分页 -->
          <section>
            <div class="position_paging">
              <n-pagination v-model:page="current" :page-count="pageTotal" size="large"
                :on-update:page="showSizeChange" />
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
import { useHead } from '@vueuse/head'
import { getPositionList, getRecommendCompany } from "@/request/api";
const router = useRouter()
const route = useRoute()
const store = useStore()
const placeholder = ref('请输入职位名称')
/* 条件筛选栏 */
const condition = ref([
  {
    id: 1,
    title: '工作地点',
    content: ['不限', '昆明', '曲靖', '玉溪', '大理', '昭通', '丽江', '红河', '楚雄', '保山', '文山', '普洱', '临沧', '德宏', '怒江']
  },
  {
    id: 2,
    title: '薪资待遇',
    content: ['不限', '3K以下', '3-5K', '5-8K', '8-10K', '10-15K', '15-20K', '20-30K']
  },
  {
    id: 3,
    title: '工作经验',
    content: ['不限', '应届生', '1年', '2年', '3年', '4年', '5年', '6年', '7年', '8年', '9年', '10年']
  },
  {
    id: 4,
    title: '工作单位',
    content: ['不限', '国企', '事业单位', '私企', '民企']
  }
])

useHead(
  {
    title: '职位列表-友脉人才网',
    meta: [
      {
        name: 'keywords',
        content: '找工作，职位，岗位，五险一金，周末双休，正式靠谱，兼职，全职，真实靠谱'
      },
      {
        name: 'description',
        content: '正式靠谱，周末双休···你想要的都在友脉人才网，为您提供各种靠谱工作'
      }
    ]
  }
)

const current = ref(1)
const pageTotal = ref(1)

const showSizeChange = (page) => {
  current.value = page
  window.scrollTo(0, 0)
  getPositionDesc()
}

let choosePositionData = ref({})
let choosePosition = ref(['不限', '不限', '不限', '不限'])
let positionInputValue = ref('')

/*搜索职位*/
const searchPosition = () => {
  // store.state.positionSelectData.name = store.state.positionInputValue
  choosePositionData.value.name = positionInputValue.value
  current.value = 1
  getPositionDesc()
}

const chooseCategory = (index, name) => {
  if (choosePosition.value[index - 1] === name) {
    choosePosition.value[index - 1] = ''
  } else {
    choosePosition.value[index - 1] = name
  }

  choosePosition.value.map((choose, num) => {
    if (num === 0) {
      if (choose !== '不限') {
        if (choose === '') {
          delete choosePositionData.value.addr
        } else {
          choosePositionData.value.addr = choose
        }
      } else {
        delete choosePositionData.value.addr
      }
    }
    if (num === 1) {
      switch (choose) {
        case '3K以下':
          choosePositionData.value.salaryRange = [0, 3];
          break;
        case '3-5K':
          choosePositionData.value.salaryRange = [3, 5];
          break;
        case '5-8K':
          choosePositionData.value.salaryRange = [5, 8];
          break;
        case '8-10K':
          choosePositionData.value.salaryRange = [8, 10];
          break;
        case '10-15K':
          choosePositionData.value.salaryRange = [10, 15];
          break;
        case '15-20K':
          choosePositionData.value.salaryRange = [15, 20];
          break;
        case '20-30K':
          choosePositionData.value.salaryRange = [20, 30];
          break;
        default:
          delete choosePositionData.value.salaryRange;
          break;
      }
    }
    if (num === 2) {
      switch (choose) {
        case '应届生':
          choosePositionData.value.serviceYear = 0;
          break;
        case '1年':
          choosePositionData.value.serviceYear = 1;
          break;
        case '2年':
          choosePositionData.value.serviceYear = 2;
          break;
        case '3年':
          choosePositionData.value.serviceYear = 3;
          break;
        case '4年':
          choosePositionData.value.serviceYear = 4;
          break;
        case '5年':
          choosePositionData.value.serviceYear = 5;
          break;
        case '6年':
          choosePositionData.value.serviceYear = 6;
          break;
        case '7年':
          choosePositionData.value.serviceYear = 7;
          break;
        case '8年':
          choosePositionData.value.serviceYear = 8;
          break;
        case '9年':
          choosePositionData.value.serviceYear = 9;
          break;
        case '10年':
          choosePositionData.value.serviceYear = 10;
          break;
        default:
          delete choosePositionData.value.serviceYear;
          break;
      }
    }
    if (num === 3) {
      switch (choose) {
        case '国企':
          choosePositionData.value.companyType = 0;
          break;
        case '事业单位':
          choosePositionData.value.companyType = 1;
          break;
        case '私企':
          choosePositionData.value.companyType = 2;
          break;
        case '民企':
          choosePositionData.value.companyType = 3;
          break;
        default:
          delete choosePositionData.value.companyType;
          break;
      }
    }
  })
  store.state.showLoading = true
  store.state.showOverlay = true
  current.value = 1
  getPositionDesc();
}



const toDetail = (id) => {
  router.push({
    path: '/detailspositon',
    query: {
      id
    }
  })
}

const positionDesc = ref([])
const commandCompany = ref([])


const getPositionDesc = () => {
  if (positionInputValue.value === '' || !positionInputValue.value) {
    if (choosePositionData.value.name) {
      delete choosePositionData.value.name
    }
  }
  positionDesc.value = []
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
  getPositionList(current.value, choosePositionData.value).then(res => {
    if (res.data.code === 200) {
      pageTotal.value = Math.ceil(res.data.total / 10)
      res.data.rows.map((item) => {
        if (!item.companyInfo.logoUrl || item.companyInfo.logoUrl === '') {
          item.companyInfo.logoUrl = 'https://youmaigroup-ymy-customer-mp.oss-cn-shenzhen.aliyuncs.com/companyDefault.png'
        }
        if (item.welfareList) {
          item.welfareList = item.welfareList.split(";")
          if (item.welfareList.length > 4) {
            item.welfareList.length = 4
            item.welfareList.push("更多")
          }
        } else {
          item.welfareList = ["福利多多"]
        }
        if (item.companyInfo.welfareList) {
          item.companyInfo.welfareList = item.companyInfo.welfareList.split(";")
          if (item.companyInfo.welfareList.length > 4) {
            item.companyInfo.welfareList.length = 4
            item.companyInfo.welfareList.push("更多")
          }
        } else {
          item.companyInfo.welfareList = ["优秀公司"]
        }
        if (item.servicesYear) {
          if (item.servicesYear === 0) {
            item.servicesYear = '不限经验'
          } else {
            item.servicesYear = item.servicesYear + '年'
          }
        } else {
          item.servicesYear = '不限经验'
        }
        if (item.educationValue === '不限') {
          item.educationValue = '不限学历'
        }
        switch (item.companyInfo.type) {
          case 0:
            item.companyInfo.type = '国企';
            break;
          case 1:
            item.companyInfo.type = '事业单位';
            break;
          case 2:
            item.companyInfo.type = '私企';
            break;
          case 3:
            item.companyInfo.type = '民企';
            break;
          default:
            item.companyInfo.type = '未知';
            break;
        }
        if (item.companyInfo.crewSize) {
          item.companyInfo.crewSize = item.companyInfo.crewSize.split("人")[0] + "人"
        }
      })
      positionDesc.value = res.data.rows
      store.state.showLoading = false
      store.state.showOverlay = false
      document.body.style.overflow = ""
    }

  })
}
const getCommandCompany = () => {
  getRecommendCompany().then(res => {
    if (res.data.code === 200) {
      commandCompany.value = res.data.data
    }
  });
}
const toCompanyDetails = (id) => {
  router.push({
    path: '/companyDetails',
    query: {
      id
    }
  })
  store.state.pathActive = '/company'
}

const searchHandler = (e) => {
  if (e.key === 'Enter') {
    searchPosition()
  }
}


onMounted(() => {
  store.state.pathActive = '/position'
  if (route.query.inputValue) {
    positionInputValue.value = route.query.inputValue
    choosePositionData.value.name = route.query.inputValue
  }
  getPositionDesc()
  getCommandCompany()
})



</script>

<style lang='less' scoped>
.position {
  margin-top: .5rem;

  .loading_animate {
    z-index: 9999;
    position: fixed;
    top: 35%;
    left: 45%;
  }

  // 搜索框
  .position_search {
    display: flex;
    width: 8rem;
    height: .6rem;
    margin: 0 auto;

    .search_left {
      width: .6rem;
      height: 100%;
      background-color: #fff;
      border-top-left-radius: .6rem;
      border-bottom-left-radius: .6rem;
    }

    .search_input {
      width: 6.4rem;
      border: 0;
    }

    .search_btn {
      width: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4C80CC;
      color: #fff;
      font-size: .26rem;
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }

  // 条件筛选栏
  .position_condition {
    //height: 15.625vw;
    background-color: #fff;
    padding: .2rem .3rem;
    border-radius: .08rem;


    .condition_item {
      display: flex;
      border-bottom: .01rem dashed #EBEBEB;

      .item_title {
        width: 1rem;
        height: .4rem;
        color: @big_Color;
        line-height: .4rem;
        font-size: .18rem;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .item_content {
        display: flex;
        align-items: center;


        a {
          margin-right: .3rem;
          color: #767676;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .choose {
          color: #4C80CC;
        }
      }
    }

    .item4 {
      border: 0;
    }
  }

  // 职位展示区
  .position_main {
    margin-top: .2rem;

    .position_list {
      display: flex;
      flex-direction: column;

      .list_top {
        display: flex;
        justify-content: space-between;

        .position_card {
          display: flex;
          flex-direction: column;
          //justify-content: space-between;

          .position_list {
            width: 9.24rem;
            //height: 6.771vw;
            background-color: #fff;
            margin-bottom: .16rem;

            border: none;
            border-radius: .06rem;
            -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
            transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
            word-break: break-all;

            &:hover {
              box-shadow: 0 .16rem .32rem 0 rgba(48, 55, 66, 0.15);
              transform: translate(0, -0.26vw);
              transition-delay: 0s !important;
            }

            .desc_top {
              padding: .15rem .24rem;
              display: flex;
              border-bottom: .01rem dashed #ccc;

              .top_left {
                flex: 1;

                .left_top {
                  a {
                    font-size: .18rem;
                    color: #505050;

                    &:hover {
                      color: @youmai_Color;
                    }
                  }
                }

                .left_bottom {

                  display: flex;
                  margin-top: .15rem;

                  .salary {
                    margin-right: .1rem;

                    a {
                      font-size: .18rem;
                      color: #FE6B60;
                    }
                  }

                  .servicesYear {
                    margin-right: .1rem;

                    a {
                      color: @small_Color;
                      font-size: .14rem;
                      padding: .01rem .1rem;
                      background-color: #F8F8F8;
                      border-radius: .05rem;
                    }
                  }

                  .educationValue {
                    a {
                      color: @small_Color;
                      font-size: .14rem;
                      padding: .01rem .1rem;
                      background-color: #F8F8F8;
                      border-radius: .05rem;
                    }
                  }
                }
              }

              .top_right {
                margin-left: .3rem;
                flex: 1;
                display: flex;

                .logo {
                  width: .606rem;
                  height: .606rem;
                  border: .01rem solid #F8F8F8;
                  border-radius: .05rem;
                  margin-right: .1rem;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: .05rem;
                  }
                }

                .company_info {
                  .company_name {

                    //margin-left: 0.526vw;
                    a {
                      &:hover {
                        color: @youmai_Color;
                      }
                    }
                  }

                  .company_desc {
                    margin-top: .15rem;
                    display: flex;

                    .type {
                      margin-right: .1rem;

                      a {
                        color: #828282;
                        font-size: .14rem;
                        padding: .01rem .1rem;
                        background-color: #F8F8F8;
                        border-radius: .05rem;
                      }
                    }

                    .crewSize {
                      a {
                        color: #828282;
                        font-size: .14rem;
                        padding: .01rem .1rem;
                        background-color: #F8F8F8;
                        border-radius: .05rem;
                      }
                    }
                  }
                }
              }
            }

            .desc_bottom {
              padding: .15rem .15rem;
              display: flex;
              border-bottom-left-radius: .08rem;
              border-bottom-right-radius: .08rem;

              .bottom_left {
                flex: 1;
                display: flex;

                a {
                  margin-right: .1rem;
                  color: @small_Color;
                  font-size: .14rem;
                  padding: .01rem .1rem;
                  background-color: #F8F8F8;
                  border-radius: .05rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                }
              }

              .bottom_right {
                flex: 1;
                margin-left: .3rem;
                display: flex;

                a {
                  margin-right: .1rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  color: @small_Color;
                  font-size: .14rem;
                  padding: .01rem .1rem;
                  background-color: #F8F8F8;
                  border-radius: .05rem;
                }
              }
            }


          }

          .box-shadow {
            -webkit-box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
            box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
          }

        }

        .position_desc {
          //width: 15.625vw;

          .other_recommend {
            width: 100%;
            background-color: #fff;
            padding: .28rem .37rem;
            color: @big_Color;
            border-radius: .08rem;
            box-sizing: border-box;
            position: relative;
            //z-index: -2;


            .bgc-lin {
              width: 100%;
              height: .5rem;
              background: linear-gradient(#e0ecff, #ffffff);
              z-index: 0;
              position: absolute;
              top: 0;
              right: 0;
            }

            .title {
              position: absolute;
              z-index: 99;

            }

            .recommend {
              z-index: 2;
              font-size: .18rem;
              padding: .14rem 0;
              //font-weight: 700;
              display: flex;
              border-bottom: .01rem dashed rgba(204, 204, 204, .4);

              .img {
                width: .46rem;
                height: .46rem;
                border: .01rem solid rgba(204, 204, 204, .4);
                border-radius: .04rem;
                margin-right: .05rem;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 100%;
                  border-radius: .04rem;
                  //height: 2.604vw;
                  //border-radius: 2.604vw;

                }
              }

              .word {
                width: 1.7rem;
                flex-shrink: 0;
                display: flex;
                align-items: center;


                a {
                  line-height: .24rem;
                  font-size: @otherCompany;
                  color: @big_Color;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;

                  &:hover {
                    color: @youmai_Color;
                  }
                }
              }


            }

            //.recommend0 {
            //  padding-top: 1.558vw;
            //}
          }
        }
      }

      // 分页
      .position_paging {
        margin-top: .2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        :deep(.n-pagination-item--button) {
          background: #fff;
        }

        :deep(.n-pagination-item--clickable) {
          background-color: #fff;
        }

        :deep(.n-pagination-item--active) {
          color: @youmai_Color;
          border: .01rem solid @youmai_Color;
        }
      }

    }

    .position_no_data {
      margin-top: 1.5rem;
      width: 8.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }



}
</style>
