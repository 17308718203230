<template>
  <div class="content_left">
    <div class="menu">
      <ul>
        <!--            :class="menuList===item?'pitch':''"-->
        <li @click="changeContent(item.path)" v-for="item in menuList" :key="item.id">
          <a :class="$store.state.menuPitch === item.path ? 'pitch' : ''">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter()
const store = useStore()
const menuList = ref([
  {
    id: 1,
    name: '集团简介',
    path: '/about'
  },
  {
    id: 2,
    name: '发展历程',
    path: '/development'
  },
  {
    id: 3,
    name: '友脉优势',
    path: '/advantage'
  },
  {
    id: 4,
    name: '企业文化',
    path: '/culture'
  },
  {
    id: 5,
    name: '社会责任',
    path: '/social'
  },
  {
    id: 6,
    name: '协议与规则',
    path: '/rules'
  },
  {
    id: 7,
    name: '隐私政策',
    path: '/privacy'
  },
  // {
  //   id: 8,
  //   name: '帮助',
  //   path: '/help'
  // },
  {
    id: 9,
    name: '防骗指南',
    path: '/counterfraud'
  }
])
const changeContent = (path) => {
  store.state.menuPitch = path
  router.push(path)
}
</script>

<style lang="less" scoped>
.content_left {
  width: 1.536rem;
  padding: .1rem;
  flex: 1;

  .menu {
    margin-top: .2rem;
    width: 1.536rem;
    display: flex;
    justify-content: center;

    ul {
      width: 100%;
      list-style: none;
      margin-left: 0;

      li {
        padding-left: .2rem;
        display: flex;
        align-items: center;
        width: 100%;
        height: .5rem;

        a {
          &:hover {
            //background-color: @youmai_Color;
            color: @youmai_Color;
            font-size: .2rem;
            font-weight: 700;
          }
        }

      }

      .pitch {
        //background-color: @youmai_Color;
        color: @youmai_Color;
        font-size: .2rem;
        font-weight: 700;

      }
    }
  }
}</style>