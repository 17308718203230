
//导入axios
import axios from 'axios'

const request = axios.create({
    //指定请求的根路径
    baseURL: process.env.VUE_APP_BASE_URL,
    // baseURL:'https://api.youmaijob.com',
    // headers: {
    //     Authorization: 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoyNDEsInVzZXJfa2V5IjoiMzg5OTc4ODgtMmQ0MS00YmNmLTgxMjctODg0MzgxNmJhNTdiIiwidXNlcm5hbWUiOiIxMzg4NzU1Mzk0MyJ9.htkBUXNmW9IdIKkTxEF4FRFHNmSPbukmT9JuhReQr2TKQMyL1gTbIkMswZC19HKbiRJD4pmWJ1HR1ua81F0fKw',
    // }
})

axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error)
})
export default request
