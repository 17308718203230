<template>
  <nav class="page_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">
      <div class="privacy">
        <nav class="privacy_nav">
          <h1>隐私政策</h1>
          <div class="right_nav">
            <span>PRIVACY POLICY</span>
            <div class="right_line"></div>
          </div>
        </nav>
        <div v-html="privacyText"></div>
        <!--        <article>-->
        <!--          <p>欢迎访问我们的官方网站。在使用我们的网站之前，请仔细阅读本隐私政策。我们非常重视您的隐私，并致力于保护您的个人信息。</p>-->

        <!--          <p>-->
        <!--            本隐私政策解释了我们如何收集、使用和保护您的个人信息。我们可能会根据需要随时更新本隐私政策，因此建议您定期查看此页面以了解最新信息。如果我们对本隐私政策进行任何实质性更改，我们将在我们的网站上发布更新的隐私政策，并通过其他合适的方式通知您。-->
        <!--          </p>-->

        <!--          <h4>个人信息的收集与使用</h4>-->
        <!--          <p>我们可能会收集您的个人信息，例如您的姓名、电子邮件地址、电话号码、居住地址等。我们收集这些信息是为了能够向您提供更好的服务、回答您的问题、处理您的订单、提供您所需的信息，以及向您发送有关我们产品和服务的最新消息和信息。-->
        <!--          </p>-->

        <!--          <h4>Cookie和网站数据的收集与使用</h4>-->
        <!--          <p>-->
        <!--            我们使用cookie和类似技术来收集与存储有关您访问我们网站的信息。这些信息包括您的IP地址、浏览器类型、访问日期和时间、访问的页面等。这些信息帮助我们了解我们的网站如何被使用，以及如何改进我们的服务。我们还使用cookie和其他技术来为您提供更好的用户体验，例如记住您的登录信息。-->
        <!--          </p>-->
        <!--          <h4>个人信息的保护</h4>-->
        <!--          <p>我们非常重视保护您的个人信息的安全。我们采取一系列措施来保护您的个人信息，包括技术措施、物理措施和管理措施。我们仅允许有必要知晓这些信息的授权人员访问您的个人信息。</p>-->

        <!--          <h4>信息的共享</h4>-->
        <!--          <p>我们不会出售、交换或转让您的个人信息给第三方。但是，在某些情况下，我们可能需要与第三方分享您的信息，以提供您所需的服务。我们会要求这些第三方遵守本隐私政策，并采取必要的措施保护您的个人信息。</p>-->

        <!--          <h4>其他</h4>-->
        <!--          <p>如果您有任何关于我们隐私政策的疑问或意见，请通过我们网站上提供的联系方式与我们联系。感谢您使用我们的网站。</p>-->
        <!--        </article>-->
      </div>
    </div>
  </div>

  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
// import {useRouter} from "vue-router";
// const router = useRouter()
// const back = () => {
//   router.go(-1)
// }
import { onMounted, ref } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";
import { getPrivacyProtocal } from "@/request/api"

const privacyText = ref("")
const getPrivacy = () => {
  getPrivacyProtocal("e11cbc4c-662f-48be-9318-ebf78df39ea1").then(res => {
    if (res.data.code === 200) {
      privacyText.value = res.data.data.content
    }
  })
}
onMounted(() => {
  window.scrollTo(0, 0)
  getPrivacy()
})
</script>

<style lang="less" scoped>
.page_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  display: flex;
  margin-top: .3rem;
  border-radius: .08rem;
  background-color: #fff;

  .content {

    .privacy {
      border-top-right-radius: .08rem;
      border-bottom-right-radius: .08rem;
      background-color: #fff;
      padding: .1rem .5rem 0.1rem .1rem;

      h4 {
        margin: .08rem 0;
      }

      p {
        line-height: .32rem;
      }

      .privacy_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .2rem;

        .right_nav {
          display: flex;
          align-items: center;
          color: #3496FB;
          font-weight: 600;


          .right_line {
            margin-left: .1rem;
            height: .05rem;
            width: .3rem;
            background-color: #3496FB;
          }

        }
      }
    }
  }
}
</style>