import { createStore } from 'vuex'

export default createStore({
  state: {
    pathActive: '/home',
    homeInputValue: '',
    searchCategory: '职位',
    choosePosition: ['不限', '不限', '不限', '不限'],
    chooseCompany: ['不限', '不限', '不限'],
    menuPitch: '/about',
    // positionSelectData: {name:'', addr: '',salaryRange: ["1", "20"],serviceYear: ''},
    positionSelectData: {salaryRange: ["1", "20"]},
    companySelectData: {},
    positionInputValue: '',
    companyInputValue: '',
    // positionSearchByName: '',
    showLoading: false,
    showOverlay: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
