import {createRouter, createWebHashHistory} from 'vue-router'
// import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "../views/home/HomeView";
import PositionView from "../views/position/PositionView.vue";
import CompanyView from "../views/company/CompanyView.vue";
import CompanyDetails from "../views/company/CompanyDetails.vue";
import CampusRecruitment from "../views/campusRecruitment/CampusRecruitmentView.vue";
import AdmissionTest from "@/views/recruitmentExamination/recruitmentExamination.vue";
import DetailsPosition from "../views/position/DetailsPositionView.vue";
import AboutView from "../views/statement/AboutView.vue"
import RulesView from "../views/statement/RulesView.vue"
import PrivacyView from "../views/statement/PrivacyView.vue"
import HelpView from "../views/statement/HelpView.vue"
import ProblemView from "../views/statement/ProblemView.vue"
import CounterFraud from "../views/statement/CounterFraud.vue"
import Development from "../views/statement/DevelopmentView.vue"
import Advantage from "../views/statement/AdvantageView.vue"
import CorporateCulture from "../views/statement/CorporateCulture.vue"
import SocialResponsibility from "../views/statement/SocialResponsibility.vue"

const routes = [
    // {
    //     path: "/",
    //     redirect: "/home",
    // },
    {
        path: "/home",
        name: "home",
        component: HomeView
    },
    {
        path: "/position",
        name: "positionView",
        component: PositionView
    },
    {
        path: "/detailspositon",
        name: "detailspositon",
        component: DetailsPosition,
        meta: {
            title: '职位详情-友脉人才网',
            keywords: '五险一金，周末双休，职位描述',
            description: '职位详细描述'
        }
    },
    {
        path: "/company",
        name: "companyView",
        component: CompanyView
    },
    {
        path: "/companyDetails",
        name: "companyDetails",
        component: CompanyDetails,
        meta: {
            title: '公司详情-友脉人才网',
            keywords: '靠谱公司详情',
            description: '为求职者提供公司详细信息，有更多了解公司的机会'
        }
    },
    {
        path: "/campusrecruitment",
        name: "campusrecruitment",
        component: CampusRecruitment,
        meta: {
            title: '校园招聘-友脉人才网',
            keywords: '校园招聘',
            description: '为莘莘学子提供各种靠谱岗位和公司，提供各种求职信息'
        }
    },
    {
        path: "/admissiontest",
        name: "admissiontest",
        component: AdmissionTest,
        meta: {
            title: '招录考-友脉人才网',
            keywords: '招录考',
            description: '招录考'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        meta: {
            title: '关于-友脉集团',
            keywords: '友脉集团，人力资源，教育培训，安保服务，互联网外包，呼叫中心，外包',
            description: '友脉控股集团有限公司（简称：友脉集团）是经国家工商总局核准，全国无地域限制无城市名称、合法注册的多元化集团企业'
        }
    },
    {
        path: '/rules',
        name: 'rules',
        component: RulesView,
        meta: {
            title: '规则-友脉人才网',
            keywords: '友脉人才网，规则，协议',
            description: '友脉人才网相关协议与规则'
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyView,
        meta: {
            title: '隐私政策-友脉人才网',
            keywords: '友脉人才网，隐私，政策',
            description: '友脉人才网相关隐私政策'
        }
    },
    {
        path: '/help',
        name: 'help',
        component: HelpView,
        meta: {
            title: '帮助-友脉人才网',
            keywords: '帮助，使用技巧',
            description: '友脉人才网版主，使用技巧'
        }
    },
    {
        path: '/problem',
        name: 'problem',
        component: ProblemView,
        meta: {
            title: '问题-友脉人才网',
            keywords: '问题，友脉，反馈',
            description: '友脉人才网相关问题，反馈'
        }
    },
    {
        path: '/counterfraud',
        name: 'counterfraud',
        component: CounterFraud,
        meta: {
            title: '防骗指南-友脉人才网',
            keywords: '防骗，虚假，指南',
            description: '友脉人才网防骗指南，虚假信息提醒'
        }
    },
    {
        path: '/development',
        name: 'development',
        component: Development,
        meta: {
            title: '发展-友脉集团',
            keywords: '发展，历程，',
            description: '“友脉”品牌创建于2014年，经过短短几年时间便发展成为集人力资源、教育培训、安保服务、档案管理、互联网外包、呼叫中心、园区运营等互联网+人力资源全产业链服务为一体的多元化综合服务企业集团。'
        }
    }, {
        path: '/advantage',
        name: 'advantage',
        component: Advantage,
        meta: {
            title: '优势-友脉集团',
            keywords: '人才，招人，找工作，灵活用工，劳务派遣',
            description: '集团实行西南昆明+华南广州双总部协同发展战略，立足西南、华南，面向全国，是中国人力资源服务企业中规模较大、经济实力强、服务规范和专业人力资源全产业链服务提供商集团目前拥有43余家控股/参股企业或分子公司、1所职业培训学校、1个省级众创空间、2个服务外包产业园'
        }
    }, {
        path: '/culture',
        name: 'culture',
        component: CorporateCulture,
        meta: {
            title: '文化-友脉集团',
            keywords: '聚友同创 合脉共宇',
            description: '聚焦客户需求，提供专业、优质、有竞争力的人力资源解决方案和服务，持续为客户创造最大价值'
        }
    }, {
        path: '/social',
        name: 'social',
        component: SocialResponsibility,
        meta: {
            title: '社会责任-友脉集团',
            keywords: '爱心，责任，公益，扶贫',
            description: '友脉集团作为企业公民，心怀大爱，将推动社会和慈善公益事业发展视为己任，在教育公益及扶贫公益方面持续不断地履行社会责，在社会公益道路上，友脉集团将不忘初心、矢志前行，为中国公益事业贡献自己的力量！'
        }
    },


];

// const router = createRouter({
// 	history: createWebHashHistory(),
// 	routes,
// });
const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

// const router = createRouter({
// 	// 使用hash的路由模式
// 	history: createWebHashHistory(),
// 	routes,
// 	// 每次切换路由的时候滚动到页面顶部
// 	scrollBehavior () {
// 		// vue2.0  x  y  控制
// 		// vue3.0  left  top 控制
// 		return { left: 0, top: 0 }
// 	}
// })

router.afterEach(() => {
    window.scrollTo(0,0);
});
router.beforeEach((to, from, next) => {



    if (to.meta.title) {
        document.title = to.meta.title
    }
    const el = document.querySelector('meta[name="keywords"]')
    if(el) {
        el.setAttribute('content', to.meta.keywords)
    } else {
        const head = document.head || document.getElementsByTagName('head')[0]
        let meta = document.createElement('meta')
        meta.name = 'keywords'
        meta.content = to.meta.keywords
        head.appendChild(meta)
    }

    const descriptionEl = document.querySelector('meta[name="description"]')
    if (descriptionEl) {
        descriptionEl.setAttribute('content', to.meta.description)
    } else {
        const head = document.head || document.getElementsByTagName('head')[0]
        let meta = document.createElement('meta')
        meta.name = 'description'
        meta.content = to.meta.description
        head.appendChild(meta)
    }
    next()
})

export default router;
