<template>
  <nav class="about_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="about">
      <nav class="about_nav">
        <h1>集团简介</h1>
        <div class="right_nav">
          <a href="http://www.youmaigroup.cn/" target="_blank">YOUMAI</a>
          <div class="right_line"></div>
        </div>
      </nav>
      <div class="about_main">
        <section>
          <div class="company_sketch">
            <img src="@/assets/image/statement/sketch.png" alt="简介">
            <!--        <div class="sketch_left">-->
            <!--          <img src="@/assets/image/about/about1.jpg" alt="">-->
            <!--        </div>-->
            <!--        <div class="sketch_right">-->
            <!--          <p style="line-height: 1.667vw"><span style="font-weight: 600;">友脉控股集团有限公司</span>（简称：友脉集团）是经国家工商总局核准，全国无地域限制无城市名称、合法注册的多元化集团企业。“友脉”品牌创建于2014年，经过短短几年时间便发展成为集人力资源、教育培训、安保服务、档案管理、互联网外包、呼叫中心、园区运营等互联网+人力资源全产业链服务为一体的多元化综合服务企业集团。友脉控股集团从创立之初就制定了立足西南华南、面向全国、辐射南亚东南亚发展的宏伟目标，启动西南昆明、华南广州双总部协同发展战略，以双轨道辐射模式，建立起有自身发展特色的集团业务体系，目前已覆盖云南省16个州市及广东、重庆、贵州、海南、广西、湖南、四川等省市区域。友脉控股集团目前拥有40余家控股、参股企业和分子公司，自有员工600余人，派遣/外包员工20000余人，带动社会就业／人才输出人员逾10万余人。已为超过数百家知名企业、政府及事业单位提供了服务。</p>-->
            <!--        </div>-->
          </div>
        </section>
        <section>
          <div class="company_glory">
            <img src="@/assets/image/statement/glory.png" alt="荣誉" />
            <!--        <div class="title_glory">-->
            <!--          <h3>所获荣誉</h3>-->
            <!--        </div>-->
            <!--        <div class="glory_content">-->
            <!--          <p style="line-height: 1.667vw; text-indent: 1.667vw">友脉控股集团荣获“云南省省级文明单位”、“云南省服务外包重点企业”、“云南省劳动关系和谐企业”、“云南省省级专精特新成长企业”、“云南省知识产权优势企业”“国家级科技型中小企业”、“广州市劳动关系和谐企业”、“国家税务总局A级纳税人企业”、“云南省服务业企业50强”等诸多荣誉。董事长和志勇1999年中山大学毕业后就加入大型央企工作，历经15年的互联网大企业历练，在人力资源、企业战略、营销创新等多方面提质升级、创新模式，成长为企业经营管理的高级人才。自主创业以来，董事长和志勇当选为“昆明市五华区人大代表”、被授予“云南省人力资源服务行业领军人物”、“云南省百强优秀民营企业家”、“云南省首批劳动关系专家智库成员”、 “全国工商联人力资源服务业委员会委员”、“中国人才交流协会人才测评专委会副秘书长”、“云南省民族团结进步促进会副会”、“昆明市五华区五华先锋党建名师”、“昆明市道德模范昆明好人”、“昆明市五华区新阶联副会长”、“昆明市企业家联合会副会长”、“昆明市就业促进会副会长”等荣誉。带领集团研发了多项发明及外观专利、软件著作权、核心技术和自主创新产品。</p>-->
            <!--        </div>-->
            <!--        <div class="certificate">-->
            <!--          <div class="qualified_item">-->
            <!--            <router-link to='/honer/1'><img src="@/assets/image/layout/footer_five.jpg" alt=""/></router-link>-->
            <!--          </div>-->
            <!--          <div class="qualified_item">-->
            <!--            <router-link to='/honer/2'>-->

            <!--              <img src="@/assets/image/layout/footer_six.jpg" alt=""/>-->
            <!--            </router-link>-->
            <!--          </div>-->
            <!--          <div class="qualified_item">-->
            <!--            <router-link to='/honer/5'>-->

            <!--              <img src="@/assets/image/layout/footer_seven.jpg" alt=""/>-->
            <!--            </router-link>-->

            <!--          </div>-->
            <!--          <div class="qualified_item" style="margin-right: 0">-->
            <!--            <router-link to='/honer/3'>-->
            <!--              <img src="@/assets/image/layout/footer_eight.jpg" alt=""/>-->

            <!--            </router-link>-->
            <!--          </div>-->
            <!--        </div>-->
          </div>
        </section>
      </div>
    </div>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { onMounted } from "vue";
import FooterView from "@/components/FooterView.vue";
import LayoutNav from "@/components/layoutNav.vue";
import StatementMenu from "@/components/StatementMenu.vue";

onMounted(() => {
  window.scrollTo(0, 0)
})

</script>

<style lang="less" scoped>
.about_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  display: flex;
  margin-top: .3rem;
  background-color: #fff;
  border-radius: .08rem;

  .about {
    .about_nav {
      padding: .1rem .5rem 0.1rem .1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-right-radius: .08rem;

      .right_nav {
        display: flex;
        align-items: center;

        a {
          color: #3496FB;
          font-weight: 600;
        }

        .right_line {
          margin-left: .1rem;
          height: .05rem;
          width: .3rem;
          background-color: #3496FB;
        }

      }
    }

    .about_main {
      width: 100%;
      padding: .1rem .5rem 0.1rem .1rem;

      .company_sketch {
        border: .01rem solid @small_Color;
        display: flex;
        padding: .2rem;
        box-sizing: border-box;

        .sketch_left {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 1.2rem;
          }
        }

        .sketch_right {
          flex: 4;
        }
      }

      .company_glory {
        margin-top: .1rem;
        border: .01rem solid @small_Color;
        padding: .2rem;

        .title_glory {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: .15rem;
        }
      }

      .certificate {
        display: flex;
      }
    }
  }
}</style>