<template>
    <div class="footer">

        <n-modal v-model:show="showModal">
            <n-card style="width: 6rem; height: 6.5rem" :bordered="false" size="huge" role="dialog" aria-modal="true">

                <img style="width: 100%; margin: 0 auto" src="@/assets/image/layout/nav_code.jpg" alt="小程序二维码"
                     title="小程序二维码"/>

            </n-card>
        </n-modal>
        <n-modal v-model:show="showModal1">
            <n-card style="width: 6rem; height: 6.5rem" :bordered="false" size="huge" role="dialog" aria-modal="true">

                <img style="width: 100%; margin: 0 auto" src="@/assets/image/layout/youmaijobqiye.jpg"
                     alt="小程序二维码" title="小程序二维码"/>

            </n-card>
        </n-modal>


        <div class="bottomStatement">

            <div class="bottom_desc">
                <div class="bottomMenu">
                    <div class="footer_left">
                        <div class="left_item" v-for="item in bottomMenu" :key="item.id">
                            <div class="item">
                                <div class="item_title">
                                    {{ item.title }}
                                </div>
                                <div class="item_list">
                                    <p class="list_name" @click="statementClick(i.name)" v-for="i in item.list"
                                       :key="i.id">
                                        <a>
                                            {{ i.name }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="code">
                        <div class="company_code">
                            <img src="@/assets/image/layout/company_code.jpg" alt="友脉集团官微"
                                 title="友脉集团官方微信号"/>
                            <p>集团官微</p>
                        </div>
                        <div class="service_code">
                            <img src="@/assets/image/layout/service_code.jpg" alt="友脉集团客服"
                                 title="友脉集团客服微信号"/>
                            <p>集团移动客服</p>
                        </div>
                        <!--            <div class="mini_code">-->
                        <!--              <img src="@/assets/image/layout/mini_code.jpg" alt="友脉JOB小程序" title="友脉JOB小程序"/>-->
                        <!--              <p>友脉JOB小程序</p>-->
                        <!--            </div>-->
                    </div>
                    <div class="line"></div>
                    <div class="other_desc">
                        <p>总机热线: 西南 0871-6538 8777 华南 020-8962 9399</p>
                        <p>移动客服(7x24小时): 153 9148 8888</p>
                        <p>西南总部: 云南省昆明市五华区德润中心A座写字楼26、27层</p>
                        <p>华南总部: 广东省广州市海珠区中山大学国家大学科技园B座13层</p>
                        <p>宫网链接: <a href="http://www.youmaigroup.cn" target="_blank">http://www.youmaigroup.cn</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="bottom_top">
        <span class="web_desc">
          Copyright ©2022-2024 友脉控股集团有限公司 YoumaiGroup.cn 版权所有 保留一切权利
        </span>
                <span class="web_record">
                    <img src="../assets/image/logo01.6189a29f.png" class="record_image" alt="备案图片">
          <a class="record_content" href="https://beian.mps.gov.cn/#/query/webSearch?code=53010202001965" rel="noreferrer" target="_blank">滇公网安备53010202001965</a>
        </span>
                <span class="web_records">
          <a href="https://beian.miit.gov.cn" rel="nofollow" ka="link-icp" target="_blank">滇ICP备2022002924号-3</a>
        </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const store = useStore()

const router = useRouter()

const bottomMenu = ref([
    {
        id: 1,
        title: '关于',
        list: [
            {
                id: 1,
                name: '关于我们'
            },
            {
                id: 2,
                name: '友脉JOB'
            },
            {
                id: 5,
                name: '友脉JOB企业端'
            },
            {
                id: 3,
                name: '协议与规则'
            },
            {
                id: 4,
                name: '隐私政策'
            }
        ]
    },
    {
        id: 2,
        title: '人才服务',
        list: [
            {
                id: 1,
                name: '职位搜索'
            },
            {
                id: 2,
                name: '公司搜索'
            },
            {
                id: 3,
                name: '手机找工作'
            }
        ]
    },
    {
        id: 3,
        title: '使用与帮助',
        list: [
            {
                id: 1,
                name: '使用帮助'
            },
            {
                id: 2,
                name: '常见问题'
            },
            {
                id: 3,
                name: '防骗指南'
            }

        ]
    }
])

const showModal = ref(false)

const showModal1 = ref(false)

const statementClick = (name) => {
    if (name === '关于我们') {
        router.push("/about")
        store.state.pathActive = ''
        store.state.menuPitch = '/about'
    } else if (name === '友脉JOB') {
        showModal.value = true
    } else if (name === '友脉JOB企业端') {
        showModal1.value = true
    } else if (name === '协议与规则') {
        router.push("/rules")
        store.state.pathActive = ''
        store.state.menuPitch = '/rules'
    } else if (name === '隐私政策') {
        router.push('/privacy')
        store.state.pathActive = ''
        store.state.menuPitch = '/privacy'
    } else if (name === '手机找工作') {
        showModal.value = true
    } else if (name === '使用帮助') {
        // router.push('/help')
        // store.state.pathActive = ''
        // store.state.menuPitch = '/help'
    } else if (name === '常见问题') {
        // router.push('/problem')
        // store.state.pathActive = ''
    } else if (name === '防骗指南') {
        router.push('/counterfraud')
        store.state.pathActive = ''
        store.state.menuPitch = '/counterfraud'
    } else if (name === '职位搜索') {
        router.push("/position")
        store.state.pathActive = '/position'
    } else if (name === '公司搜索') {
        router.push("/company")
        store.state.pathActive = '/company'
    }

}
</script>

<style lang="less" scoped>
.footer {
    margin-top: .44rem;


    .bottomStatement {
        width: 100%;
        background-color: #31383D;
        font-size: .14rem;
        color: @small_Color;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .36rem 0;

        .bottom_desc {
            display: flex;
            //margin-bottom: 1.042vw;

            .bottomMenu {
                width: 100%;
                display: flex;


                .footer_left {
                    display: flex;

                    .left_item {
                        .item {
                            display: flex;
                            flex-direction: column;
                            margin-right: .576rem;

                            .item_title {
                                font-size: .16rem;
                                color: #ffffff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                            }

                            .item_list {
                                margin-top: .08rem;

                                .list_name {
                                    margin-top: .1rem;
                                    font-size: .14rem;


                                    a {
                                        color: @wightColor;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;

                                        &:hover {
                                            color: @youmai_Color;
                                        }
                                    }


                                }
                            }
                        }
                    }
                }

                .line {
                    width: .02rem;
                    height: 1.5rem;
                    background-color: @wightColor;
                }

                .code {
                    display: flex;
                    align-items: center;
                    color: @wightColor;

                    img {
                        width: 1.3rem;
                    }

                    .company_code {
                        margin: 0 .2rem 0 .5rem;

                        p {
                            text-align: center;
                            margin-top: .1rem;
                        }
                    }

                    .service_code {
                        margin: 0 .5rem 0 .2rem;

                        p {
                            text-align: center;
                            margin-top: .1rem;
                        }
                    }

                    //.mini_code {
                    //  margin-left: 20px;
                    //  margin-right: 50px;
                    //  p {
                    //    text-align: center;
                    //    margin-top: 10px;
                    //  }
                    //}
                }

                .other_desc {
                    margin-left: .5rem;
                    color: #ffffff;

                    p {
                        line-height: .32rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;

                        //font-weight: 600;
                        a {
                            color: @wightColor;

                            &:hover {
                                color: @youmai_Color;
                            }
                        }
                    }
                }
            }
        }

        .bottom_top {
            display: flex;
            //justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: .4rem;

            .web_desc {
                color: @wightColor;
                line-height: .24rem;
            }

            .web_records {
                line-height: .24rem;

                a {
                    color: @wightColor;

                    &:hover {
                        color: @youmai_Color;
                    }
                }


            }

            .web_record {
                color: @wightColor;
                line-height: .24rem;
                display: flex;
                align-items: center;

                .record_image {
                    width: 0.18rem;
                    height: 0.18rem;
                    margin-right: .06rem;
                }

                .record_content {
                    color: @wightColor;
                    &:hover {
                        color: @youmai_Color;
                    }
                }
            }
        }

    }


}
</style>