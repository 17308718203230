<template>
  <!-- 搜索框 -->
  <div class="position_search">
    <div class="search_left"></div>
    <input class="search_input" type="text" :placeholder="placeholder" v-model="store.state.positionInputValue">
    <a class="search_btn" style="font-size: .18rem;" @click="searchPosition">搜索</a>
  </div>
</template>

<script setup>
import { ref, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter()
const store = useStore()
const placeholder = ref('请输入关键字')
const props = defineProps({
  placeholder: String
})
placeholder.value = toRefs(props).placeholder.value


const searchPosition = () => {
  store.state.positionSelectData = {
    name: store.state.positionInputValue
  }
  router.push("/position")

}
</script>


<style lang="less">
// 搜索框
.position_search {
  display: flex;
  width: 8rem;
  height: .6rem;
  margin: 0 auto;

  .search_left {
    width: .6rem;
    height: 100%;
    background-color: #fff;
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem;
  }

  .search_input {
    width: 6.4rem;
    border: 0;
  }

  .search_btn {
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4C80CC;
    color: #fff;
    font-size: .26rem;
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}
</style>