<template>
  <nav class="nav_sticky">
    <NavigationView></NavigationView>
  </nav>
  <div class="home">
    <!--    <MetaInfo />-->


    <div class="home_img ">
      <!--      <img src="@/assets/image/index/index14.jpg" alt="">-->
      <img v-for="(img, index) in imgs" :key="index" :src="img.src" :style="{ zIndex: index === currentIndex ? 1 : 0 }"
        :class="{ active: index === currentIndex }" v-bind:transition="transitionName" @click="changeImg"
        @mouseenter="mouseIn" @mouseleave="mouseOut" alt="banner图" title="友脉人才网" />
    </div>
    <div class="container">
      <!-- 首页搜索框 -->
      <section>
        <div class="home_choose">
          <!--        <SearchHome></SearchHome>-->
          <!-- 搜索框 -->
          <div class="position_search">
            <div class="search_left" style="width: 1rem">
              <select @change="selectChange($event)" class="choose_search_category">
                <option>职位</option>
                <option>公司</option>
              </select>
            </div>
            <input class="search_input" type="text" :placeholder="placeholder" @keydown="searchHandler"
              v-model="homeInputValue" />
            <a class="search_btn" style="font-size: .18rem;" @click="search">搜 索</a>
          </div>
        </div>
      </section>

      <!-- 首页分类 -->
      <section>
        <div class="home_category_show">
          <div class="home_category">
            <div class="category_top" @mouseleave="categoryId = 0">
              <ul>
                <li class="category_title" :class="categoryId === item.id ? 'hover' : ''"
                  @mouseenter="mouseenterData(item.id, item.category_name)" v-for="item in category[which_page - 1]"
                  :key="item.id">
                  <span class="bigTitle">{{ item.category_name }}</span>
                  <div class="small">
                    <a class="title_small" v-for="i in item.category_item" :key="i" @click="changeInputValue(i)">{{ i
                    }}</a>
                  </div>
                  <!--            <i class="iconfont icon-xiangyoujiantou"></i>-->
                </li>
              </ul>
              <!--        <div class="layer" v-if="categoryId" @mouseenter="mouseLayer">-->
              <!--          <h4 class="layer_title">{{ categoryTitle }}</h4>-->
              <!--          <ul>-->
              <!--            <li class="layer_list" v-for="menu in menuList[categoryId - 1]" :key="menu.id">-->
              <!--              <span class="layer_category_title">{{ menu.title }}</span>-->
              <!--              <span class="layer_category_data" v-for="(i, index) in menu.banner" :key="index">{{ i }}</span>-->
              <!--            </li>-->

              <!--          </ul>-->
              <!--        </div>-->
            </div>
            <div class="category_bottom" style="display: none">
              <div class="category_page">
                <span>{{ which_page }}</span>/<span>{{ total_pages }}</span>
              </div>
              <div class="page_turning">
                <div class="left" @click="previousPage">
                  <i class="iconfont icon-triangle-left"></i>
                </div>
                <div class="right" @click="nextPage">
                  <i class="iconfont icon-triangle-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="home_show">
            <table>
              <tr>
                <td @click="toCompanySearch1" rowspan="2" colspan="2" @mouseenter="image_td = true"
                  @mouseleave="image_td = false">
                  <n-carousel :space-between="20" draggable autoplay :interval="5000">
                    <img class="image_td" style="border-top-left-radius: .08rem" src="@/assets/image/index/swiper1.jpg"
                      alt="友脉人才网" title="友脉人才网" />
                    <img class="image_td" style="border-top-left-radius: .08rem" src="@/assets/image/index/swiper2.jpg"
                      alt="友脉人才网" title="友脉人才网" />
                    <img class="image_td" style="border-top-left-radius: .08rem" src="@/assets/image/index/swiper3.jpg"
                      alt="友脉人才网" title="友脉人才网" />
                  </n-carousel>

                  <div class="shade" v-if="image_td"></div>
                </td>
                <td @click="toCompanyDetails(52)" @mouseenter="image_td1 = true" @mouseleave="image_td1 = false">
                  <img src="@/assets/image/index/index7.jpg" style="border-top-right-radius: .08rem" alt="斗南花卉中心"
                    title="斗南花卉中心-友脉人才网" />
                  <div class="shade" v-if="image_td1"></div>
                </td>
              </tr>
              <tr>
                <td @click="toCompanyDetails(49)" @mouseenter="image_td2 = true" @mouseleave="image_td2 = false">
                  <img src="@/assets/image/index/index8.jpg" alt="通用水务局" title="通用水务局-友脉人才网" />
                  <div class="shade" v-if="image_td2"></div>
                </td>
              </tr>
              <tr>
                <td @click="toCompanyDetails(38)" @mouseenter="image_td3 = true" @mouseleave="image_td3 = false">
                  <img src="@/assets/image/index/index9.jpg" style="border-bottom-left-radius: .08rem" alt="友脉控股集团"
                    title="友脉控股集团" />
                  <div class="shade" v-if="image_td3"></div>
                </td>
                <td @click="toCompanyDetails(43)" @mouseenter="image_td4 = true" @mouseleave="image_td4 = false">
                  <img src="@/assets/image/index/index10.jpg" alt="湖南航空股份有限公司" title="湖南航空股份有限公司" />
                  <div class="shade" v-if="image_td4"></div>
                </td>
                <td @click="toCompanySearch2" @mouseenter="image_td5 = true" @mouseleave="image_td5 = false">
                  <img src="@/assets/image/index/index11.jpg" style="border-bottom-right-radius: .08rem" alt="通讯公司"
                    title="通讯公司" />
                  <div class="shade" v-if="image_td5"></div>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </section>
      <!-- 首页热招职位 -->
      <section>
        <div class="home_hot_position">
          <!-- 标题部分 -->
          <div class="hot_title">
            <div class="left">
              <div class="title_icon"></div>
              <h1 class="left_title">热招职位</h1>
            </div>
            <div class="right">
              <a @click="toPosition">查看全部 <i class="iconfont icon-xiangyoujiantou"></i></a>
            </div>
          </div>
          <!-- 卡片展示部分 -->
          <div class="card_show_position container">
            <n-grid x-gap="12" y-gap="12" cols="3">
              <n-grid-item @click="toPositionDetail(item.needPositionId)" v-for="item in hotPosition"
                :key="item.needPositionId">
                <div class="card box-shadow">
                  <div class="box" v-if="item.isHot">
                    <div class="d">
                      <span class="d_word">热招职位</span>
                    </div>
                  </div>
                  <div class="card_header">
                    <a class="card_title">{{ item.name }}</a>
                    <a class="card_subtitle">{{ item.minSalary }}-{{ item.maxSalary }}K</a>
                  </div>
                  <div class="card_body">
                    <a class="require">{{ item.servicesYear }} | {{ item.educationValue }}</a>
                    <a class="position_desc">
                      <a class="welfare" v-for="(welfare, index) in item.welfareList" :key="index">{{ welfare }}</a>
                      <!--          <span class="welfare">更多</span>-->
                    </a>
                  </div>
                  <div class="card_footer">
                    <div class="bottom_content">
                      <div class="bottom_img">

                        <img :src="item.companyInfo.logoUrl" :alt="item.companyInfo.name" :title="item.companyInfo.name">
                      </div>
                      <a class="bottom_title">{{ item.companyInfo.name }}</a>
                    </div>
                  </div>
                </div>
              </n-grid-item>
            </n-grid>
          </div>
        </div>
      </section>
      <!-- 小卡片 月入万元部分 -->
      <section>
        <div class="small_card">
          <div class="card_item">
            <img src="@/assets/image/index/index3.jpg" alt="靠谱好工作" title="靠谱好工作-友脉人才网" />
          </div>
          <div class="card_item">
            <img src="@/assets/image/index/index4.jpg" alt="名企之家" title="名企之家-友脉人才网" />
          </div>
          <div class="card_item">
            <img src="@/assets/image/index/index5.jpg" alt="金牌HR" title="真实有效-友脉人才网" />
          </div>
        </div>
      </section>
      <!-- 名企推荐 -->
      <section>
        <!-- 名企推荐 -->
        <div class="famous_recommend">
          <!-- 标题部分 -->
          <div class="famous_title">
            <div class="left">
              <div class="title_icon"></div>
              <h1 class="left_title">名企推荐</h1>
            </div>
            <div class="right">
              <a @click="toComponent">查看全部 <i class="iconfont icon-xiangyoujiantou"></i></a>
            </div>
          </div>
          <!-- 卡片展示区域 -->
          <div class="famous_card">
            <n-grid x-gap="12" :y-gap="12" cols="4" responsive="screen">
              <n-grid-item @click="toCompanyDetailsPage(item.companyId)" v-for="item in famousCompany"
                :key="item.companyId">
                <div class="card_item box-shadow">
                  <a class="card_item_img">
                    <img :src="item.logoUrl" :alt="item.name" :title="item.name" />
                  </a>
                  <a class="item_name">
                    <a>{{ item.name }}</a>
                  </a>
                  <div class="item_info">
                    <a class="industry">{{ item.professionStr }}</a>|<a class="scale">{{ item.crewSize }}</a>
                  </div>
                  <div class="positionNum">
                    <div class="num_style">
                      <a class="num_info">
                        <a class="num">{{ item.positionCount }}</a>个热招职位
                      </a>
                    </div>
                  </div>
                </div>
              </n-grid-item>
            </n-grid>

          </div>
        </div>
      </section>
      <!-- 热门资讯 -->
      <section>
        <HotNewsHome style="display: none"></HotNewsHome>
      </section>

      <section>
        <SwiperHonor></SwiperHonor>
      </section>
    </div>

    <footer>
      <FooterView></FooterView>
    </footer>

  </div>
</template>

<script setup>
import HotNewsHome from "@/views/home/components/HotNewsHome";
import { getHomeDataHandler } from "@/request/homeAPI"
import { ref, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SwiperHonor from "@/views/home/components/swiperHonor.vue";
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
import { useHead } from '@vueuse/head'
const store = useStore()
const router = useRouter()

/*分类*/
const category = ref([
  [
    {
      id: 1,
      category_name: '互联网',
      category_item: ['Java', '.NET', 'PHP', 'C', 'C++']
    },
    {
      id: 2,
      category_name: '销售',
      category_item: ['销售总监', '销售VP', '销售经理']
    },
    {
      id: 3,
      category_name: '人事',
      category_item: ['CMO', '厂长/副厂长', '总裁/总经理']
    },
    {
      id: 4,
      category_name: '媒体',
      category_item: ['创意总监', '广告客户经理']
    },
    {
      id: 5,
      category_name: '教育',
      category_item: ['校长/副校长', '教务管理']
    },
    {
      id: 6,
      category_name: '金融',
      category_item: ['证券/期货/外汇经纪人', '证券分析师']
    },
    {
      id: 7,
      category_name: '生产',
      category_item: ['采购经理/主管', '采购员']
    },
    {
      id: 8,
      category_name: '运营',
      category_item: ['新媒体运营', '产品运营', '网络推广']
    }
  ],
])
const which_page = ref(1)
const total_pages = ref(3)

const toCompanySearch1 = () => {
  router.push("/company")
  store.state.pathActive = '/company'
}
const toCompanySearch2 = () => {
  router.push({ name: 'companyView', query: { inputValue: '通讯' } })
  store.state.pathActive = '/company'
}

const toCompanyDetails = (id) => {
  router.push({
    path: '/companyDetails',
    query: {
      id
    }
  })
  store.state.pathActive = '/company'
}

const changeInputValue = (value) => {
  homeInputValue.value = value
  router.push({ name: 'positionView', query: { inputValue: homeInputValue.value } })
}

let categoryId = ref(0)
let categoryTitle = ref('互联网')

// const positionCategory = []

const mouseenterData = (id, title) => {
  categoryId.value = id
  categoryTitle.value = title
}

let image_td = ref(false)
let image_td1 = ref(false)
let image_td2 = ref(false)
let image_td3 = ref(false)
let image_td4 = ref(false)
let image_td5 = ref(false)


//上一页
const previousPage = () => {
  if (which_page.value === 1) {
    return
  }
  which_page.value--;
}

//下一页
const nextPage = () => {
  if (which_page.value === total_pages.value) {
    return
  }
  which_page.value++
}
/* head标签 */
useHead(
  {
    title: '首页-友脉人才网',
    meta: [
      {
        name: 'keywords',
        content: '人才，招人，找工作，灵活用工，劳务派遣，友脉集团，外包，人力资源'
      },
      {
        name: 'description',
        content: '友脉人力是友脉集团旗下核心业务板块，专注为客户提供人力资源整体解决方案，基于业务外包、劳务外包、劳务派遣、劳务承揽、招聘猎头、培训咨询、社保代理、灵活用工、HR信息化和人力资源产业园运营等核心服务，致力于打造友脉人力生态圈，成为西南HR行业领军品牌'
      }
    ]
  }
)

/* 首页轮播图开始 */
const imgs = ref([
  { src: new URL('../../assets/image/index/banner4.png', import.meta.url).href },
  { src: new URL('../../assets/image/index/banner1.png', import.meta.url).href },
  { src: new URL('../../assets/image/index/banner2.png', import.meta.url).href },
  { src: new URL('../../assets/image/index/banner3.png', import.meta.url).href },
])
const currentIndex = ref(0)
const transitionName = ref('fade')
const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % imgs.value.length;
}
const mouseIn = () => {
  // transitionName.value = 'none'
  clearInterval(timer)
}

const mouseOut = () => {
  // transitionName.value = 'fade'
  timer = setInterval(nextSlide, 8000)
}

let timer = setInterval(nextSlide, 8000)
const changeImg = () => {
  currentIndex.value = (currentIndex.value + 1) % imgs.value.length;
}
/* 轮播图结束 */

/* 获取数据，生命周期函数 */
onBeforeMount(() => {
  getHomeData()
})

onMounted(() => {
  store.state.pathActive = '/home'
})

/* 搜索框 */
const placeholder = ref('请输入关键字')

const searchCategory = ref('职位')
const selectChange = (e) => {
  searchCategory.value = e.target.value
}

const homeInputValue = ref('')

const search = () => {
  if (searchCategory.value === '职位') {
    router.push({ name: 'positionView', query: { inputValue: homeInputValue.value } })
  } else if (searchCategory.value === '公司') {
    router.push({ name: 'companyView', query: { inputValue: homeInputValue.value } })
  }
}

const searchHandler = (e) => {
  if (e.key === 'Enter') {
    search()
  }
}


/* 获取首页数据开始 */
const getHomeData = () => {
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
  getHomeDataHandler().then(res => {
    if (res.data.code === 200) {
      res.data.data.position.map(item => {
        if (item.welfareList) {
          item.welfareList = item.welfareList.split(";")
          if (item.welfareList.length > 4) {
            item.welfareList.length = 4
            item.welfareList.push("···")
          }
        } else {
          item.welfareList = ["福利多多"]
        }
        if (item.servicesYear) {
          if (item.servicesYear === 0) {
            item.servicesYear = '不限经验'
          } else {
            item.servicesYear = item.servicesYear + '年'
          }
        } else {
          item.servicesYear = '不限经验'
        }
      })
      hotPosition.value = res.data.data.position
      famousCompany.value = res.data.data.company
      store.state.showLoading = false
      store.state.showOverlay = false
      document.body.style.overflow = ""
    }
  })
}
const hotPosition = ref([])

const famousCompany = ref([])
/* 获取首页数据结束 */

/* 去公司页面 */
const toCompanyDetailsPage = (id) => {
  router.push({
    path: '/companyDetails',
    query: {
      id
    }
  })
}

/*去职位页面*/
const toPositionDetail = (id) => {
  router.push({
    path: '/detailspositon',
    query: {
      id
    }
  })
  store.state.pathActive = '/positon'
}
</script>



<style lang="less" scoped>
.home {
  .loading_animate {
    z-index: 9999;
    position: fixed;
    top: 35%;
    left: 45%;
  }

  .home_img {
    width: 19.2rem;
    height: 1.92rem;
    //height: 10.9375vw;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // height: 100%;
      opacity: 0;
      transition: opacity 2s ease-in-out;
      z-index: 99;
    }

    .active {
      opacity: 1;
    }
  }

  // 首页选择框模块
  .home_choose {
    margin: .3rem 0;

    .position_search {
      display: flex;
      width: 8.8rem;
      height: .6rem;
      margin: 0 auto;

      .search_left {
        width: 2.88rem;
        height: 100%;
        background-color: #fff;
        border-top-left-radius: 3.125vw;
        border-bottom-left-radius: 3.125vw;
        display: flex;
        justify-content: center;
        align-items: center;

        .choose_search_category {
          border: none;
          //-webkit-appearance: none;
          font-size: .12rem;
          color: @small_Color;
        }

        select {
          outline: none;
        }
      }

      .search_input {
        width: 6.4rem;
        border: 0;
      }

      .search_btn {
        width: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4C80CC;
        color: #fff;
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
      }
    }
  }

  // 首页分类
  .home_category_show {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: space-between;


    .home_category {
      background-color: #fff;
      width: 3.945rem;
      position: relative;
      border-radius: .08rem;

      .hover {
        box-shadow: .05rem .05rem .05rem #EBEEF5, -.05rem -.05rem .05rem #EBEEF5;
        background-color: #fff;
      }

      .category_top {
        ul {
          list-style: none;
          padding-top: .12rem;
          padding-left: 0;

          .category_title {
            height: .4rem;
            line-height: .43rem;
            font-size: .16rem;
            font-weight: 600;
            color: @big_Color;
            display: flex;
            justify-content: space-between;
            padding-right: .2rem;
            padding-left: .2rem;

            &:hover {
              box-shadow: .05rem .05rem .05rem #EBEEF5, -.05rem -.05rem .05rem #EBEEF5;
              background-color: #fff;
            }

            .bigTitle {
              width: .61rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .small {
              width: 2.93rem;
              display: flex;
              margin-left: .1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              .title_small {
                font-size: .12rem;
                color: @small_Color;
                margin-right: .1rem;
              }
            }

            .iconfont {
              font-size: .14rem;
            }

          }
        }

        .layer {
          z-index: 9;
          width: 8.4rem;
          height: 3.53rem;
          background: rgba(230, 230, 230, 0.9);
          position: absolute;
          left: 3.6rem;
          top: 0;
          //display: none;
          padding: .15rem;
          overflow: auto;

          &::-webkit-scrollbar {
            width: .1rem;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background: #f9f7fa;
          }

          .layer_title {
            font-size: .2rem;
            color: @big_Color;
            margin-bottom: .25rem;
          }

          ul {
            .layer_list {
              margin-bottom: .25rem;

              .layer_category_title {
                font-size: .16rem;
                color: @small_Color;
                margin-right: .25rem;
              }
            }
          }
        }
      }



      .category_bottom {
        border-top: #C0CAD3 dashed .02rem;
        display: flex;
        justify-content: space-between;
        height: .4rem;
        margin: .05rem .1rem 0 .1rem;
        padding-top: .1rem;


        .category_page {
          display: flex;
          align-items: center;
          height: 100%;
          margin-left: .1rem;
          color: #C0CAD3;
        }

        .page_turning {
          display: flex;
          align-items: center;
          height: 100%;
          margin-right: .1rem;

          .left {
            width: .24rem;
            height: .24rem;
            background-color: #F5F6F8;
            margin-right: .1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .iconfont {
              color: #A2AFBA;
            }
          }

          .right {
            width: .24rem;
            height: .24rem;
            background-color: #F5F6F8;
            display: flex;
            justify-content: center;
            align-items: center;

            .iconfont {
              color: #A2AFBA;
            }
          }
        }
      }


    }

    .home_show {
      height: 100%;
      width: 8.3rem;
      display: flex;
      justify-content: space-between;
      border-radius: .08rem;
      background-color: #fff;

      table {
        width: 100%;
        height: 100%;

        tr {
          td {
            width: 2.65rem;
            height: 1.15rem;
            position: relative;
            padding: 0;

            img {
              width: 5.5rem;
              height: 2.3rem;
            }

            .image_td {
              position: absolute;
              top: 0;
              left: 0;
              width: 5.5rem;
              height: 2.32rem;
            }

            .shade {
              background-color: rgba(204, 204, 204, .5);
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }

            .danmu-canvas {
              width: 5.5rem;
              height: 2.3rem;
              display: block;
              background-color: rgba(255, 255, 255, 0);
              position: absolute;
              top: 0;
              left: 0;
              z-index: 999999;
              //top: 240px;
            }
          }
        }
      }

    }
  }

  // 首页热招职位模块
  .home_hot_position {
    margin-top: .4rem;

    // 标题
    .hot_title {
      display: flex;
      justify-content: space-between;
      margin-bottom: .2rem;

      .left {
        display: flex;
        align-items: center;
        width: 1rem;
        height: .2rem;

        .title_icon {
          flex: 1;
          height: .2rem;
          display: flex;
          align-items: center;
          border-left: #FD6300 solid .08rem;
        }

        .left_title {
          flex: 9;
          font-size: .2rem;
          font-weight: 700;
          color: @big_Color;
          width: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .right {
        font-size: .16rem;
        color: @small_Color;

        a {
          font-size: .16rem;
          color: @small_Color;
        }

        .iconfont {
          font-size: .12rem;
        }
      }

    }

    // 卡片
    .card_show_position {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .card {
        //width: 21.104vw;
        width: 100%;
        border: none;
        border-radius: .06rem;
        -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        word-break: break-all;
        padding: .3rem;
        height: 2.3rem;
        //margin-bottom: 1.09375vw;
        padding-left: .4rem;
        position: relative;
        overflow: hidden;

        .box {
          position: absolute;
          margin-top: -0.16rem;
          margin-left: -0.62rem;
          width: .96rem;
          height: .25rem;
          background-color: #00CD9A;
          transform: rotate(-45deg);

          .d {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            .d_word {
              color: #ffffff;
              font-size: .12rem;
              display: flex;
              justify-content: center;
              align-items: center;

            }
          }
        }


        .card_header {
          display: flex;
          justify-content: space-between;
          font-size: .16rem;

          .card_title {
            font-size: @titleSize;
            font-weight: 700;
            color: @big_Color;

            &:hover {
              color: @youmai_Color;
            }
          }

          .card_subtitle {
            color: #FEA55C;
            font-size: @salarySize;
            //font-weight: 300;
          }
        }

        .card_body {
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          border-bottom: .01rem solid #EEEEEE;
          display: flex;
          flex-direction: column;

          .require {
            font-size: .14rem;
            padding-top: .12rem;
          }

          .position_desc {
            margin: .12rem 0;

            .welfare {
              border: .01rem solid @welfareColor;
              padding: .05rem .05rem;
              border-radius: .05rem;
              margin-right: .08rem;
              font-size: .12rem;
              color: @welfareColor;
              box-sizing: border-box;
            }
          }
        }

        .card_footer {
          display: flex;
          align-items: center;
          height: .7rem;

          .bottom_content {
            display: flex;
            align-items: center;
            justify-content: center;

            .bottom_img {
              width: .38rem;
              height: .38rem;
              border-radius: .06rem;
              border: .01rem solid #F2F3F6;
              margin-right: .12rem;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                border-radius: .06rem;
              }
            }

            .bottom_title {
              font-size: @companySize;
              color: #8EA5BA;
            }
          }

        }
      }
    }


    .card:hover {
      box-shadow: 0 1rem 2rem 0 rgba(48, 55, 66, 0.15);
      transform: translate(0, -0.05rem);
      transition-delay: 0s !important;
    }

    .box-shadow {
      -webkit-box-shadow: 0 .04rem .16rem rgba(37, 45, 58, 0.15);
      box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
    }

  }

  // 小卡片展示部分
  .small_card {
    margin-top: .22rem;
    display: flex;
    justify-content: space-between;

    .card_item {
      width: 3.9rem;
      height: 1.1rem;
      border-radius: .08rem;

      img {
        width: 100%;

        border-radius: .08rem;
      }
    }
  }

  // 名企推荐部分
  .famous_recommend {
    margin-top: .34rem;
    margin-bottom: .3rem;

    .famous_title {
      display: flex;
      justify-content: space-between;
      margin-bottom: .2rem;


      .left {
        display: flex;
        align-items: center;
        width: 1rem;
        height: .2rem;

        .title_icon {
          flex: 1;
          height: .2rem;
          display: flex;
          align-items: center;
          border-left: #FD6300 solid 0.417vw;
        }

        .left_title {
          flex: 9;
          font-size: .2rem;
          font-weight: 700;
          width: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .right {
        font-size: .16rem;
        color: @small_Color;
        //width: 4.958vw;

        a {
          font-size: .16rem;
          color: @small_Color;
        }

        .iconfont {
          font-size: .12rem;

        }
      }
    }

    .famous_card {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;


      .card_item {
        //width: 15vw;
        //height: 15vw;
        padding: .4rem 0;
        background-color: #fff;
        //margin: 0 0 0.833vw 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: .06rem;
        -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        word-break: break-all;

        &:hover {
          box-shadow: 0 .16rem .32rem 0 rgba(48, 55, 66, 0.15);
          transform: translate(0, -0.26vw);
          transition-delay: 0s !important;
        }

        .card_item_img {
          width: .7rem;
          height: .7rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: .22rem;
          border-radius: .08rem;
          border: #F2F3F6 solid .01rem;

          img {
            width: 100%;

            border-radius: .08rem;
          }
        }

        .item_name {
          width: 95%;
          height: .27rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .2rem;
          font-weight: 700;
          margin-bottom: .16rem;


          a {
            color: @big_Color;

            &:hover {
              color: @youmai_Color;
            }
          }



          a {
            width: 2.4rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: @big_Color;
          }
        }

        .item_info {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: @small_Color;
          margin-bottom: .26rem;

          .industry {
            width: .8rem;
            margin-right: .1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: @small_Color;
          }

          .scale {
            margin-left: .1rem;
            color: @small_Color;
          }
        }

        .positionNum {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: @small_Color;

          .num_style {
            width: 2rem;
            height: .4rem;
            background-color: #fff;
            border: .01rem solid @small_Color;
            border-radius: .2rem;

            .num_info {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: @small_Color;

              .num {
                color: #FC9B3F;
              }
            }
          }
        }

      }

      .box-shadow {
        -webkit-box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
        box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
      }
    }

  }
}
</style>
