<template>
  <!--求职帮助 -->
  <div class="jobs_help container">
    <div class="help_category shadow" v-for="item in jobs_help" :key="item.id">
      <div class="help_title" :class="'bgc'+item.id">{{ item.name }}</div>
      <div class="help_content">
        <div class="content_list" v-for="i in item.help" :key="i.lid">
          <span class="content">{{ i.content }}</span>
        </div>
        <div class="more_help">
          <div class="more">
            <span>查看更多</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";

const jobs_help = ref([
  {
    id: 1,
    name: '职场话题',
    help: [
      {
        lid: 1,
        content: '老板说：想回来随时欢迎，公司的大门为你敞开'
      },
      {
        lid: 2,
        content: '遇到这些吸血公司，一定要赶紧远离'
      },
      {
        lid: 3,
        content: '上班白上了！工资抵不上花销'
      },
      {
        lid: 4,
        content: '面试时大胆提问，打探公司基本情况'
      },
      {
        lid: 5,
        content: '不注意这些，会陷入低效求职境地'
      },
    ]
  },
  {
    id: 2,
    name: '简历指导',
    help: [
      {
        lid: 1,
        content: '经营管理专业'
      },
      {
        lid: 2,
        content: '南昌航空大学高校建立模板'
      },
      {
        lid: 3,
        content: '深证大学'
      },
      {
        lid: 4,
        content: '应届毕业生个人简历表格'
      },
      {
        lid: 5,
        content: '业务员简历样本 '
      },
    ]
  },
  {
    id: 3,
    name: '无忧求职攻略',
    help: [
      {
        lid: 1,
        content: '跳槽路上一定要拒绝的8件事情'
      },
      {
        lid: 2,
        content: '想离职就赶紧去面试，越拖越没有勇气去离职'
      },
      {
        lid: 3,
        content: '为什么你吐槽公司无数遍后还会继续在这家公司工作'
      },
      {
        lid: 4,
        content: 'HR最喜欢看到简历中有这些字眼，让你更容易获得青睐'
      },
      {
        lid: 5,
        content: '年轻人跳槽避免跳坑，排查渣子公司，渣子职位 '
      },
    ]
  },
  {
    id: 4,
    name: '职场资讯',
    help: [
      {
        lid: 1,
        content: '跳槽路上一定要拒绝的8件事情'
      },
      {
        lid: 2,
        content: '想离职就赶紧去面试，越拖越没有勇气去离职'
      },
      {
        lid: 3,
        content: '为什么你吐槽公司无数遍后还会继续在这家公司工作'
      },
      {
        lid: 4,
        content: 'HR最喜欢看到简历中有这些字眼，让你更容易获得青睐'
      },
      {
        lid: 5,
        content: '年轻人跳槽避免跳坑，排查渣子公司，渣子职位 '
      },
    ]
  },
])
</script>

<style lang="less" scoped>
.jobs_help {
  margin-top: 2.604vw;
  height: 19.271vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 0.417vw;

  .help_category {
    width: 14.0625vw;
    height: 17.1875vw;
    border: 0.052vw solid @small_Color;
    margin-left: 1.25vw;
    border-radius: 0.417vw;

    .help_title {
      height: 3.125vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.26vw;
      font-size: 1.25rem;
      font-weight: 700;
      border-top-left-radius: 0.417vw;
      border-top-right-radius: 0.417vw;
    }

    .bgc1 {
      background: url("@/assets/image/campus/campus6.jpg");
      background-size: 14.0625vw 3.125vw;
    }
    .bgc2 {
      background: url("@/assets/image/campus/campus7.jpg");
      background-size: 14.0625vw 3.125vw;
    }
    .bgc3 {
      background: url("@/assets/image/campus/campus8.jpg");
      background-size: 14.0625vw 3.125vw;
    }
    .bgc4 {
      background: url("@/assets/image/campus/campus9.jpg");
      background-size: 14.0625vw 3.125vw;
    }


    .help_content {
      padding: 0.417vw 1.198vw;

      .content_list {
        height: 2.083vw;
        display: flex;
        justify-content: center;
        align-items: center;


        .content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          color: @small_Color;

        }
      }

      .more_help {
        margin-top: 0.521vw;

        .more {
          padding-top: 0.521vw;
          border-top: 0.052vw dashed @small_Color;
          text-align: right;
          color: #80C842;
          font-weight: 600;
        }

      }
    }
  }
}
</style>