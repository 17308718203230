<template>
  <nav>
    <NavigationView></NavigationView>
  </nav>
  <!-- 校园招聘 -->
  <div class="campus" v-if="show">
    <div class="campus_img">
      <img src="@/assets/image/campus/campus1.jpg" alt="校园招聘" />
    </div>
    <SearchView placeholder="找兼职/实习/全职"></SearchView>
    <!-- 最新资讯 -->
    <NewNews></NewNews>

    <div class="show_campus container">
      <div class="show_img">

      </div>
    </div>

    <!--热门岗位-->
    <HotJobs></HotJobs>

    <!-- 宣传图片 -->
    <div class="publicity_picture container">
      <div class="img1">
        <img src="@/assets/image/campus/campus1.jpg" alt="校园招聘" />
      </div>
      <div class="img2">
        <img src="@/assets/image/campus/campus4.jpg" alt="校园招聘" />
      </div>
      <div class="img3">
        <img src="@/assets/image/campus/campus5.jpg" alt="校园招聘" />
      </div>
    </div>

    <!--名企直招-->
    <FamousInvite></FamousInvite>

    <!--求职帮助 -->
    <JobsHelp></JobsHelp>
  </div>
  <div class="campusNo container" v-else>
    <n-result
        status="info"
        title="校园招聘页面"
        description="程序员正在加急开发中···"
    >
      <template #footer>
        <n-button @click="back">点击返回首页</n-button>
      </template>
    </n-result>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import SearchView from "@/components/SearchView.vue"
import NewNews from "@/views/campusRecruitment/components/NewNews";
import HotJobs from "@/views/campusRecruitment/components/HotJobs";
import FamousInvite from "@/views/campusRecruitment/components/FamousInvite";
import JobsHelp from "@/views/campusRecruitment/components/JobsHelp";
import {onMounted, ref} from "vue";


import {useRouter} from "vue-router";
import {useStore} from "vuex";
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
const store = useStore()
const router = useRouter()

const back = () => {
  router.push("/home")
  store.state.pathActive = '/home'
}

let show = ref(false)

onMounted(() => {
  store.state.pathActive = '/campusrecruitment'
  window.scrollTo(0,0)
})

</script>

<style lang="less" scoped>
.campus {
  .campus_img {
    width: 100%;
    background-color: @youmai_Color;
    margin-bottom: 2.604vw;
    img {
      width: 100%;
      height: 100%;
    }
  }


  //云招聘板报
  .show_campus {

    .show_img {
      height: 13.021vw;
      border-radius: 3.90625vw;

      background: url("@/assets/image/campus/campus2.jpg") no-repeat center;
      background-size: 62.5vw 6.771vw;
    }
  }


  
  //宣传图片
  .publicity_picture {
    margin-top: 2.083vw;
    height: 5.99vw;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.521vw;
    border-radius: 0.417vw;
    .img1 {
      width: 19.792vw;
      height: 4.427vw;
      background-color: #4C80CC;
      border-radius: 0.417vw;
      img {
        border-radius: 0.417vw;
      }
    }
    .img2 {
      width: 19.792vw;
      height: 4.427vw;
      background-color: gray;
      border-radius: 0.417vw;
      img {
        border-radius: 0.417vw;
      }
    }
    .img3 {
      width: 19.792vw;
      height: 4.427vw;
      background-color: skyblue;
      border-radius: 0.417vw;
      img {
        border-radius: 0.417vw;
      }
    }
  }




}
.campusNo {
  margin-top: 0.36rem;
  background-color: #fff;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>