<template>
  <!-- 工作详情 -->
  <div class="work_details shadow">
    <div class="title">公司介绍</div>
    <p class="component_intro">1、公司简介</p>
    <div class="text-wrap" :style="all ? 'display: inline-block' : ''">
      <p class="text" v-html="work.info">
      </p>
    </div>
    <!--    <div class="all_btn">-->
    <!--      <span v-if="!all" @click="seeAll">查看全部<i class="iconfont icon-xiangxia"></i></span>-->
    <!--      <span v-else @click="seeAll">收起<i class="iconfont icon-xiangshang"></i></span>-->
    <!--    </div>-->
    <!-- 工商信息 -->
    <div class="business_information">
      <div class="business_information_title">工商信息</div>
      <div class="business_content">
        <table class="table">
          <tbody>
            <tr>
              <td style="background-color:#F2F9FC; width: 1.01rem">企业名称</td>
              <td style="width: 3.03rem">{{ work.name }}</td>
              <td style="background-color:#F2F9FC;width: 1.01rem">法定代表人</td>
              <td colspan="3">{{ work.legalPerson }}</td>
            </tr>
            <tr>
              <td style="background-color:#F2F9FC;">成立时间</td>
              <td>{{ work.registerDate }}</td>
              <td style="background-color:#F2F9FC;">注册资金</td>
              <td>{{ work.registerFund }}</td>
            </tr>
            <tr>
              <td style="background-color:#F2F9FC;">所属地区</td>
              <td colspan="5">{{ work.province }}{{ work.city }}{{ work.region }}</td>
            </tr>
            <tr>
              <td style="background-color:#F2F9FC;">所属行业</td>
              <td colspan="5">{{ work.professionStr }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <!--工作地址-->
    <div class="work_address">
      <div class="title">
        <span>工作地址</span>
      </div>
      <div class="work_map">
        <div class="map_address">
          <p class="address"><i class="iconfont icon-dizhi1"></i>{{ work.addrShow }}</p>
          <!--          <span class="openMap" @click="openMap = !openMap">展开地图</span>-->
        </div>
        <div id="map">
          <p v-if="showAddr" class="noAddr">暂无地图数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, toRefs } from 'vue';
// import jsonp from 'jsonp'
import { jsonp } from 'vue-jsonp';

const props = defineProps({
  companyDetails: Object
})
const work = ref({})
work.value = toRefs(props).companyDetails.value
const params = {
  headers: { "content-type": "application/xml" },
  callbackQuery: "callbackParam",
  callbackName: "jsonpCallback"
}
let count = ref(0);
let timer = setInterval(() => {
  count.value++
  let url = `https://apis.map.qq.com/ws/geocoder/v1/?address=${work.value.addr}&key=63GBZ-VDY6F-UGNJW-JALQW-OSJL5-CTBGL&sig=In2AS4k1gQ5IJLEGBvynqq6Ks1cn0DH&output=jsonp&callback="jsonpCallback`;
  jsonp(url, params).then(res => {
    if (res.status === 0) {
      showAddr.value = false
      let map = new TMap.Map(document.getElementById('map'), {
        // eslint-disable-next-line no-undef
        center: new TMap.LatLng(res.result.location.lat, res.result.location.lng),//设置地图中心点坐标
        zoom: 17.2,   //设置地图缩放级别
      });
      //创建并初始化MultiMarker
      // eslint-disable-next-line no-undef,no-unused-vars
      let marker = new TMap.MultiMarker({
        map: map,  //指定地图容器
        //样式定义
        styles: {
          // 点标记样式
          // eslint-disable-next-line no-undef
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        //点标记数据数组
        geometries: [{
          // 标记位置(纬度，经度，高度)
          // eslint-disable-next-line no-undef
          position: new TMap.LatLng(res.result.location.lat, res.result.location.lng),
          id: 'marker',
        }
        ]
      });
      // eslint-disable-next-line no-undef
      // map.setCenter(new TMap.LatLng(lat.value,lng.value));
      clearInterval(timer)
      map.on("tilesloaded", function () {
      })
    } else {
      if (count.value === 20) {
        clearInterval(timer)
      }
      showAddr.value = true
    }
    // eslint-disable-next-line no-undef
  }).catch(err => {
    console.log('err', err);
  })
}, 500)





// let address = ref('')
const all = ref(false)
// let openMap = ref(false)

// const address = ref()


const showAddr = ref(false)

onMounted(() => {

})

</script>

<style lang="less" scoped>
// 工作详情
.work_details {
  width: 8.64rem;
  background-color: #fff;
  padding: .48rem .28rem;
  border-radius: .08rem;

  .title {
    font-size: .18rem;
    font-weight: 700;
    margin-bottom: .3rem;
    color: @big_Color;
  }

  .component_intro {
    font-size: .15rem;
    margin-bottom: .12rem;
    color: #5C5C5C;

  }

  .component_content {
    color: #5C5C5C;
    font-size: .15rem;
  }

  .text-wrap {
    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 2;
    font-size: .16rem;
    color: #707070;

    p {
      line-height: .32rem;
    }
  }

  .all_btn {
    height: .1rem;
    color: #fff;
    text-align: right;

    span {
      line-height: .1rem;
      color: #44BCBC;
      font-size: .12rem;

      .iconfont {
        font-size: .1rem;
      }
    }
  }

  .business_information {
    margin-top: .32rem;
    margin-bottom: .22rem;

    .business_information_title {
      font-size: .18rem;
      font-weight: 700;
      margin-bottom: .15rem;
      color: @big_Color;
    }


    .business_content {
      background-color: #F8F8F8;
      padding: .2rem;
      border-radius: .08rem;

      .table {
        width: 100%;
        background-color: #fff;
        border-collapse: collapse;
        border-radius: .08rem;

        tr {
          height: .6rem;

          td {
            padding-left: .1rem;
          }
        }
      }

      table,
      th,
      td {
        border: .01rem solid #E4EEF6;
      }

    }

  }


  .work_address {
    display: flex;
    flex-direction: column;

    .work_map {
      width: 8.2rem;
      border: .01rem solid #F2F2F2;
      border-radius: .1rem;

      .map_address {
        height: .32rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .address {
          margin-left: .05rem;
          color: #707070;

          .iconfont {
            color: #2CC9C9;
            margin: 0 .08rem;
          }
        }
      }

      #map {
        height: 2.5rem;
        position: relative;
      }

      .noAddr {
        position: absolute;
        top: 45%;
        left: 40%;
        font-size: 0.2rem;
      }
    }
  }

}
</style>