<template>
  <div></div>
</template>

<script setup>
import { useMessage } from 'naive-ui'
window.$message = useMessage();
</script>

<style scoped>

</style>