<template>
  <div class="categorytitle">
    <div class="text">
      <div class="title">
        <span>{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue';
const title = ref('政府机关');

const props = defineProps({
  title: String
})
title.value = toRefs(props).title.value

</script>

<style lang="less" scoped>
.categorytitle {
  margin-bottom: 1.40625vw;


  .text {

    .title {
      font-size: .24rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: @big_Color;
    }

  }

}
</style>