<template>
  <n-message-provider>
    <MessageApi></MessageApi>
  </n-message-provider>
  <router-view></router-view>

  <aside>
    <div class="btn_tools">
      <!--    :style="!isAppear?'display: none': ''"-->
      <div class="scrollToTop" :class="isCircle ? 'circle' : ''" @click="scrollToTop" v-if="isAppear">
        <i class="iconfont icon-huidaodingbu"></i>
      </div>
      <div class="feedback">
        <a class="feedback_content" @click="showPopUp">
          <i class="iconfont icon-wentifankui"></i>
          <p class="feedback_name">反馈</p>
        </a>

      </div>
      <div class="others">
        <div class="toSearch">
          <div class="icon">

            <i class="iconfont icon-sousuo"></i>
          </div>
          <div class="hover_change_search" @click="toSearch">
            <a>搜索</a>
          </div>
        </div>
        <!--        <div class="service">-->
        <!--          <div class="icon">-->

        <!--            <i class="iconfont icon-kefu"></i>-->
        <!--          </div>-->
        <!--          <div class="hover_change_service" @click="showService">-->
        <!--            <a>客服</a>-->
        <!--          </div>-->

        <!--        </div>-->
        <!--      <div class="score">-->
        <!--        <div class="icon">-->

        <!--          <i class="iconfont icon-aixin"></i>-->
        <!--        </div>-->
        <!--        <div class="hover_change_score">-->
        <!--          <a>评分</a>-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="miniProgram">
          <div class="icon">
            <i class="iconfont icon-xiaochengxu"></i>
          </div>
          <a class="miniProgram_text" @click="popMiniProgram">小程序</a>
          <!--        <div class="miniProgram_card">-->
          <!--          <div class="job">-->
          <!--            <a>找工作</a>-->
          <!--            <div class="job_code">-->
          <!--              <img src="@/assets/image/layout/nav_code.jpg" alt="" />-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="person">-->
          <!--            <a>找人才</a>-->
          <!--            <div class="person_code">-->
          <!--&lt;!&ndash;              <img src="@/assets/image/layout/nav_code.jpg" alt="" />&ndash;&gt;-->
          <!--              <n-result-->
          <!--                  status="info"-->
          <!--                  title="信息"-->
          <!--                  size="small"-->
          <!--                  description="正在加急开发中···"-->
          <!--              >-->
          <!--              </n-result>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
      </div>
    </div>
  </aside>

  <!-- 客服小人 -->
  <div class="serve_animate">
    <img @click="showService" @mouseenter="serveBtn" src="@/assets/image/layout/serve_animate.gif" alt="客服">
    <div v-if="showTips" class="serve_tip">
      <h5 style="margin-top: 0.06rem;margin-bottom: 0.06rem">Hi, 我是客服小可爱~</h5>
      <p style="font-size: 0.12rem">有问题点我咨询哦</p>
      <img class="hover_serve" src="@/assets/image/layout/serve_hover.png" alt="">
      <i @click="closeTips" class="iconfont icon-quxiao"></i>
    </div>
  </div>

  <!--遮罩层-->
  <div class="modal" v-if="$store.state.showOverlay">
    <div class="overlay" @click="disappear"></div>
    <n-spin v-if="$store.state.showLoading">
      <template #description>
        数据加载中···
      </template>
    </n-spin>
    <!-- 反馈弹窗-->
    <div class="feedback_pop_up" v-show="feedback">
      <div class="feedback_main">
        <div class="main_top">
          <div class="title">反馈内容</div>
          <a class="top_btn" @click="disappear">关闭</a>
        </div>
        <div class="main_content">
          <div class="content_text">
            <div class="text_title"><span class="necessary">*</span>反馈内容：</div>
            <textarea v-model="textContent" class="textarea" placeholder="请输入您要反馈的内容，必填"></textarea>
          </div>
          <div class="feedback_image">
            <div class="problem_img">问题图片：</div>
            <div class="upload_image">
              <n-upload action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f" :default-file-list="previewFileList"
                list-type="image-card" @preview="handlePreview" title="问题图片" :max="maxImage" />
              <n-modal v-model:show="showModal" preset="card" style="width: 31.25vw">
                <img :src="previewImageUrl" style="width: 100%" alt="" />
              </n-modal>
              <div class="img_desc">支持jpg，png，jpeg文件上传，最多支持上传5张</div>
            </div>
          </div>
        </div>
        <div class="main_bottom">
          <span class="cancel" @click="disappear"><span>取消</span></span>
          <span class="confirm" @click="submitFeedback"><a>确定</a></span>
          <!--            <n-button @click="show = !show">-->
          <!--              点击来转圈-->
          <!--            </n-button>-->
        </div>

      </div>
    </div>
    <!-- 客服弹窗 -->
    <div class="service_pop_up" v-show="showServiceState">
      <div class="service_code">
        <img src="@/assets/image/layout/service_code.jpg" alt="集团客服" title="友脉服务号" />
        <a>微信扫码添加客服</a>
      </div>
      <!--      <div class="service_main">-->
      <!--        <div class="service_top">-->
      <!--          <h3>客服小甜心</h3>-->
      <!--          <a class="close" @click="disappear">关闭</a>-->
      <!--        </div>-->
      <!--        <div class="service_content">-->
      <!--          <ul class="talk_list" style="top: 0;list-style: none" id="talk_list">-->
      <!--            <li v-for="(item, index) in chatData" :key="index" :class="item.class">-->
      <!--              <img :src="'https://wy-1305371101.cos.ap-chongqing.myqcloud.com/youmai/avatar' + item.avatar"-->
      <!--                alt="" /><span>{{ item.text }}</span>-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--        </div>-->
      <!--        <div class="service_bottom">-->
      <!--          <textarea class="textarea" v-model="sendMessage"></textarea>-->
      <!--          <div class="service_btn">-->
      <!--            <span class="send" @click="sendMsg">发送</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="success_submit">
      <n-spin v-if="show">
        <template #description>
          提交中
        </template>
      </n-spin>
    </div>
    <div class="miniPopUp" v-show="showMini">
      <div class="mini_code">
        <span class="consumer">友脉JOB小程序</span>
        <span class="company">友脉JOB企业端小程序</span>
        <img src="@/assets/image/layout/nav_code.jpg" alt="" />
        <img src="@/assets/image/layout/youmaijobqiye.jpg" alt="" />
      </div>
    </div>
  </div>

  <!-- 弹幕 -->
  <!--  <div class="danmubgc" v-if="showBgc"></div>-->
  <!--  <div v-show="showDanmaku" @mouseenter="stopDanmaku" @mouseleave="startDanmaku" class="danmaku" ref="danmaku"-->
  <!--    :style="{ left: rightDistance + 'px' }">-->
  <!--    <img class="danmaku_img" src="@/assets/image/recruit/danmaku.gif" alt="">-->
  <!--    <a><span style="color: #FEA55C">恭喜</span>{{ lastName }}xx入职{{ companyName }}xxxx岗位</a>-->
  <!--  </div>-->
  <div v-if="showDanmuContainer" class="danmu-container" @mouseenter="pauseDanmus" @mouseleave="resumeDanmus">
    <div v-for="danmu in danmus" :key="danmu.id" class="danmu"
      :style="{ animationPlayState: danmu.isPaused ? 'paused' : 'running' }">
      <a style="color: #3b3b3b">{{ danmu.content }}</a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeUnmount, onUpdated, watchEffect } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { NMessageProvider } from "naive-ui";
import MessageApi from "@/components/MessageApi"
import { setInterval, clearInterval } from "timers";
const store = useStore()

const show = ref(false)

const router = useRouter();

const showMini = ref(false)
const maxImage = ref(5)

/* 弹幕效果 */
const danmus = ref([
]);

// const state = reactive({
//   showDanmuContainer: true,
// });
let showDanmuContainer = ref(true)

/* 生成随机数 */
const getRandomInteger = (num) => {
  return Math.floor(Math.random() * num)
}

let timer;
const startTimer = () => {
  const randomNum1 = getRandomInteger(lastNameList.value.length)
  const randomNum2 = getRandomInteger(companyList.value.length)
  lastName.value = lastNameList.value[randomNum1]
  companyName.value = companyList.value[randomNum2]
  timer = setInterval(() => {
    setTimeout(() => {
      danmus.value = []
    }, 10000)
    danmus.value.push({ id: Date.now(), content: "恭喜" + lastName.value + "xx入职" + companyName.value + "xxx职位", isPaused: false });
  }, 30000);
};

// const pauseDanmus = () => {
//   danmus.value.forEach((danmu) => {
//     danmu.isPaused = true;
//   });
// };
//
// const resumeDanmus = () => {
//   danmus.value.forEach((danmu) => {
//     danmu.isPaused = false;
//   });
// };

onMounted(() => {
  startTimer();
});

onBeforeUnmount(() => {
  clearInterval(timer);
});

watchEffect(() => {
  if (danmus.value.length === 0) {
    showDanmuContainer.value = false;
  } else {
    showDanmuContainer.value = true;
  }
});
/* 弹幕结束 */

/* 展示小程序 */
const popMiniProgram = () => {
  showMini.value = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
}

const handleResize = () => {
  if (window.innerWidth < 768) {
    // 开发环境
    // 如果浏览器宽度小于 768 就跳转到移动网站
    window.location.href = "https://mobile.youmaijob.com/"
  }
}


let feedback = ref(false)


const showPopUp = () => {
  feedback.value = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
}


const disappear = () => {
  feedback.value = false
  showServiceState.value = false
  store.state.showOverlay = false
  showMini.value = false
  document.body.style.overflow = ""
}

const showModal = ref(false);
const previewImageUrl = ref("");
const previewFileList = ref([])

function handlePreview(file) {
  const { url } = file;
  previewImageUrl.value = url;
  showModal.value = true;
}

const textContent = ref('')

const submitFeedback = () => {
  if (textContent.value === '') {
    alert('请您输入要提交的内容')
  } else {
    show.value = true
    const timer = setInterval(() => {
      show.value = false
      disappear()
      window.$message.success("提交成功！！！");
      clearInterval(timer)
    }, 2000)


  }
}

let showServiceState = ref(false)

const showService = () => {
  showServiceState.value = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
}


const scrollToTop = () => {
  //  设置一个定时器
  const upRoll = setInterval(() => {
    const top = document.documentElement.scrollTop
    const speed = Math.ceil(top / 10)
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop -= speed
    } else {
      clearInterval(upRoll)
    }
  }, 10)
}
const isCircle = ref(false)

const isAppear = ref(false)
const isFixed = ref(false)

const toSearch = () => {
  const upRoll = setInterval(() => {
    const top = document.documentElement.scrollTop
    const speed = Math.ceil(top / 10)
    if (document.documentElement.scrollTop > 60) {
      document.documentElement.scrollTop -= speed
    } else {
      clearInterval(upRoll)
    }
  }, 10)
}
const handleScroll = () => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  isAppear.value = scrollTop > 100 ? true : false
  isFixed.value = scrollTop > 1300 ? true : false
}

/* 监听页面是否是前台运行 */
const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
  } else {
  }
}

onMounted(() => {
  window.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize)
  handleResize()
})

onUpdated(() => {
  window.addEventListener('resize', handleResize)
  handleResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('visibilitychange', handleVisibilityChange);
  window.removeEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('visibilitychange', handleVisibilityChange)
  window.removeEventListener('scroll', handleScroll);
})

const showTips = ref(false)

// 刷新重定向到home页面
onMounted(() => {
  if (router.path !== "/home") {
    router.push("/home")
  }

  setInterval(() => {
    showTips.value = true;
    // clearInterval(timer1)
  }, 10000)
  setInterval(() => {
    showTips.value = false;
    // clearInterval(timer1)
  }, 300000)
})

// 小人物动画
const serveBtn = () => {
  showTips.value = true;
}
// 关闭提示
const closeTips = () => {
  showTips.value = false
}

const lastNameList = ref([
  '王', '李', '张', '刘', '陈', '杨', '黄', '赵', '吴', '周',
  '徐', '孙', '胡', '朱', '高', '林', '何', '郭', '马', '罗',
  '梁', '宋', '郑', '谢', '韩', '唐', '冯', '于', '董', '萧',
  '程', '曹', '袁', '邓', '许', '傅', '沈', '曾', '彭', '吕',
  '苏', '卢', '蒋', '蔡', '贾', '丁', '魏', '薛', '叶', '阎',
  '余', '潘', '杜', '戴', '夏', '锺', '汪', '田', '任', '姜',
  '范', '方', '石', '姚', '谭', '廖', '邹', '熊', '金', '陆',
  '郝', '孔', '白', '崔', '康', '毛', '邱', '秦', '江', '史',
  '顾', '侯', '邵', '孟', '龙', '万', '段', '钟', '汤', '尹',
  '黎', '易', '常', '武', '乔', '贺', '赖', '龚', '文', '关',
  '盛', '林', '符', '陆', '肖', '季', '仇', '欧', '甘', '尤',
  '廉', '莫', '景', '房'
])

const companyList = ref([
  '友脉控股集团有限公司', '云南友脉劳务服务有限公司', '某大型通讯公司', '友脉集团呼叫中心事业部', '某大型保险公司', '湖南航空股份有限公司', '某大型通讯公司文山分公司', '某大型通讯公司楚雄分公司', '某大型通讯公司版纳分公司', '某大型通讯公司云南分公司', '百江燃气', '通用水务局', '云南梁氏高原蓝莓产业发展有限公司', '云南省医药有限公司', '昆明国际花卉拍卖交易中心', '云南鸿园电力物业服务有限公司', '重庆友脉科技有限公司'
])

let companyName = ref("友脉控股集团有限公司")

let lastName = ref("王")


</script>

<style lang="less">
.danmu-container {
  //position: relative;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: rgba(204, 204, 204, .6);
  overflow: hidden;
  z-index: 999;
}

.danmu {
  position: absolute;
  top: 35px;
  white-space: nowrap;
  font-size: 16px;
  /* 可根据需要设置弹幕的样式 */

  /* 添加弹幕动画效果 */
  animation: danmuAnimation 10s linear;
}

@keyframes danmuAnimation {
  0% {
    left: 100%;
  }

  100% {
    left: -2%;
  }
}

.modal {
  position: relative;

  .success_submit {
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 9999;
  }

  .feedback_pop_up {
    position: absolute;
    top: 0;
    right: 0;
    width: 8rem;
    height: 5.5rem;
    z-index: 999;

    .mask_layer {
      width: 100%;
      height: 100%;
      background-color: rgba(100, 92, 92, 0.5);
    }

    .feedback_main {
      position: absolute;
      top: 2rem;
      right: 5.6rem;
      width: 100%;
      height: 100%;
      background-color: #fff;
      margin: 0 auto;
      z-index: 10;
      padding: .2rem;

      .main_top {
        display: flex;
        justify-content: space-between;

        .title {
          font-size: .24rem;
          font-weight: 600;
        }

        .top_btn {
          color: @small_Color;

          &:hover {
            color: @youmai_Color;
          }
        }
      }

      .main_content {
        margin-top: .2rem;

        .content_text {
          display: flex;
          justify-content: space-between;

          .text_title {
            padding-top: .03rem;
            vertical-align: top;
            font-size: .18rem;

            .necessary {
              color: red;
            }
          }

          .textarea {
            width: 6.5664rem;
            height: 1.58rem;

            &::-webkit-input-placeholder {
              font-size: .18rem;
            }

            resize: none;
            padding: .05rem;
            box-sizing: border-box;
            border: .02rem solid @youmai_Color;
            border-radius: .08rem;
            outline: none;
          }
        }

        .feedback_image {
          height: 2.07rem;
          margin-top: .2rem;
          display: flex;
          justify-content: space-between;

          .problem_img {
            font-size: .18rem;
            vertical-align: top;
          }

          .upload_image {
            padding: .1rem;
            width: 6.58rem;
            height: 1.88rem;
            border: .02rem solid @youmai_Color;
            border-radius: .08rem;
            z-index: 10;

            .img_desc {
              margin-top: .15rem;
            }
          }
        }
      }

      .main_bottom {
        margin-top: .3rem;
        text-align: right;

        .cancel {
          font-size: .18rem;
          padding: .05rem .2rem;
          border: .01rem solid @youmai_Color;
          border-radius: .08rem;
          color: @small_Color;
        }

        .confirm {
          margin-left: .15rem;
          font-size: .18rem;
          padding: .05rem .2rem;
          border-radius: .08rem;
          background-color: @youmai_Color;
          color: #fff;
        }
      }

    }
  }

  .service_pop_up {
    position: absolute;
    top: 2.52rem;
    left: 6.72rem;
    width: 4.992rem;
    height: 4.992rem;
    z-index: 999;

    .service_code {
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      //padding: 5px;
      img {
        width: 80%;
        height: 80%;
      }

      a {
        font-size: 0.18rem;
      }
    }

  }

  .miniPopUp {
    position: absolute;
    top: 2.3rem;
    left: 4.608rem;
    width: 4.992rem;
    height: 5.5rem;
    z-index: 999;

    .mini_code {
      display: flex;
      box-sizing: border-box;

      .consumer {
        position: absolute;
        top: 4px;
        left: 35%;
        font-size: large;
        font-weight: bold;
      }

      .company {
        position: absolute;
        top: 4px;
        right: -332px;
        font-size: large;
        font-weight: bold;
      }
    }
  }
}

.btn_tools {
  width: .5rem;
  height: 4rem;
  position: fixed;
  bottom: .576rem;
  left: 17.664rem;
  display: flex;
  flex-direction: column;

  .scrollToTop {
    width: .5rem;
    height: .5rem;
    background-color: #fff;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      height: .5rem;
      width: .5rem;
      background-color: @youmai_Color;
      border-radius: .3rem;
    }

    .iconfont {
      font-size: .18rem;
      font-weight: 700;
    }
  }

  .feedback {
    margin-top: .1rem;
    width: .5rem;
    height: .7rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;

    .feedback_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        height: .5rem;
        width: .5rem;
        background-color: @youmai_Color;
        border-radius: .3rem;
        color: #fff
      }

      .feedback_name {
        font-size: .12rem;
      }
    }


  }

  .others {
    margin-top: .1rem;
    width: .5rem;
    //height: 9.021vw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .25rem;

    .toSearch {
      width: .5rem;
      height: .5rem;
      position: relative;

      .icon {
        width: .5rem;
        height: .5rem;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        .iconfont {
          font-size: .18rem;
        }
      }

      .hover_change_search {
        width: .5rem;
        height: 0;
        border-radius: .5rem;
        background-color: @youmai_Color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;

        opacity: 0;

        a {
          color: #fff;
        }
      }

      &:hover {
        >.icon {
          height: 0;
          opacity: 0;
        }

        >.hover_change_search {
          height: .5rem;
          font-size: .14rem;
          opacity: 1;
        }
      }
    }

    .service {
      width: .5rem;
      height: .5rem;
      position: relative;

      .icon {
        width: .5rem;
        height: .5rem;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        .iconfont {
          font-size: .18rem;
        }
      }

      .hover_change_service {
        width: .5rem;
        height: 0;
        border-radius: .5rem;
        background-color: @youmai_Color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        opacity: 0;

        a {

          color: #fff;
        }
      }

      &:hover {
        >.icon {
          height: 0;
          opacity: 0;
        }

        >.hover_change_service {
          height: .5rem;
          font-size: .14rem;
          opacity: 1;
        }
      }

    }

    .score {
      width: .5rem;
      height: .5rem;
      position: relative;

      .icon {
        width: .5rem;
        height: .5rem;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        .iconfont {
          font-size: .18rem;
        }
      }

      .hover_change_score {
        width: .5rem;
        height: 0;
        border-radius: .5rem;
        background-color: @youmai_Color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        color: #fff;
        opacity: 0;
      }

      &:hover {
        >.icon {
          height: 0;
          opacity: 0;
        }

        >.hover_change_score {
          height: .5rem;
          font-size: .14rem;
          opacity: 1;
        }
      }
    }

    .miniProgram {
      width: .5rem;
      height: .5rem;
      position: relative;

      .icon {
        width: .5rem;
        height: .5rem;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        .iconfont {
          font-size: .18rem;
        }
      }

      .miniProgram_text {
        width: .5rem;
        height: 0;
        border-radius: .5rem;
        background-color: @youmai_Color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        color: #fff;
        opacity: 0;
      }


      &:hover {
        >.icon {
          height: 0;
          opacity: 0;
        }

        >.miniProgram_text {
          height: .5rem;
          font-size: .14rem;
          opacity: 1;
        }

      }
    }

  }
}

.serve_animate {
  position: fixed;
  bottom: 1rem;
  left: 17.664rem;

  .serve_tip {
    padding: 0 .06rem .06rem .06rem;
    background-color: #c9c8c8;
    width: 1.42rem;
    position: absolute;
    top: .08rem;
    right: .8rem;
    border-radius: .08rem;

    .hover_serve {
      //background-color: #ccc;
      position: absolute;
      top: .16rem;
      left: 1.36rem;
    }

    .icon-quxiao {
      position: absolute;
      top: -0.16rem;
      right: .04rem;
      font-size: .08rem;
    }
  }
}

.barrage {
  width: 100%;
  height: 100px;
  background-color: rgba(204, 204, 204, .5);
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
