<template>
  <nav class="responsibility_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">
      <div class="social_nav">
        <h1>社会责任</h1>
        <div class="right_nav">
          <span>SOCIAL RESPONSIBILITY</span>
          <div class="right_line"></div>
        </div>

      </div>
      <section>
        <div class="social_main">
          <img src="@/assets/image/statement/responsibilyty.png" alt="社会责任">
          <!--    <div class="social_card">-->
          <!--      <div class="card_res">-->
          <!--        <div class="image">-->
          <!--          <i class="iconfont icon-&#45;&#45;gongyixingjiuji"></i>-->
          <!--        </div>-->
          <!--        <p style="margin: 0.421vw 0;font-weight: 600;">公益慈善</p>-->
          <!--        <p style="font-size: 0.737vw">集团热心参与各类慈善捐助等多项目公益活动，近年来，累计向灾区、贫困山区捐款捐物</p>-->
          <!--      </div>-->
          <!--      <div class="card_res">-->
          <!--        <div class="image">-->
          <!--          <i class="iconfont icon-jiaoyu"></i>-->
          <!--        </div>-->
          <!--        <p style="margin: 0.421vw 0;font-weight: 600;">教育就业</p>-->
          <!--        <p style="font-size: 0.737vw">集团高度重视精准扶贫工作，探索“教育+就业”扶贫新模式，实现造血式扶贫</p>-->
          <!--      </div>-->
          <!--      <div class="card_res">-->
          <!--        <div class="image">-->
          <!--          <i class="iconfont icon-ziyuan8"></i>-->
          <!--        </div>-->
          <!--        <p style="margin: 0.421vw 0;font-weight: 600;">消费扶贫</p>-->
          <!--        <p style="font-size: 0.737vw">将电子商务融入到扶贫工作体系，实现由“授人以鱼”到“授人以渔”转变</p>-->
          <!--      </div>-->
          <!--      <div class="card_res">-->
          <!--        <div class="image">-->
          <!--          <i class="iconfont icon-zhiyuanzhehuodong"></i>-->
          <!--        </div>-->
          <!--        <p style="margin: 0.421vw 0;font-weight: 600;">志愿者服务</p>-->
          <!--        <p style="font-size: 0.737vw">服务企业、奉献社会。友脉党建、工会等积极组织开展了形式多样的志愿服务活动</p>-->
          <!--      </div>-->
          <!--    </div>-->
        </div>
      </section>
    </div>
  </div>


  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { onMounted } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";

onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style lang="less" scoped>
.responsibility_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  display: flex;
  margin-top: .3rem;
  background-color: #fff;
  border-radius: .08rem;

  .content {
    padding: .1rem .5rem .1rem .1rem;

    .social_nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right_nav {
        display: flex;
        align-items: center;
        color: #3496FB;
        font-weight: 600;


        .right_line {
          margin-left: .1rem;
          height: .05rem;
          width: .3rem;
          background-color: #3496FB;
        }

      }
    }

    .social_main {
      margin-top: .2rem;
      margin-bottom: .2rem;

      .social_card {
        display: flex;
        justify-content: space-between;

        margin-top: .4rem;

        .card_res {
          width: 2rem;
          height: 2rem;
          //background-color: @youmai_Color;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: .08rem;

          .image {
            width: .6rem;
            height: .6rem;

            .iconfont {
              font-size: .6rem;
            }
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }
}</style>