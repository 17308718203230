<template>
  <nav class="advantage_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">
      <div class="advantage">
        <h1>友脉优势</h1>
        <div class="right_nav">
          <span>YOUMAI ADVANTAGE</span>
          <div class="right_line"></div>
        </div>
      </div>
      <section>
        <div class="advantage_main">
          <div class="advantage_scale">
            <h3>企业发展规模</h3>
            <p>集团实行西南昆明+华南广州双总部协同发展战略，立足西南、华南，面向全国，业务覆盖云南全省16个州市及广东、贵州、重庆、四川、湖南、海南、新疆、北京等省市区域
              友脉控股集团是云南省省级文明单位，是云南省服务外包重点企业，是中国人力资源服务企业中规模较大、经济实力强、服务规范和专业人力资源全产业链服务提供商
              集团目前拥有43余家控股/参股企业或分子公司、1所职业培训学校、1个省级众创空间、2个服务外包产业园</p>
            <div class="advantage_card">
              <div class="card1">
                <div class="card_top">
                  <span style="font-size: .4rem;">600 </span><span>人+</span>
                </div>
                <div class="card_bottom">
                  <span>自有员工数</span>
                </div>
              </div>
              <div class="card1">
                <div class="card_top">
                  <span style="font-size: .4rem">25000 </span><span>人+</span>
                </div>
                <div class="card_bottom">
                  <span>外包/劳务派遣员工数</span>
                </div>
              </div>
              <div class="card1">
                <div class="card_top">
                  <span style="font-size: .4rem">120000 </span><span>人+</span>
                </div>
                <div class="card_bottom">
                  <span>带动社会就业人员约</span>
                </div>
              </div>
              <div class="card1">
                <div class="card_top">
                  <span style="font-size: .4rem">5000万 </span><span>元+</span>
                </div>
                <div class="card_bottom">
                  <span>年税收贡献</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import { onMounted } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";

onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style lang="less" scoped>
.advantage_nav {
  width: 100%;
  background-color: #fff;

}

.main {
  display: flex;
  background-color: #fff;
  margin-top: .3rem;
  border-radius: .08rem;

  .content {
    .advantage {
      padding: .1rem .5rem 0.1rem .1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-right-radius: .08rem;

      .right_nav {
        display: flex;
        align-items: center;
        color: #3496FB;
        font-weight: 600;


        .right_line {
          margin-left: .1rem;
          height: .05rem;
          width: .3rem;
          background-color: #3496FB;
        }

      }
    }

    .advantage_main {
      padding: 0.521vw 2.604vw 0.521vw 0.521vw;

      .advantage_scale {
        width: 100%;
        padding: .1rem;
        border: .01rem solid @small_Color;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          margin-top: .15rem;
          line-height: .32rem;
          display: flex;
          text-align: center;
        }

        .advantage_card {

          display: flex;
          justify-content: space-between;

          .card1 {
            //flex: 1;
            width: 2rem;
            height: 2rem;
            margin: 0 .15rem;
            //background-color: @youmai_Color;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .card_top {
              font-size: .24rem;
              color: @big_Color;
            }
          }
        }
      }
    }
  }
}</style>