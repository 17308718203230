<template>
  <nav class="fraud_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">
      <div class="counterFraud">
        <nav class="counterFraud_nav">
          <h1>防骗指南</h1>
          <div class="right_nav">
            <span>DEVELOPING COURSE</span>
            <div class="right_line"></div>
          </div>
        </nav>
        <div class="counterFraud_main" v-html="fraudText"></div>
        <!--        <article>-->
        <!--          <div class="counterFraud_main">-->
        <!--            <p>以下是一些求职防骗指南：</p>-->

        <!--            <ol>-->
        <!--              <li><span style="font-weight: 600;">确认招聘信息的真实性：</span>在网上搜索公司的信息，查看公司的网站、社交媒体和新闻报道，了解公司的背景、规模和招聘计划。</li>-->

        <!--              <li><span-->
        <!--                  style="font-weight: 600;">不要相信太好的承诺：</span>如果一个工作看起来过于理想，工作时间短、薪资高、福利优渥，应该对这些承诺持谨慎态度。虚假承诺通常是骗子用来诱骗求职者的一个手段。-->
        <!--              </li>-->

        <!--              <li><span style="font-weight: 600;">不要轻易透露个人信息：</span>不要将个人信息（如身份证号码、银行账号等）透露给任何人，除非你确认对方是可信的雇主或招聘机构。</li>-->

        <!--              <li><span-->
        <!--                  style="font-weight: 600;">谨慎回应不明来历的邮件或短信：</span>如果你收到了不明来历的邮件或短信，询问你是否有兴趣申请某个职位，不要随意点击附件或链接，这可能是骗子的诈骗信息。-->
        <!--              </li>-->

        <!--              <li><span style="font-weight: 600;">谨慎交纳费用：</span>如果一个雇主要求你交纳费用（如报名费、培训费、保证金等），在确认此举合法性之前，不要交纳任何费用。</li>-->

        <!--              <li><span-->
        <!--                  style="font-weight: 600;">注意面试时的问题：</span>合法的招聘公司通常不会问求职者个人隐私，如信用卡号码、家庭住址等问题。如果面试官问这些问题，应该保持警惕，提出拒绝回答的要求。-->
        <!--              </li>-->

        <!--              <li><span style="font-weight: 600;">认真阅读合同：</span>在签署雇佣合同之前，认真阅读合同内容，确保你理解其中的所有条款和条件，不要轻易签署含有漏洞的合同。</li>-->


        <!--            </ol>-->
        <!--            <p>希望以上这些防骗指南能够帮助你在求职过程中避免受骗。</p>-->
        <!--          </div>-->
        <!--        </article>-->
      </div>
    </div>
  </div>

  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
// import {useRouter} from "vue-router";
// const router = useRouter()
// const back = () => {
//   router.go(-1)
// }
import { onMounted, ref } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";
import { getPrivacyProtocal } from "@/request/api"

const fraudText = ref("")
const getFraud = () => {
  getPrivacyProtocal("e33f017c-2de9-4e22-accd-31bf069a9ee8").then(res => {
    if (res.data.code === 200) {
      fraudText.value = res.data.data.content
    }
  })
}

onMounted(() => {
  window.scrollTo(0, 0)
  getFraud()
})
</script>

<style lang="less" scoped>
.fraud_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  display: flex;
  background-color: #fff;
  margin-top: .3rem;
  border-radius: .08rem;

  .content {

    .counterFraud {
      .counterFraud_nav {
        padding: .1rem .5rem 0.1rem .1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right_nav {
          display: flex;
          align-items: center;
          color: #3496FB;
          font-weight: 600;


          .right_line {
            margin-left: .1rem;
            height: .05rem;
            width: .3rem;
            background-color: #3496FB;
          }

        }
      }

      .counterFraud_main {
        padding: .1rem .5rem 0.1rem .1rem;

        p {
          line-height: .32rem;
        }

        ol {
          li {
            margin: .08rem 0;
            line-height: .32rem;
          }
        }
      }
    }
  }
}
</style>