<template>
  <!-- 职位简述 -->
  <div class="position_sketch shadow">
    <div class="swiper">
      <n-carousel :space-between="20" draggable :show-dots="false">
        <img class="carousel-img" src="@/assets/image/position/position_details/detail_bgc1.jpg" alt="职位简述背景图"
          :title="sketch.position_name" />
      </n-carousel>
    </div>

    <div class="sketch container">

      <div class="sketch_left">
        <div class="left_top">
          <span class="position_name">{{ sketch.position_name }}</span>

        </div>
        <div class="left_center">
          <span class="position_salary">{{ sketch.position_min_salary }}-{{ sketch.position_max_salary }}K</span>
        </div>
        <div class="left_bottom">
          <span class="position_address text_shadow"><i class="iconfont icon-dizhi"></i>{{ sketch.position_address
          }}</span>
          <span class="position_experience text_shadow"><i class="iconfont icon-jingyan"></i>{{ sketch.position_experience
          }}</span>
          <span class="position_age text_shadow"><i class="iconfont icon-qiye"></i>{{
            sketch.position_minAge
          }}-{{ sketch.position_maxAge }}岁</span>
        </div>

      </div>
      <div class="sketch_right">
        <!--        <div class="right_top">-->
        <!--          <div class="welfare" v-for="(welfare, index) in sketch1.position_welfare" :key="index">{{ welfare }}</div>-->
        <!--        </div>-->
        <div class="right_top" style="display: none">
          <div class="share">
            <i class="iconfont icon-weixin"></i><span>小程序快速投递</span>
            <!--            <div class="share_code">-->
            <!--              <img src="@/assets/image/layout/nav_code.jpg" alt="" />-->
            <!--&lt;!&ndash;              <span>扫码进入小程序</span>&ndash;&gt;-->
            <!--            </div>-->
          </div>
          <div class="report"><i class="iconfont icon-jubao"></i><span>举报</span></div>
        </div>
        <div class="right_bottom">
          <div class="bottom_btn">
            <!--            <div class="collect_btn">-->
            <!--              <span class="btn_content"><i class="iconfont icon-aixin"></i><span>收藏职位</span></span>-->
            <!--            </div>-->
            <div class="exchange_btn" @click="addFavorite">
              <a class="btn_content"><i class="iconfont icon-jiaoliu"></i><span>收藏职位</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from "vue";

// const sketch1 = ref({
//   position_name: '云服务工程师',
//   position_min_salary: '5',
//   position_max_salary: '8',
//   position_address: '昆明',
//   position_experience: '不限',
//   position_minAge: '20',
//   position_maxAge: '35',
//   position_welfare: ['五险一金', '双休', '定期体检', '午餐补贴'],
//   position_linkman: '李经理'
// })

const sketch = ref({})
const props = defineProps({
  positionSketch: Object
})
sketch.value = toRefs(props).positionSketch.value
const addFavorite = () => {
  let {url} = window.location;
  let title = document.title;
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("360se") > -1) {
    alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
  }
  else if (ua.indexOf("msie 8") > -1) {
    window.external.AddToFavoritesBar(url, title); //IE8
  }
  else if (document.all) {//IE类浏览器
    try {
      window.external.addFavorite(url, title);
    } catch (e) {
      alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
    }
  }
  else if (window.sidebar) {//firfox等浏览器；
    window.sidebar.addPanel(title, url, "");
  }
  else {
    alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
  }

}
</script>

<style lang="less" scoped>
// 职位简述
.position_sketch {
  width: 100%;
  position: relative;

  .swiper {
    width: 100%;
    height: 1.92rem;

    img {
      width: 100%;
    }
  }


  .sketch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding-top: .25rem;
    display: flex;
    justify-content: space-between;

    .sketch_left {
      display: flex;
      flex-direction: column;

      .left_top {
        float: right;

        .position_name {
          font-size: .28rem;
          font-weight: 600;
          color: #fff;
          margin-right: .3rem;
        }

      }

      .left_center {
        margin-top: .2rem;

        .position_salary {
          font-size: .28rem;
          font-weight: 600;
          color: #F1B572;
        }
      }

      .left_bottom {
        margin-top: .2rem;
        color: #fff;
        font-size: .2rem;

        .position_address {
          margin-right: .5rem;

          .iconfont {
            font-size: .24rem;
            margin-right: .17rem;
          }
        }

        .position_experience {
          margin-right: .5rem;

          .iconfont {
            font-size: .24rem;
            margin-right: .17rem;
            font-weight: 700;
          }
        }

        .position_componentInfo {
          .iconfont {
            font-size: .24rem;
            margin-right: .17rem;
            font-weight: 700;
          }
        }

        .position_age {
          .iconfont {
            font-size: .24rem;
            margin-right: .17rem;
            font-weight: 700;
          }
        }
      }

    }

    .sketch_right {
      display: flex;
      flex-direction: column;
      position: relative;

      .right_top {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: .15rem;
        //margin-left: 14.021vw;
        display: flex;

        color: #fff;

        .share {
          //position: relative;

          span {
            margin-left: .08rem;
          }

          //.share_code {
          //  position: absolute;
          //  //top: 0;
          //  left: -1.302vw;
          //  width: 10.417vw;
          //  height: 0;
          //  opacity: 0;
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //  flex-direction: column;
          //  background-color: #fff;
          //  color: @small_Color;
          //
          //  span {
          //    font-size: .875rem;
          //    margin-top: 0.417vw;
          //  }
          //
          //  img {
          //    width: 7.8125vw;
          //    height: 7.8125vw;
          //  }
          //}

          &:hover {
            color: @youmai_Color;

            //>.share_code {
            //  height: 10.9375vw;
            //  opacity: 1;
            //}
          }
        }

        .report {
          margin-left: .29rem;

          span {
            margin-left: .08rem;
          }

          &:hover {
            color: @youmai_Color;
          }
        }


      }

      .right_bottom {
        margin-top: .888rem;

        .bottom_btn {
          display: flex;
          z-index: 1;
          margin-left: 3.05rem;
          //float: right;

          .collect_btn {
            width: 1.536rem;
            height: .528rem;
            border: .02rem solid #F1B572;
            color: #F1B572;
            border-radius: .1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: serif;

            .btn_content {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: .24rem;

              .iconfont {
                font-size: .24rem;
                margin-right: .13rem;
              }
            }
          }

          .exchange_btn {
            margin-left: .3264rem;
            width: 1.5rem;
            height: .51rem;
            background-color: #F1B572;
            border-radius: .1rem;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: serif;

            a {
              color: #ffffff;
            }

            .btn_content {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: .2rem;

              .iconfont {
                font-size: .24rem;
                margin-right: .096rem;
              }
            }
          }
        }
      }
    }
  }
}

.text_shadow {
  background-color: rgba(0, 0, 0, .4);
  padding: .05rem .1rem;
  border-radius: .08rem;
}</style>