<template>
  <div class="layoutNav container">
    <div class="left_img">
      <img src="@/assets/image/index/index2.png" alt="">
    </div>
    <div class="nav_desc">
      <span>招聘</span> · <span>求职</span> · <span>培训</span>
    </div>
    <div class="right_back_home" @click="toHome">
      <div class="icon">
        <i class="iconfont icon-shouyetianchong"></i>
      </div>
      <span>首页</span>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0)
})
const store = useStore();
const router = useRouter()
const toHome = () => {
  router.push("/home")
  store.state.pathActive = '/home'
}
</script>

<style lang="less" scoped>
.layoutNav {

  background-color: #fff;
  width: 12.4rem;
  //height: 3.125vw;
  display: flex;
  align-items: center;

  .left_img {
    width: 3.2rem;
  }

  .nav_desc {
    margin-left: .2rem;
    color: @youmai_Color;
    font-size: .2rem;
    font-weight: 600;
  }

  .right_back_home {
    //float: right;
    margin-left: 6.2rem;
    color: @youmai_Color;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      background-color: #EBF2F8;
      width: .4rem;
      height: .4rem;
      border-radius: .4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .iconfont {
        background-color: #E0ECFF;
        width: .3rem;
        height: .3rem;
        border-radius: .3rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    span {
      color: @big_Color;
      font-size: .18rem;
      margin-left: .05rem;

      &:hover {
        color: @youmai_Color;
      }
    }
  }
}
</style>