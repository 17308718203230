import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 重置样式
import "normalize.css";
import "@/assets/styles/common.less";
import naive from "naive-ui"
import { createHead } from '@vueuse/head'
const app = createApp(App);

const head = createHead()
app.use(head)

app.use(store).use(router).use(naive)
app.mount("#app");
