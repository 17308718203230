<template>
  <!--热门岗位-->
  <div class="hot_jobs container">
    <CampusMessage bigTitle="POPULAR" smallTitle="POSTS" chTitle="热门岗位"></CampusMessage>
    <div class="hot_jobs_right">
      <div class="hot_jobs_card shadow">
        <div class="hot_title">
          <span class="hot_title_word"><i class="iconfont icon-fenlei"></i>好公司</span>
        </div>
        <div class="position_item" v-for="item in workData" :key="item.id" :class="'line'+item.id">
          <div class="left_desc">
            <div class="work_name">{{ item.name }}</div>
            <div class="work_component">{{ item.component }}</div>
          </div>
          <div class="right_btn">
            <div class="btn">立即申请</div>
          </div>
        </div>
      </div>
      <div class="hot_jobs_card">
        <div class="hot_title">
          <span class="hot_title_word"><i class="iconfont icon-fenlei"></i>好工作</span>
        </div>
        <div class="position_item" v-for="item in workData" :key="item.id" :class="'line'+item.id">
          <div class="left_desc">
            <div class="work_name">{{ item.name }}</div>
            <div class="work_component">{{ item.component }}</div>
          </div>
          <div class="right_btn">
            <div class="btn">立即申请</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import CampusMessage from "@/views/campusRecruitment/components/components/CampusMessage";

const workData = ref([
  {
    id: 1,
    name: '智能与信息设计（计算机，自动化）',
    component: '中机6院'
  },
  {
    id: 2,
    name: '智能与信息设计（计算机，自动化）',
    component: '中机6院'
  },
  {
    id: 3,
    name: '智能与信息设计（计算机，自动化）',
    component: '中机6院'
  },
  {
    id: 4,
    name: '智能与信息设计（计算机，自动化）',
    component: '中机6院'
  },
  {
    id: 5,
    name: '智能与信息设计（计算机，自动化）',
    component: '中机6院'
  },
])
</script>

<style lang="less" scoped>
//热门岗位
.hot_jobs {
  display: flex;
  height: 27.604vw;
  margin-top: 4.6875vw;

  .hot_jobs_right {
    flex: 3;
    height: 100%;
    display: flex;
    justify-content: space-between;


    .hot_jobs_card {
      background-color: #fff;
      width: 22.917vw;
      height: 100%;
      padding: 0 1.5625vw;
      border-radius: 0.417vw;

      .hot_title {
        height: 3.125vw;
        display: flex;
        align-items: center;
        border-bottom: 0.052vw solid #F1F1F1;
        font-size: 1.25rem;
        font-weight: 700;
        vertical-align: center;

        .hot_title_word {
          display: flex;
          align-items: center;

          .iconfont {
            margin-right: 0.885vw;
            font-size: 1.75rem;
            color: #65C5E8;
          }
        }

      }

      .position_item {
        height: 4.896vw;
        display: flex;
        border-bottom: 0.052vw solid #F1F1F1;


        .left_desc {
          display: flex;
          flex-direction: column;

          .work_name {
            margin-top: 0.833vw;
            font-size: 1.0625rem;
            color: #646465;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .work_component {
            margin-top: 0.833vw;
            font-size: 0.78125vw;
            color: #646465;
          }
        }

        .right_btn {
          display: flex;
          align-items: center;
          margin-right: 0.521vw;

          .btn {
            width: 6.458vw;
            height: 1.667vw;
            border: 0.104vw solid #65C5E8;
            border-radius: 0.833vw;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #65C5E8;
          }
        }

      }

      .line5 {
        border-bottom: 0 !important;
      }
    }
  }
}
</style>