<template>
  <nav class="nav_sticky">
    <NavigationView></NavigationView>
  </nav>
  <div class="home_img ">
    <!--      <img src="@/assets/image/index/index14.jpg" alt="">-->
    <img v-for="(img, index) in imgs" :key="index" :src="img.src" :style="{ zIndex: index === currentIndex ? 1 : 0 }"
      :class="{ active: index === currentIndex }" v-bind:transition="transitionName" @click="changeImg"
      @mouseenter="mouseIn" @mouseleave="mouseOut" alt="banner图" title="友脉人才网" />
  </div>
  <div class="recruit container">

    <div class="recruit_title">
      <span>招录考</span>
    </div>
    <!-- 招录考 -->
    <div class="recruit">
      <div class="comCard">
        <div class="comCard_item">
          <n-grid x-gap="12" cols="4">
            <n-gi class="card_content box-shadow" :class="'card' + index" @click="toCompanyDetails(item.projectId)"
              v-for="(item, index) in companyData" :key="item.companyId">
              <div class="card_top">
                <div class="left_img">
                  <!--'src="@/assets/logo/logo1.jpg"'-->
                  <img :src="item.logoUrl" :alt="item.name" :title="item.name" />
                </div>
                <div class="right_desc">
                  <a class="desc_name">
                    {{ item.name }}
                  </a>
                  <span class="tag" style="margin-top: 1.053vw;">
                    <a class="tagTile" :class="'tagTitle' + index" v-for="(i, index) in item.tagTitleList" :key="index">{{
                      i }}</a>
                  </span>
                </div>
              </div>
              <div class="card_bottom">
                <span>项目名称：</span>
                <a>{{ item.projectName }}</a>
              </div>
            </n-gi>
          </n-grid>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
import { getRecruitList } from "@/request/api";
import { onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore()
const router = useRouter()

let companyData = ref([])

const imgs = ref([
  { src: new URL('../../assets/image/recruit/recruit_banner.jpg', import.meta.url).href },
  { src: new URL('../../assets/image/recruit/swiper2.png', import.meta.url).href },
  // { src: new URL('../../assets/image/recruit/swiper3.png', import.meta.url).href },
  { src: new URL('../../assets/image/recruit/swiper4.png', import.meta.url).href },
])

const currentIndex = ref(0)
const transitionName = ref('fade')
// setInterval(nextSlide, 5000);
const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % imgs.value.length;
}

const mouseIn = () => {
  // transitionName.value = 'none'
  clearInterval(timer)
}

const mouseOut = () => {
  // transitionName.value = 'fade'
  timer = setInterval(nextSlide, 8000)
}

let timer = setInterval(nextSlide, 8000)



const changeImg = () => {
  currentIndex.value = (currentIndex.value + 1) % imgs.value.length;
}


onMounted(() => {
  // window.scrollTo(0,0)
  store.state.pathActive = '/admissiontest'
  getCompanyData()
})

const toCompanyDetails = (id) => {
  router.push({
    path: '/companyDetails',
    query: {
      id,
      type: 1
    }
  })
}

// 获取数据
const getCompanyData = () => {
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"

  getRecruitList(1, 50).then(res => {


    res.data.rows.map(recruit => {
      let recruitValue = {}
      if (recruit.businessCompany.tagTitleList) {
        recruit.businessCompany.tagTitleList = recruit.businessCompany.tagTitleList.split(";");
        if (recruit.businessCompany.tagTitleList.length > 2) {
          recruit.businessCompany.tagTitleList.length = 2
        } else if (recruit.businessCompany.tagTitleList[0] === '') {
          recruit.businessCompany.tagTitleList = ["优秀公司"]
        }
      } else {
        recruit.businessCompany.tagTitleList = ["优秀公司"]
      }
      if (!recruit.businessCompany.logoUrl) {
        recruit.businessCompany.logoUrl = 'https://youmaigroup-ymy-dev.oss-cn-shenzhen.aliyuncs.com/2023/04/14/7oEyFVQUMuwu0cec3100a8a0c3fcd22e1b41dab51970.jpg'
      }
      recruitValue = recruit.businessCompany
      recruitValue.projectName = recruit.name
      recruitValue.projectId = recruit.projectId
      companyData.value.push(recruitValue)
    })
    store.state.showLoading = false
    store.state.showOverlay = false
    document.body.style.overflow = ""
  })
}

</script>

<style lang="less" scoped>
.carousel-img {
  width: 100%;
  // height: 240px;
  object-fit: cover;
}

.recruit_title {
  margin: .3rem 0;
  font-size: .24rem;
  font-weight: bold;
}

.home_img {
  width: 19.2rem;
  height: 4rem;
  //height: 10.9375vw;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }

  .active {
    opacity: 1;
  }
}

.recruit {
  .comCard {
    .comCard_item {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      //justify-content: space-between;

      .card_content {
        width: 2.94rem;
        background: linear-gradient(#ffffff, #F5FCFC);
        padding: .14rem .2rem .14rem .2rem;
        display: flex;
        flex-direction: column;
        //margin-bottom: 0.632vw;
        //margin: 0 0.18vw 0.417vw 0.18vw;
        //margin: 0.104vw;
        margin-bottom: .18rem;
        border: none;
        border-radius: .06rem;
        -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        word-break: break-all;

        &:hover {
          box-shadow: 0 .16rem .32rem 0 rgba(48, 55, 66, 0.15);
          transform: translate(0, -0.05rem);
          transition-delay: 0s !important;
        }

        .card_top {
          display: flex;

          .left_img {
            width: 1.2rem;
            height: .8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: .01rem solid #E6E6E6;
            border-radius: .08rem;

            img {
              width: 100%;
              border-radius: .08rem;
              //height: 80%;
            }
          }


          .right_desc {
            width: 100%;
            padding-left: .15rem;
            display: flex;
            flex-direction: column;

            .desc_name {
              font-size: .2rem;
              color: #333333;
              width: 1.5744rem;
              height: .25rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              &:hover {
                color: @youmai_Color;
              }
            }

            .tag {
              display: flex;

              .tagTile {
                border: .01rem solid @small_Color;
                padding: .02rem .05rem;
                border-radius: .05rem;
                margin-right: .06rem;
                font-size: .12rem;
                color: @small_Color;
              }

              .tagTitle1 {
                margin-right: 0;
              }

              a {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }
            }
          }

        }

        .card_bottom {
          //position: absolute;
          //padding: 0;
          //background-color: #F5FCFC;
          margin-top: .14rem;
          font-size: .14rem;
          color: @small_Color;
          //display: flex;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

          .position_name {
            color: @youmai_Color;
          }
        }



      }

      .box-shadow {
        -webkit-box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
        box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
      }
    }
  }
}

.campusNo {
  margin-top: 0.36rem;
  background-color: #fff;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>