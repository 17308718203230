<template>
  <!-- 侧边栏 -->
  <div class="sidebar">
    <!--    <div class="position_code">-->
    <!--      <img src="@/assets/image/layout/nav_code.jpg" alt=""/>-->
    <!--    </div>-->
    <div class="company_info shadow">
      <div class="bgc-lin"></div>
      <div class="info_title">
        <h3>公司信息</h3>
      </div>
      <div style="height: 1.823vw"></div>
      <div class="company_name">
        <div class="image">

          <img v-if="companyInfo.logoUrl" :src="companyInfo.logoUrl" :alt="companyInfo.name" :title="companyInfo.name" />
          <img v-else src="https://youmaigroup-ymy-customer-mp.oss-cn-shenzhen.aliyuncs.com/companyDefault.png">
        </div>
        <h4>
          <a>{{ companyInfo.name }}</a>
        </h4>
      </div>
      <div class="company_type">
        <i class="iconfont icon-qiye"></i>
        <a>{{ companyInfo.type }}</a>
      </div>
      <div class="person_num">
        <i class="iconfont icon-tuanduiguimo"></i>
        <a>{{ companyInfo.crewSize }}</a>
      </div>
      <div class="industry">
        <i class="iconfont icon-xingye"></i>
        <a>{{ companyInfo.professionStr }}</a>
      </div>
      <a class="seeAll" @click="toCompanyPage">
        <i class="iconfont icon-chakansuoyouwenjian"></i>
        <span>查看所有职位</span>
      </a>
    </div>


  </div>
</template>

<script setup>

import { ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const router = useRouter()
const store = useStore()

const props = defineProps({
  companySketch: Object
})
const companyInfo = ref({})
companyInfo.value = toRefs(props).companySketch.value


const toCompanyPage = () => {
  router.push({
    path: '/companyDetails',
    query: {
      id: companyInfo.value.companyId
    }
  })
  store.state.pathActive = "/company"
}


</script>

<style lang="less" scoped>
// 侧边栏
.sidebar {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


  .company_info {
    padding: .32rem;
    background-color: #fff;
    border-radius: .08rem;
    position: relative;

    .bgc-lin {
      width: 100%;
      height: .5rem;
      background: linear-gradient(#e0ecff, #ffffff);
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
    }


    .info_title {
      position: absolute;
      top: .32rem;
      padding-bottom: .12rem;
      align-items: center;
      width: 2.6rem;
      border-bottom: .01rem solid rgba(204, 204, 204, .5);
    }

    .company_name {
      margin-top: .1728rem;
      margin-bottom: .096rem;
      display: flex;
      align-items: center;
      //border-top: 0.052vw solid @small_Color;

      h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;

        a {
          color: @big_Color;
        }
      }

      .image {
        width: .5rem;
        height: .5rem;
        margin-right: .16rem;
        border: .01rem solid rgba(204, 204, 204, .4);
        border-radius: .08rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: .08rem;
          //border-radius: 2.604vw;
        }
      }

    }

    .company_type {

      margin-bottom: .096rem;
      display: flex;
      align-items: center;

      a {
        color: @small_Color;
      }

      .iconfont {
        font-size: .18rem;
        margin-right: .08rem;
      }
    }

    .person_num {

      margin-bottom: .096rem;
      display: flex;
      align-items: center;

      a {
        color: @small_Color;
      }

      .iconfont {
        font-size: .18rem;
        margin-right: .08rem;
      }
    }

    .industry {
      color: @small_Color;
      margin-bottom: .1728rem;
      display: flex;
      align-items: center;

      a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: @small_Color;
      }

      .iconfont {
        font-size: .18rem;
        margin-right: .08rem;
        margin-top: .01rem;
      }
    }

    .seeAll {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-top: .01rem solid rgba(204, 204, 204, .5);
      padding-top: .288rem;
      color: @youmai_Color;

      &:hover {
        color: #155abe;
      }

      .iconfont {
        font-size: .22rem;
      }
    }
  }


  .other_recommend {
    margin-top: .2rem;
    width: 3rem;
    background-color: #fff;
    padding: .28rem .37rem;
    color: @big_Color;
    border-radius: .08rem;

    .recommend {
      font-size: .18rem;
      padding: .16rem 0;
      font-weight: 700;

      img {
        height: .5rem;
        margin-right: .05rem;
      }
    }
  }
}
</style>