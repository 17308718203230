<template>
  <nav class="nav_sticky">
    <NavigationView></NavigationView>
  </nav>
  <div class="component">
    <!-- 搜索框 -->
    <section>
      <div class="position_search">
        <div class="search_left"></div>
        <input class="search_input" type="text" :placeholder="placeholder" @keydown="searchHandler"
          v-model="companyInputValue">
        <a class="search_btn" style="font-size: .18rem;" @click="searchCompany">搜索</a>
      </div>
    </section>
    <div style="height: .5rem"></div>
    <!-- 条件筛选栏 -->
    <!--    <ConditionView></ConditionView>-->
    <!-- 条件筛选栏 -->
    <section>
      <div class="company_condition container shadow">
        <div class="condition_item" :class="'item' + item.id" v-for="item in condition" :key="item.id">
          <div class="item_title">{{ item.title }}</div>
          <div class="item_content">
            <a @click="chooseCategory(item.id, i)" :class="i === chooseCompany[item.id - 1] ? 'choose' : ''"
              v-for="(i, index) in item.content" :key="index">{{ i }}</a>
          </div>
        </div>
      </div>
    </section>

    <div class="component_main container">
      <div class="company">
        <section>
          <CategoryTitle title="推荐公司"></CategoryTitle>
        </section>
        <!--        <CompanyCard :company="company"></CompanyCard>-->
        <section>
          <template v-if="company.length">
            <div class="comCard">
              <div class="comCard_item" v-if="cardContent">
                <n-grid x-gap="12" cols="4">
                  <n-gi class="card_content box-shadow" :class="'card' + index" @click="toCompanyDetails(item.companyId)"
                    v-for="(item, index) in company" :key="item.companyId">
                    <div class="card_top">

                      <div class="left_img">
                        <!--'src="@/assets/logo/logo1.jpg"'-->
                        <img :src="item.logoUrl" :alt="item.name" />
                      </div>
                      <div class="right_desc">
                        <a class="desc_name">
                          {{ item.name }}
                        </a>
                        <span class="tag" style="margin-top: 1.053vw;">
                          <a class="tagTile" :class="'tagTitle' + index" v-for="(i, index) in item.welfareList"
                            :key="index">{{ i }}</a>
                        </span>
                      </div>
                    </div>
                    <div class="card_bottom">
                      <span class="bottom_name">热招 |</span>
                      <a class="position_name">&nbsp;{{ item.positionCompany.name }}</a>
                      <span class="salary" v-if="item.positionCompany.minSalary && item.positionCompany.maxSalary">&nbsp;{{
                        item.positionCompany.minSalary }}-{{ item.positionCompany.maxSalary
  }}K</span>
                    </div>
                  </n-gi>
                </n-grid>
                <!--              <div class="card_content box-shadow" :class="'card'+index" @click="toCompanyDetails(item.companyId)"-->
                <!--                   v-for="(item, index) in company" :key="item.companyId">-->
                <!--                <div class="card_top">-->

                <!--                  <div class="left_img">-->
                <!--                    &lt;!&ndash;'src="@/assets/logo/logo1.jpg"'&ndash;&gt;-->
                <!--                    <img :src="item.logoUrl" alt=""/>-->
                <!--                  </div>-->
                <!--                  <div class="right_desc">-->
                <!--                    <a class="desc_name">-->
                <!--                      {{ item.name }}-->
                <!--                    </a>-->
                <!--                    <p style="margin-top: 1.053vw;">-->
                <!--                      <a class="tagTile" :class="'tagTitle'+index" v-for="(i, index) in item.tagTitleList" :key="index">{{ i }}</a>-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="card_bottom">-->
                <!--                  <span class="bottom_name">热招 |</span>-->
                <!--                  <a class="position_name">&nbsp;{{ item.positionCompany.name }}</a>-->
                <!--                  <span class="salary">&nbsp;{{item.positionCompany.minSalary}}-{{item.positionCompany.maxSalary}}K</span>-->
                <!--                </div>-->
                <!--              </div>-->
              </div>
            </div>
          </template>
          <template v-else>
            <div class="position_no_data">
              <n-result status="warning" title="" description="暂无符合条件的数据">
              </n-result>
            </div>
          </template>
        </section>

      </div>
      <section>
        <div class="position_paging">
          <n-pagination v-model:page="current" :page-count="pageTotal" size="large" :on-update:page="showSizeChange" />
        </div>
      </section>
    </div>

  </div>
  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
import CategoryTitle from '@/views/company/components/CategoryTitle.vue';
import { onMounted, ref } from 'vue';
import { getCompanyList } from "@/request/api";
import { useStore } from "vuex";
import { useHead } from '@vueuse/head'
import { useRouter, useRoute } from "vue-router";
import NavigationView from "@/components/NavigationView.vue";
import FooterView from "@/components/FooterView.vue";
const store = useStore()
const route = useRoute()

const current = ref(1)
const pageTotal = ref(1)

useHead(
  {
    title: '公司列表-友脉人才网',
    meta: [
      {
        name: 'keywords',
        content: '好公司，行业可靠，龙头企业，待遇诱人'
      },
      {
        name: 'description',
        content: '为求职者提供各行各业靠谱好公司'
      }
    ]
  }
)

const searchHandler = (e) => {
  if (e.key === 'Enter') {
    searchCompany()
  }
}

onMounted(() => {
  store.state.pathActive = '/company'
  if (route.query.inputValue) {
    companyInputValue.value = route.query.inputValue
    companySelectData.value.name = route.query.inputValue
  }
  getCompanyData()
})



const placeholder = ref('请输入公司名称')

const condition = ref([
  {
    id: 1,
    title: '工作地点',
    content: ['不限', '昆明', '曲靖', '玉溪', '大理', '昭通', '丽江', '红河', '楚雄', '保山', '文山', '普洱', '临沧', '德宏', '怒江']
  },
  {
    id: 2,
    title: '工作单位',
    content: ['不限', '国企', '事业单位', '私企', '民企']
  }
])

const chooseCompany = ref(['不限', '不限'])
const companyInputValue = ref('')
const chooseCategory = (index, name) => {
  if (chooseCompany.value[index - 1] === name) {
    chooseCompany.value[index - 1] = ''
  } else {
    chooseCompany.value[index - 1] = name
  }
  chooseCompany.value.map((item, index) => {
    if (index === 0) {
      switch (item) {
        case '不限':
          delete companySelectData.value.addr;
          break;
        case '':
          delete companySelectData.value.addr;
          break;
        default:
          companySelectData.value.addr = item
      }
    }
    if (index === 1) {
      switch (item) {
        case '国企':
          companySelectData.value.type = 0;
          break;
        case '事业单位':
          companySelectData.value.type = 1;
          break;
        case '私企':
          companySelectData.value.type = 2;
          break;
        case '民企':
          companySelectData.value.type = 3;
          break;
        default:
          delete companySelectData.value.type;
          break;
      }
    }
  })
  getCompanyData()
}

const searchCompany = () => {
  companySelectData.value.name = companyInputValue.value
  current.value = 1
  getCompanyData()
}
let company = ref([])

const showSizeChange = (page) => {
  current.value = page
  window.scrollTo(0, 0)
  getCompanyData()
}

const router = useRouter()
const cardContent = ref([])


/*公司筛选要传递的数据*/
let companySelectData = ref({})

// const positionCompany = ref([])

const toCompanyDetails = (id) => {
  router.push({
    path: '/companyDetails',
    query: {
      id
    }
  })
}

/* 获取数据 */
const getCompanyData = () => {
  store.state.showLoading = true
  store.state.showOverlay = true
  document.body.style.overflow = "hidden"
  company.value = []
  getCompanyList(current.value, companySelectData.value).then(res => {
    if (res.data.code === 200) {
      const r = res.data.rows
      if (r.length === 0 || !r) {
        store.state.showLoading = false
        store.state.showOverlay = false
        document.body.style.overflow = ""
        return
      }
      pageTotal.value = Math.ceil(res.data.total / 16)
      r.map((v) => {
        if (!v.logoUrl) {
          v.logoUrl = 'https://youmaigroup-ymy-customer-mp.oss-cn-shenzhen.aliyuncs.com/companyDefault.png'
        }
        if (v.welfareList) {
          v.welfareList = v.welfareList.split(";")
          if (v.welfareList.length > 2) {
            v.welfareList.length = 2
          }
        } else {
          v.welfareList = ["福利多多"]
        }
        if (v.type === 0) {
          v.type = '国企'
        } else if (v.type === 1) {
          v.type = '事业单位'
        } else if (v.type === 2) {
          v.type = '私企'
        } else if (v.type === 3) {
          v.type = '民企'
        }
        if (v.positionListInfo.length !== 0) {
          v.positionCompany = v.positionListInfo[0]
        } else {
          v.positionCompany = { name: '暂无热招职位' }
        }
        company.value.push(v)
        store.state.showLoading = false
        store.state.showOverlay = false
        document.body.style.overflow = ""
      })
    }
  })
}




</script>

<style lang="less" scoped>
.component {
  margin-top: .5rem;

  .loading_animate {
    z-index: 9999;
    position: fixed;
    top: 35%;
    left: 45%;
  }

  // 搜索框
  .position_search {
    display: flex;
    width: 8rem;
    height: .6rem;
    margin: 0 auto;

    .search_left {
      width: .6rem;
      height: 100%;
      background-color: #fff;
      border-top-left-radius: .6rem;
      border-bottom-left-radius: .6rem;
    }

    .search_input {
      width: 6.4rem;
      border: 0;
    }

    .search_btn {
      width: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4C80CC;
      color: #fff;
      font-size: 1.625rem;
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }

  // 条件筛选栏
  .company_condition {
    //height: 13.021vw;
    background-color: #fff;
    padding: .2rem .3rem;
    border-radius: .08rem;


    .condition_item {
      display: flex;
      border-bottom: .01rem dashed #EBEBEB;

      .item_title {
        width: 1rem;
        height: .4rem;
        color: @big_Color;
        line-height: .4rem;
        font-size: .18rem;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .item_content {
        display: flex;
        align-items: center;


        a {
          margin-right: .3rem;
          color: #767676;
        }

        .choose {
          color: #4C80CC;
        }
      }
    }

    .item4 {
      border: 0;
    }
  }

  .component_main {
    margin-top: .46rem;

    .position_paging {
      margin-top: .4rem;
      display: flex;
      justify-content: center;
    }

    .company {
      .comCard {
        .comCard_item {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          //justify-content: space-between;

          .card_content {
            width: 2.94rem;
            background: linear-gradient(#ffffff, #F5FCFC);
            padding: .14rem .2rem .14rem .2rem;
            display: flex;
            flex-direction: column;
            //margin-bottom: 0.632vw;
            //margin: 0 0.18vw 0.417vw 0.18vw;
            //margin: 0.104vw;
            margin-bottom: .18rem;
            border: none;
            border-radius: .06rem;
            -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
            transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
            word-break: break-all;

            &:hover {
              box-shadow: 0 .16rem .32rem 0 rgba(48, 55, 66, 0.15);
              transform: translate(0, -0.05rem);
              transition-delay: 0s !important;
            }

            .card_top {
              display: flex;

              .left_img {
                width: 1.2rem;
                height: .8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: .01rem solid #E6E6E6;
                border-radius: .08rem;

                img {
                  width: 100%;
                  border-radius: .08rem;
                  //height: 80%;
                }
              }


              .right_desc {
                width: 100%;
                padding-left: .15rem;
                display: flex;
                flex-direction: column;

                .desc_name {
                  font-size: .2rem;
                  color: #333333;
                  width: 1.5744rem;
                  height: .25rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;

                  &:hover {
                    color: @youmai_Color;
                  }
                }

                .tag {
                  display: flex;

                  .tagTile {
                    border: .01rem solid @small_Color;
                    padding: .02rem .05rem;
                    border-radius: .05rem;
                    margin-right: .06rem;
                    font-size: .12rem;
                    color: @small_Color;
                  }

                  .tagTitle1 {
                    margin-right: 0;
                  }

                  a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                  }
                }
              }

            }

            .card_bottom {
              //position: absolute;
              //padding: 0;
              //background-color: #F5FCFC;
              margin-top: .14rem;
              font-size: .14rem;
              color: @small_Color;
              //display: flex;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              .position_name {
                color: @youmai_Color;
              }

              .salary {
                color: @salarySize;
              }
            }



          }

          .box-shadow {
            -webkit-box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
            box-shadow: 0 .04rem .16rem rgba(48, 55, 66, 0.15);
          }
        }
      }
    }
  }
}</style>