/**
 * 拼接公司地址
 * @param {Object} companyInfo
 * @returns
 */
export const combineAddress = (companyInfo) => {
	let fullAddress = "";

	if (
		companyInfo.province &&
		!companyInfo.addr.includes(companyInfo.province)
	) {
		fullAddress += companyInfo.province;
	}

	if (companyInfo.city && !companyInfo.addr.includes(companyInfo.city)) {
		fullAddress += companyInfo.city;
	}

	if (companyInfo.region && !companyInfo.addr.includes(companyInfo.region)) {
		fullAddress += companyInfo.region;
	}

	if (companyInfo.addr && companyInfo.addr !== "暂无") {
		fullAddress += companyInfo.addr;
	}

	return fullAddress;
};
