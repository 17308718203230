<template>
  <!--名企直招-->
  <div class="famous_component_invite container">
    <CampusMessage bigTitle="DIRECT" smallTitle="RECRUITMENT" chTitle="名企直招"></CampusMessage>
    <div class="right_component">
      <div class="component_card shadow" v-for="i in component" :key="i.id">
        <div class="text">{{i.name}}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import CampusMessage from "@/views/campusRecruitment/components/components/CampusMessage";
const component = ref([
  {
    id: 1,
    name: 'CSCEC',
    img: ''
  },
  {
    id: 2,
    name: '中国联通',
    img: ''
  },
  {
    id: 3,
    name: 'LONSHINE',
    img: ''
  },
  {
    id: 4,
    name: '白象食品',
    img: ''
  },
  {
    id: 5,
    name: '北京建工路桥集团',
    img: ''
  },
  {
    id: 6,
    name: '中国烟草',
    img: ''
  },
  {
    id: 7,
    name: '海量数据',
    img: ''
  },
  {
    id: 8,
    name: '招商银行',
    img: ''
  },
])
</script>

<style lang="less" scoped>
//名企直招
.famous_component_invite {
  margin-top: 2.604vw;
  height: 15.625vw;
  display: flex;

  .right_component {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .component_card {
      width: 11.198vw;
      height: 7.1875vw;
      background-color: #fff;
      border-radius: 0.417vw;
      .text {
        font-size: 1.25rem;
        color: @youmai_Color;
        display: flex;
        justify-content: center;
        line-height: 7.1875vw;
      }
    }
  }
}
</style>